import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import GovHome from '../../assets/images/GovHomeLogo.png'
import '../Registration/Registration.scss';
import '../ForgotPassword/ForgotPassword.scss'
import mail from '../../assets/icons/mail.png'
import ContinueButton from '../../Components/Buttons/ContinueButton/ContinueButton';
import FormInput from '../../Components/Inputs/FormInput/FormInput';
import BackButton from '../../Components/Buttons/BackButton/BackButton';
import { toast } from "react-toastify";
import url from '../../utils/services/urls.json';
import { postMethod } from '../../utils/services/apis';
import { setParam, getUserId } from '../../utils/services/common';
import ArrowBack from "../../assets/images/arrow_back-ipad.svg"

const ForgotpPassword = () => {
    const navigate = useNavigate();
    const [continueButton, setcontinueButton] = useState(false);
    const params = useParams();
    const [email, setEmail] = useState('');
    const [userId, setUserId] = useState('')
    const [errorFromApi, setErrorFromApi] = useState(false);;

    const getFormData = (event) => {
        event.preventDefault()
        const payload = {
            email: email,
            user_id: userId
        }
        forgotPassword(payload)

    };
    const forgotPassword = async (body) => {
        const result = await postMethod(url.forgotpassword, body)
        let successValue = { heading: "Verification mail sent", title: "An password reset link has been sent to your registered Email" }
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            if (result.status == 200 || result.status == 201) {
                navigate(`/${params.value}/ForgotPassword/Success`, { state: successValue })
                toast.success(result.data.message)
            } else {
                toast.error(result.data.error)
            }
        }
    }
    useEffect(() => {
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setcontinueButton(true)
        } else {
            setcontinueButton(false)
        }
        setErrorFromApi(false)
    }, [email])

    useEffect(() => {
        setParam(params.value);
        setUserId(getUserId())
    }, []);
    return (
        <>
            <div className="registration">
                <div className="middleContent " >
                    <div>
                        <img className="arrow-back" src={ArrowBack} alt="" onClick={() => navigate(-1)} style={{ cursor: "pointer" }} />
                    </div>
                    <div className="logo">
                        <img className="logoImg" src={GovHome} alt="" />
                    </div>
                    <div className="mainFields">
                        <h3 className='text-center typeHeding' style={{ fontWeight: 600 }}>Forgot Password</h3>
                        <p className='text-center paragraphemail'  >Enter your Registered Email ID</p>
                        <form onSubmit={getFormData} style={{ marginTop: '30px' }}>
                            <div className="get-regsister">
                                <FormInput required={true} name={'Email'} id={'email'} type={'email'} value={email} onChange={(e) => setEmail(e.target.value)} icon={`${mail}`}></FormInput>
                                <div  >
                                    <ContinueButton className={continueButton ? "continue" : "continueDisabled"} backgroundColor={continueButton ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} disabled={!continueButton} text={'Continue'} height={'50px'} color={'#fff'} width={"200px"} />
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <BackButton send={-1} text={'Forgot Password'} backText={'Back'}></BackButton> */}
            <BackButton className="back" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={() => navigate(-1)} />
        </>
    )
}
export default ForgotpPassword
