import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import "./ListingCard.scss";
import Reply from "../../../assets/images/reply.png"
import Bedroom from "../../../assets/images/favoriteProperty/bedroom.svg"
import Bathroom from "../../../assets/images/favoriteProperty/bathroom.svg"
import Parking from "../../../assets/images/favoriteProperty/parking.svg"
import Clock from "../../../assets/images/favoriteProperty/clock.svg"
import Home from "../../../assets/images/favoriteProperty/home.svg"
import Furnishing from "../../../assets/images/favoriteProperty/furnishing.svg"
import Modal from "react-modal"
import closeIocn from "../../../assets/images/searchproperty/close.svg"
import ContinueButton from "../../../Components/Buttons/ContinueButton/ContinueButton"
import { FormControl, InputAdornment, TextField, } from "@material-ui/core";
import SearchIcon from '@mui/icons-material/Search';
import { getMethodWithToken, postMethodWithToken } from '../../../utils/services/apis';
import url from '../../../utils/services/urls.json'
import { getUserId } from '../../../utils/services/common';
import moment from 'moment';
import { navigateLogin } from '../../../utils/services/common';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';
import DefaultImage from "../../../assets/images/default-image.svg"

const CustomImage = styled.img`
background:url('${props => props.image}');
height: 182px;
width:100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;
padding-left: 10px;

@media (min-width:1199px) and (max-width: 1700px) {
     height: 182px;
   }

   @media (min-width:770px) and (max-width: 1199px) {
     height: 142px;
   }

   @media (min-width:575px) and  (max-width:768px) {
   height: 142px;
  }

   @media (min-width:431px) and  (max-width:575px) {
   height: 320px;
  }

   @media  (min-width:400px) and  (max-width:431px) {
   height: 220px;
  }

   @media (max-width:400px) {
   height: 190px;
  }
`

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const tutorialSteps = [
    {
        label: 'San Francisco – Oakland Bay Bridge, United States',
        imgPath:
            'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
    },
    {
        label: 'Bird',
        imgPath:
            'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
    },
    {
        label: 'Bali, Indonesia',
        imgPath:
            'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80',
    },
    {
        label: 'NeONBRAND Digital Marketing, Las Vegas, United States',
        imgPath:
            'https://images.unsplash.com/photo-1518732714860-b62714ce0c59?auto=format&fit=crop&w=400&h=250&q=60',
    },
    {
        label: 'Goč, Serbia',
        imgPath:
            'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
    },
];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        // height: 240,
        display: 'block',
        // maxWidth: 300,
        overflow: 'hidden',
        width: '100%',
    },
}));

const MyListingCard = styled.div`
font-family: Proxima_nova_reg;
// width:900px;
height: auto;
margin-bottom:25px ;
background: white;
padding: 10px 0 10px 10px;
margin-left: -10px; 
box-shadow: 0px 2px 8px 0px #0000001F;

@media (max-width:575px) {
    padding: 0px;
}

`

const ListingCard = ({ myContactModal, mobileModal }) => {
    const navigate = useNavigate()
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const [value, setValue] = useState(0);
    const [openModal, setOpenModal] = useState(false)
    const [openRelistModal, setOpenRelistModal] = useState(false)
    const [searchInput, setSearchInput] = useState("")
    const [propertyId, setPropertyId] = useState("")
    const [propertyRentId, setPropertyRentId] = useState("")
    const [managerList, setManagerList] = useState([])
    const [listingDetail, setListingDetail] = useState([])
    const [sendManagingRequestId, setSendManagingRequestId] = useState("")
    const maxSteps = tutorialSteps.length;
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: screenSize.width <= 800 ? 'translate(-50%, -50%)' : 'translate(-50%, -50%)',
            width: screenSize.width <= 575 ? "85vw" : screenSize.width <= 800 ? "60vw" : screenSize.width <= 1100 ? "60vw" : "40vw",
            height: screenSize.width <= 575 ? "70vh" : screenSize.width <= 800 ? "60vh" : "68vh",
            padding: screenSize.width <= 575 ? "10px 12px" : '20px 30px',
        },
    };

    const styleWithRelist = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: screenSize.width <= 575 ? "355px" : "700px",
            height: screenSize.width <= 575 ? "240px" : "200px",
            padding: "30px",
        },
    }



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleNext = (data, galleryIndex) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMasterId]: prevSteps[data?.propertyMasterId] === data?.propertyImages?.length - 1
                ? 0
                : (prevSteps[data?.propertyMasterId] || 0) + 1
        }));
    };

    const handleBack = (data, galleryIndex) => {

        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMasterId]: prevSteps[data?.propertyMasterId] === 0
                ? data?.propertyImages?.length - 1
                : (prevSteps[data?.propertyMasterId] || 0) - 1
        }))

    };

    const handleStepChange = (data, step) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: step
        }));
    };

    const getRandomNumberProperty = (number) => {
        return Math.floor(Math.random() * number);
    };

    const handleSearchChange = (event) => {
        setSearchInput(event.target.value)
    }
    useEffect(() => {
        getMyListingDetail()
    }, [value])
    const getMyListingDetail = async () => {
        var urls = ""
        if (value === 0) {
            urls = url.myListingProperty
        } else if (value === 1) {
            urls = url.myListingProperty + "?rented=False"
        } else {
            urls = url.myListingProperty + "?rented=True"
        }
        await getMethodWithToken(urls).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setListingDetail(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    const handleOpneModal = (propertyId) => {
        setPropertyId(propertyId)
        setOpenModal(true)
        // handleManagerList()
        setSendManagingRequestId("")
    }
    useEffect(() => {
        handleManagerList()
    }, [searchInput])
    const handleManagerList = async () => {
        await getMethodWithToken(url.managerList + "?name=" + searchInput).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setManagerList(response?.data?.manager_list)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }
    const handleListingRequest = () => {
        navigate("listingRequest")
    }

    const handleOpenRelistModal = (propertyId) => {
        setOpenRelistModal(true)
        setPropertyRentId(propertyId)
    }
    const handleCloseRelistModal = () => {
        setOpenRelistModal(false)
    }
    const handleRelist = () => {
        navigate(`relist/${propertyRentId}`)
        // navigate(`/Dashboard/FavoritePropertieList/Payment/${propertyRentId}`)
    }
    const handleManageRequestChange = (managerId) => {
        setSendManagingRequestId(managerId)
    }

    const handleSendManagingRequest = async (managerId) => {
        let body = {
            "manager": managerId,
            "managing_property": propertyId
        }
        await postMethodWithToken(url.sendManagingRequest, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setOpenModal(false)
                toast.success(response?.data?.message)
            }
        })
    }

    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }

    const handleEditProperty = (propertyValue) => {
        if (propertyValue?.propertyType === "House") {
            navigate(`/Dashboard/FavoritePropertieList/SelectBasic/${propertyValue?.propertyMasterId}/1`)
        } else if (propertyValue?.propertyType === "Condo") {
            navigate(`/Dashboard/FavoritePropertieList/SelectBasic/${propertyValue?.propertyMasterId}/2`)
        } else if (propertyValue?.propertyType === "Townhome") {
            navigate(`/Dashboard/FavoritePropertieList/SelectBasic/${propertyValue?.propertyMasterId}/3`)
        } else {
            navigate(`/Dashboard/FavoritePropertieList/SelectBasic/${propertyValue?.propertyMasterId}/14`)
        }
    }

    return (
        <>
            <div className="my-listing-tabs" style={{ background: (!mobileModal && !openModal && !openRelistModal && !myContactModal) ? "rgb(242, 242, 247)" : "", zIndex: (!openModal && !openRelistModal && !myContactModal && !mobileModal) ? "1" : "" }}>
                <div className="tab-value">
                    <Tabs value={value} onChange={handleChange}  >
                        <Tab label="All Listings" {...a11yProps(0)} style={{ textTransform: 'capitalize' }} />
                        <Tab label="Listed" {...a11yProps(1)} style={{ textTransform: 'capitalize' }} />
                        <Tab label="Rented Listings" {...a11yProps(2)} style={{ textTransform: 'capitalize' }} />
                    </Tabs>
                </div>
            </div>
            <div className="my-listing-tab-card" style={{ height: (listingDetail?.length === 1 && screenSize.width < 375) ? "70vh" : (listingDetail?.length === 1 && screenSize.width < 400) ? "73vh" : (listingDetail?.length === 1 && screenSize.width < 500) && "75vh" }}>
                <TabPanel value={value} index={0}>
                    <div className="tab-panel-value">
                        {listingDetail && listingDetail.length > 0 ? listingDetail.map((item, index) => {
                            return (
                                <>
                                    <MyListingCard>
                                        <Row style={{ padding: "0" }}>
                                            <Col xs={12} sm={5} md={5} lg={4} xl={4} xxl={4}>
                                                <div>
                                                    <SwipeableViews
                                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                        index={activeStep[item?.propertyMasterId] || 0}
                                                        onChangeIndex={(step) => handleStepChange(item, step)}
                                                        enableMouseEvents
                                                    >
                                                        {item?.propertyImages.map((step, index) => {
                                                            return (
                                                                <>
                                                                    <div key={index}>
                                                                        <CustomImage className={classes.img} src={step?.file_url} alt={step.label} style={{ filter: item?.is_rent === true && "grayscale(1)" }} />
                                                                    </div>

                                                                </>
                                                            )
                                                        })}
                                                    </SwipeableViews>
                                                    <MobileStepper
                                                        position="static"
                                                        variant="text"
                                                        nextButton={
                                                            <Button size="small" style={{ color: "white" }} onClick={() => handleNext(item, index)}>

                                                                {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                            </Button>
                                                        }
                                                        backButton={
                                                            <Button size="small" style={{ color: "white" }} onClick={() => handleBack(item, index)}>
                                                                {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                            </Button>
                                                        }
                                                    />

                                                </div>
                                            </Col>
                                            <Col xs={12} sm={7} md={7} lg={8} xl={8} xxl={8}>
                                                <div className="listing-card-detail" >
                                                    <div className="d-flex justify-content-between mobile-section" >
                                                        <div className="d-flex" style={{ color: item?.is_rent === true ? "#BDBDBD" : "#2F86D1", filter: item?.is_rent === true && "grayscale(1)" }} >
                                                            <div className="listing-user-heading" >${item?.price} / </div>
                                                            <div className="listing-user-month">month</div>
                                                        </div>

                                                    </div>

                                                    <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px" }}>{item?.locality}</div>
                                                    <Row style={{ borderBottom: "1px solid #B7B7B7", paddingBottom: "5px", paddingTop: "8px" }}>
                                                        <Col xs={5} sm={5} md={6} lg={4} xl={3} xxl={3} className='d-flex'>
                                                            <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                            <div className="categroy-heading">Bedroom</div>
                                                            <div className="category-value">{item?.bedroomCount}</div>
                                                        </Col>
                                                        <Col xs={7} sm={7} md={6} lg={6} xl={6} xxl={6} className='d-flex'>
                                                            <div><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                            <div className="categroy-heading">Bathroom</div>
                                                            <div className="category-value">{item?.propertyType === "Room" ? item?.room_sharing : item?.bathroomCount}</div>
                                                        </Col>

                                                    </Row>
                                                    <div>
                                                        <Row >
                                                            <Col md={12} lg={12} xl={12} xxl={12}>
                                                                <div className="d-none d-sm-inline">
                                                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                        <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                        <div className="categroy-heading">Available from</div>
                                                                    </div>
                                                                    <div className="select-value-heading">{moment(item?.propertyAvailfrom).format("YYYY-MM-DD")}</div>
                                                                </div>

                                                                <div className="d-flex d-sm-none justify-content-between">
                                                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                        <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                        <div className="categroy-heading">Available from</div>
                                                                    </div>
                                                                    <div className="select-value-heading">{moment(item?.propertyAvailfrom).format("YYYY-MM-DD")}</div>
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                    </div>
                                                    <div className="d-flex justify-content-end request-genarte" onClick={() => { item?.is_rent === true ? handleOpenRelistModal(item?.propertyMasterId) : handleOpneModal(item?.propertyMasterId) }}>{item?.is_rent === true ? "Re-list property" : "Send Managing Request"}</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </MyListingCard>
                                </>
                            )
                        })
                            :
                            <div className="empty-my-list">
                                <div className="empty-heading" >Nothing here yet <br /><span className="empty-description">Wait for a request</span></div>
                            </div>
                        }
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div className="tab-panel-value">
                        {listingDetail && listingDetail.length > 0 ? listingDetail.map((item, index) => {
                            return (
                                <MyListingCard>
                                    <Row style={{ padding: "0" }}>
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4} xxl={4}>
                                            <div>
                                                <SwipeableViews
                                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                    index={activeStep[item?.propertyMasterId] || 0}
                                                    onChangeIndex={(step) => handleStepChange(item, step)}
                                                    enableMouseEvents
                                                >
                                                    {item?.propertyImages.map((step, index) => {
                                                        return (
                                                            <>
                                                                <div key={index}>
                                                                    <CustomImage className={classes.img} src={step?.file_url} alt={step.label} />
                                                                </div>

                                                            </>
                                                        )
                                                    })}
                                                </SwipeableViews>
                                                <MobileStepper
                                                    position="static"
                                                    variant="text"
                                                    nextButton={
                                                        <Button size="small" style={{ color: "white" }} onClick={() => handleNext(item, index)}>

                                                            {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                        </Button>
                                                    }
                                                    backButton={
                                                        <Button size="small" style={{ color: "white" }} onClick={() => handleBack(item, index)}>
                                                            {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                        </Button>
                                                    }
                                                />

                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={7} lg={8} xl={8} xxl={8}>
                                            <div className="listing-card-detail" >
                                                <div className="d-flex justify-content-between mobile-section" >
                                                    <div className="d-flex" style={{ color: "#2F86D1" }} >
                                                        <div className="listing-user-heading" >${item?.price} / </div>
                                                        <div className="listing-user-month">month</div>
                                                    </div>
                                                    <div>
                                                        <EditIcon style={{ cursor: "pointer" }} onClick={() => handleEditProperty(item)} />
                                                    </div>
                                                </div>
                                                <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px" }}>{item?.locality}</div>

                                                <Row style={{ borderBottom: "1px solid #B7B7B7", paddingBottom: "5px", paddingTop: "8px" }}>
                                                    <Col xs={5} sm={5} md={6} lg={4} xl={3} xxl={3} className='d-flex'>
                                                        <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading">Bedroom</div>
                                                        <div className="category-value">{item?.bedroomCount}</div>
                                                    </Col>
                                                    <Col xs={7} sm={7} md={6} lg={6} xl={6} xxl={6} className='d-flex'>
                                                        <div><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading">Bathroom</div>
                                                        <div className="category-value">{item?.propertyType === "Room" ? item?.room_sharing : item?.bathroomCount}</div>
                                                    </Col>

                                                </Row>
                                                <div>
                                                    <Row >
                                                        <Col md={12} lg={12} xl={12} xxl={12}>
                                                            <div className="d-none d-sm-inline">
                                                                <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                    <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                    <div className="categroy-heading">Available from</div>
                                                                </div>
                                                                <div className="select-value-heading">{moment(item?.propertyAvailfrom).format("YYYY-MM-DD")}</div>
                                                            </div>
                                                            <div className="d-flex d-sm-none justify-content-between">
                                                                <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                    <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                    <div className="categroy-heading">Available from</div>
                                                                </div>
                                                                <div className="select-value-heading">{moment(item?.propertyAvailfrom).format("YYYY-MM-DD")}</div>
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </div>
                                                <div className="d-flex justify-content-end request-genarte" onClick={() => handleOpneModal()}>Send Managing Request</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </MyListingCard>
                            )
                        })
                            :
                            <div className="empty-my-list">
                                <div className="empty-heading" >Nothing here yet <br /><span className="empty-description">Wait for a request</span></div>
                            </div>
                        }
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <div className="tab-panel-value">
                        {listingDetail && listingDetail.length > 0 ?
                            listingDetail.map((item, index) => {
                                return (
                                    <MyListingCard>
                                        <Row style={{ padding: "0" }}>
                                            <Col xs={12} sm={12} md={5} lg={4} xl={4} xxl={4}>
                                                <div>
                                                    <SwipeableViews
                                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                        index={activeStep[item?.propertyMasterId] || 0}
                                                        onChangeIndex={(step) => handleStepChange(item, step)}
                                                        enableMouseEvents
                                                    >
                                                        {item?.propertyImages.map((step, index) => {
                                                            return (
                                                                <>
                                                                    <div key={index}>
                                                                        <CustomImage className={classes.img} src={step?.file_url} alt={step.label} style={{ filter: "grayscale(1)" }} />
                                                                    </div>

                                                                </>
                                                            )
                                                        })}
                                                    </SwipeableViews>
                                                    <MobileStepper
                                                        position="static"
                                                        variant="text"
                                                        nextButton={
                                                            <Button size="small" style={{ color: "white" }} onClick={() => handleNext(item, index)}>

                                                                {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                            </Button>
                                                        }
                                                        backButton={
                                                            <Button size="small" style={{ color: "white" }} onClick={() => handleBack(item, index)}>
                                                                {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                            </Button>
                                                        }
                                                    />

                                                </div>
                                            </Col>
                                            <Col xs={12} sm={12} md={7} lg={8} xl={8} xxl={8}>
                                                <div className="listing-card-detail" >
                                                    <div className="d-flex justify-content-between mobile-section" >
                                                        <div className="d-flex" style={{ filter: "grayscale(1)" }}>
                                                            <div className="listing-user-heading" >${item?.price} / </div>
                                                            <div className="listing-user-month">month</div>
                                                        </div>
                                                    </div>
                                                    <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px" }}>{item?.locality}</div>
                                                    <Row style={{ borderBottom: "1px solid #B7B7B7", paddingBottom: "5px", paddingTop: "8px" }}>
                                                        <Col xs={5} sm={5} md={6} lg={4} xl={3} xxl={3} className='d-flex'>
                                                            <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                            <div className="categroy-heading">Bedroom</div>
                                                            <div className="category-value">{item?.bedroomCount}</div>
                                                        </Col>
                                                        <Col xs={7} sm={7} md={6} lg={6} xl={6} xxl={6} className='d-flex'>
                                                            <div><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                            <div className="categroy-heading">Bathroom</div>
                                                            <div className="category-value">{item?.propertyType === "Room" ? item?.room_sharing : item?.bathroomCount}</div>
                                                        </Col>

                                                    </Row>
                                                    <div>
                                                        <Row >
                                                            <Col md={12} lg={12} xl={12} xxl={12} >
                                                                <div className="d-none d-sm-inline">
                                                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                        <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                        <div className="categroy-heading">Available from</div>
                                                                    </div>
                                                                    <div className="select-value-heading">{moment(item?.propertyAvailfrom).format("YYYY-MM-DD")}</div>
                                                                </div>
                                                                <div className="d-flex d-sm-none justify-content-between">
                                                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                        <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                        <div className="categroy-heading">Available from</div>
                                                                    </div>
                                                                    <div className="select-value-heading">{moment(item?.propertyAvailfrom).format("YYYY-MM-DD")}</div>
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                    </div>
                                                    <div className="d-flex justify-content-end request-genarte" onClick={() => handleOpenRelistModal(item?.propertyMasterId)}>Re-list property</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </MyListingCard>
                                )
                            })
                            :
                            <div className="empty-my-list">
                                <div className="empty-heading" >Nothing here yet <br /><span className="empty-description">Wait for a request</span></div>
                            </div>
                        }
                    </div>
                </TabPanel>

            </div>
            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
                <div className="modal-container" >
                    <div className="d-flex justify-content-between">
                        <div className="modal-main-heading">Send Managing Request</div>
                        <div style={{ cursor: "pointer" }} onClick={() => handleCloseModal()}>
                            <img src={closeIocn} alt="" />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end pt-md-2 pt-2 pt-md-0" >
                        <ContinueButton text="Send Request" color={'#fff'} borderRadius={"5px"} width={"165px"} onClick={() => handleSendManagingRequest(sendManagingRequestId)} disabled={!sendManagingRequestId} backgroundColor={sendManagingRequestId ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} />
                    </div>
                    <FormControl fullWidth style={{ marginTop: "20px" }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={searchInput}
                            placeholder="Search User"
                            onChange={(e) => handleSearchChange(e)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ paddingLeft: "10px", width: "45px", height: "25px" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                    <div className="manage-request-modal">
                        {managerList && managerList.length > 0 && managerList.map((data, index) => {
                            return (
                                <div className="d-flex manager-request-list" onClick={() => handleManageRequestChange(data?.id)} style={{ borderBottom: sendManagingRequestId === data?.id ? "0.5px solid #0075CA" : "0.5px solid #D9D9D9", background: sendManagingRequestId === data?.id && "#D9EFFF" }}>
                                    <div>
                                        <img src={data?.profilePic ? data?.profilePic : DefaultImage} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                    </div>
                                    <div className="users-heading" >
                                        <div className="user-main-heading">{data?.name}</div>
                                        <div className="user-heading">{data?.companyName}</div>
                                        <div className="listing-description">{data?.listing_count} Listings</div>
                                    </div>
                                </div>
                            )
                        }
                        )}

                    </div>
                </div>
            </Modal>
            <Modal isOpen={openRelistModal} style={styleWithRelist} overlayClassName="myoverlay">
                <div className="modal-relist-container">
                    <div className="modal-relist-heading">Are you sure you want to Re-list this property?</div>
                    <div className="d-flex justify-content-end" style={{ paddingTop: "40px" }}>
                        <div className="modal-relist-description" onClick={() => handleCloseRelistModal()}>Cancel</div>
                        <ContinueButton text="Continue to Re-list" color={'#fff'} borderRadius={"4px"} width={"217px"} onClick={() => handleRelist()} />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ListingCard
