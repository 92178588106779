import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import "./ChangePlan.scss"
import Navbar from '../../../Components/commonComponents/navbar/Navbar';
import Sidebar from '../../../Components/commonComponents/sidebar/Sidebar';
import ProgressBar from "@ramonak/react-progress-bar";
import url from "../../../utils/services/urls.json"
import { getMethodWithToken, postMethodWithToken } from '../../../utils/services/apis';
import ContinueButton from '../../../Components/Buttons/ContinueButton/ContinueButton';
import { useNavigate } from 'react-router-dom';
import { getUserId, navigateLogin } from '../../../utils/services/common';
import { Col, Row } from 'react-bootstrap';
import SideBarMobile from '../../../Components/commonComponents/SideBarMobile/SideBarMobile';

const ManageCurrentSubscription = styled.div`
font-family: Proxima_nova_reg;
margin-top: 20px;
`

const ChangePlan = () => {
    const navigate = useNavigate()
    const moment = require('moment-timezone');
    const [subscriptionList, setSubscriptionList] = useState([])
    const [activePlanDetail, setActivePlanDetail] = useState([])
    const [subscriptionSelect, setSubscriptionSelect] = useState("")
    const [listingActive, setListingActive] = useState("")
    const [totalListing, setTotalListing] = useState("")
    const [mobileModal, setMobileModal] = useState(false)
    const subscriptionDetail = localStorage.getItem("subscriptionData") !== "undefined" ? JSON.parse(localStorage.getItem("subscriptionData")) : "";

    useEffect(() => {
        getSubscriptionList();
        getActiveSubscription()
    }, [])
    const getSubscriptionList = async () => {
        await getMethodWithToken(url.subscriptionList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setSubscriptionList(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    const getActiveSubscription = async () => {
        await getMethodWithToken(url.getActiveSubscription).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setActivePlanDetail(response?.data)
                setListingActive(response?.data?.subscription_detail && Object.keys(response?.data?.subscription_detail).length > 0 ? response?.data?.subscription_detail?.listed_property : subscriptionDetail?.listed_property)
                setTotalListing(response?.data?.subscription_detail && Object.keys(response?.data?.subscription_detail).length > 0 ? response?.data?.subscription_detail?.total_list_property : subscriptionDetail?.total_list_property)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }

    const handleSubscriptionSelect = (value) => {
        console.log(value)
        setSubscriptionSelect(value?.subscription_details?.[0]?.id)
        localStorage.setItem("listPrice", value?.subscription_details?.[0]?.pricePerListing?.slice(1))
    }
    const handleCheckout = async () => {
        let body = {
            "user_id": getUserId(),
            "masterSubscrption": subscriptionSelect
        }
        await postMethodWithToken(url.postSubscription, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                navigate("/Dashboard/ManageSubscription/PaymentDetail")
            }
        })
    }

    const handleGetModal = (value = false) => {
        console.log(value)
        setMobileModal(value)
    }


    return (
        <>
            <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
                <Navbar />
                <div className="d-flex">
                    <div className="Sidebar_control side-resposne">
                        <Sidebar />
                    </div>
                    <div className="Sidebar_control side-value-component">
                        <div style={{ marginTop: '80px', marginBottom: '5.5vh' }}>
                            <ManageCurrentSubscription>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
                                        <div className="current-subscription-container">
                                            <div className="current-heading">Current Plan</div>
                                            <hr />
                                            {(activePlanDetail?.is_active === true || activePlanDetail?.[0]?.is_active === true) ?
                                                ""
                                                :
                                                <div class="d-flex justify-content-center active-heading">No Active Plan</div>
                                            }
                                            <div className="term-heading">{activePlanDetail?.subscription_detail && Object.keys(activePlanDetail?.subscription_detail).length > 0 ? activePlanDetail?.subscription_detail?.Subscription_type?.length > 0 && getUpparCaseName(activePlanDetail?.subscription_detail?.Subscription_type) : activePlanDetail?.[0]?.masterSubscrption?.length > 0 && getUpparCaseName(activePlanDetail?.[0]?.masterSubscrption)}</div>
                                            <div className="d-flex mt-2" >
                                                <div className="price-manager-heading" >${activePlanDetail?.subscription_detail && Object.keys(activePlanDetail?.subscription_detail).length > 0 ? activePlanDetail?.subscription_detail?.subscription_price : activePlanDetail?.[0]?.subscription_price}</div>
                                                <div className="month-manager-heading">/ {subscriptionDetail?.Subscription_type === "Long term" ? "year" : "month"}</div>
                                            </div>
                                            <div className="payment-description">Plan ended</div>
                                            <div className="date-description">{activePlanDetail?.subscription_detail && Object.keys(activePlanDetail?.subscription_detail).length > 0 ? activePlanDetail?.end_date && moment.tz(activePlanDetail?.end_date, activePlanDetail?.end_date).format(("YYYY-MM-DD")) : activePlanDetail?.[0]?.end_date && moment.tz(activePlanDetail?.[0]?.end_date, activePlanDetail?.[0]?.end_date).format(("YYYY-MM-DD"))}</div>
                                            <div className="d-flex justify-content-between percent-box">
                                                <div className="plan-description">Remaining Listings</div>
                                                <div className="percent-description" style={{ marginTop: "20px" }}>{activePlanDetail?.subscription_detail && Object.keys(activePlanDetail?.subscription_detail).length > 0 ? activePlanDetail?.subscription_detail?.remaining_list : subscriptionDetail?.remaining_list}<span>/{activePlanDetail?.subscription_detail && Object.keys(activePlanDetail?.subscription_detail).length > 0 ? activePlanDetail?.subscription_detail?.total_list_property : subscriptionDetail?.total_list_property}</span></div>
                                            </div>
                                            <ProgressBar completed={listingActive / totalListing * 100} bgColor="#0075CA" isLabelVisible={false} className="progressBar" height="10px" />
                                            <hr />
                                            <div className="d-flex justify-content-center listing-property">Subscribe to keep listing properties</div>
                                            <div className="d-flex justify-content-center select-package-heading">Select a Subscription Package</div>
                                            <div className="d-flex justify-content-center select-package-description">Any unused listing will be carried over to the next month</div>
                                            <div className="row d-flex justify-content-center">
                                                {subscriptionList && subscriptionList.length > 0 && subscriptionList.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 subscription-box" >
                                                                <div className="item-heading">{item?.name?.length > 0 && getUpparCaseName(item?.name)} <span className="item-below-description">({item?.description})</span></div>
                                                                <div className="subscribe-button" onClick={() => (subscriptionDetail?.Subscription_type !== item?.name && handleSubscriptionSelect(item))} style={{ border: subscriptionSelect === item?.subscription_details?.[0]?.id ? "3px solid #0075CA" : "2px solid #505050" }}>
                                                                    <div className="amount-term">{item?.subscription_details?.[0]?.pricePerListing} <span className="listing-amount">{item?.subscription_details?.[0]?.listing_text}</span></div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                            <div className="checkout-button">
                                                <ContinueButton text="Continue to Checkout" color={'#fff'} onClick={() => handleCheckout()} backgroundColor={subscriptionSelect !== "" ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} disabled={subscriptionSelect === ""} fontSize={"14px"} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </ManageCurrentSubscription>
                        </div>
                    </div>
                </div>
                <div className="Sidebar_control side-mobile-responsive">
                    <SideBarMobile getModal={(val) => handleGetModal(val)} />
                </div>
            </div>

        </>
    )
}

export default ChangePlan
