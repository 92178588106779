import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import "./SubscriptionPlanList.scss";
import url from "../../../utils/services/urls.json"
import { getMethodWithToken, postMethodWithToken } from '../../../utils/services/apis';
import ContinueButton from '../../../Components/Buttons/ContinueButton/ContinueButton';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import ProgressBar from "@ramonak/react-progress-bar";
import Modal from "react-modal";
import moment from 'moment';
import { getUserId } from '../../../utils/services/common';
import { navigateLogin } from '../../../utils/services/common';
const ManageSubscription = styled.div`
font-family: Proxima_nova_reg;
margin-top: 80px;
`

const SubscriptionPlanList = () => {
  const navigate = useNavigate()
  const moment = require('moment-timezone');
  const [subscriptionList, setSubscriptionList] = useState([])
  const [activePlanDetail, setActivePlanDetail] = useState([])
  const [subscriptionSelect, setSubscriptionSelect] = useState("")
  const [flag, setFlag] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [listingActive, setListingActive] = useState("")
  const [totalListing, setTotalListing] = useState("")
  const subscriptionDetail = localStorage.getItem("subscriptionData") !== "undefined" ? JSON.parse(localStorage.getItem("subscriptionData")) : "";

  const [screenSize, getDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: screenSize.width <= 430 ? "335px" : "710px",
      height: screenSize.width <= 430 ? "350px" : "265px",
    },
  };

  useEffect(() => {
    getActivePlan()
  }, [])
  const getActivePlan = async () => {
    await getMethodWithToken(url.getActiveSubscription).then((response) => {
      if (response.status === 200 || response.status === 201) {
        setActivePlanDetail(response?.data)
        setListingActive(response?.data?.subscription_detail && Object.keys(response?.data?.subscription_detail).length > 0 ? response?.data?.subscription_detail?.listed_property : subscriptionDetail?.listed_property)
        setTotalListing(response?.data?.subscription_detail && Object.keys(response?.data?.subscription_detail).length > 0 ? response?.data?.subscription_detail?.total_list_property : subscriptionDetail?.total_list_property)
        if (response?.data?.length === 0) {
          setFlag(true)
        }
      } else if (response.status === 401) {
        navigateLogin()
        navigate("/federalEmployee/Login")
      }
    })
  }
  useEffect(() => {
    if (flag) {
      getSubscriptionList();
    }
  }, [flag])

  const getSubscriptionList = async () => {
    await getMethodWithToken(url.subscriptionList).then((response) => {
      if (response.status === 200 || response.status === 201) {
        setSubscriptionList(response?.data)
      } else if (response.status === 401) {
        navigateLogin()
        navigate("/federalEmployee/Login")
      }
    })
  }
  const getUpparCaseName = (name) => {
    const words = name.split(" ");
    return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
  }
  const handleSubscriptionSelect = (value) => {
    setSubscriptionSelect(value?.subscription_details?.[0]?.id)
    localStorage.setItem("listPrice", value?.subscription_details?.[0]?.pricePerListing?.slice(1))
  }

  const handleChangePlan = () => {
    navigate("change/plan")
  }
  const opneCancelModal = () => {
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handleCancelSubscription = async () => {
    let body = {
      "payment_id": activePlanDetail?.[0]?.payment_id,
      "property_id": activePlanDetail?.[0]?.propertyMaster,
      "is_cancel": true

    }
    await postMethodWithToken(url.postCancelSubscription, body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        setOpenModal(false)
        getActivePlan()
      }
    })
  }
  const handleContinue = async () => {
    let body = {
      "user_id": getUserId(),
      "masterSubscrption": subscriptionSelect
    }
    await postMethodWithToken(url.postSubscription, body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        navigate("/Dashboard/ManageSubscription/PaymentDetail")
      }
    })
  }
  console.log(activePlanDetail?.subscription_detail?.Subscription_type)
  return (
    <>
      <ManageSubscription>
        {(activePlanDetail && activePlanDetail.length > 0 || activePlanDetail?.subscription_detail) ?
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <div className="subscription-active-container">
                <div className="current-heading">Current Plan</div>
                <hr />
                <div className="active-plan-heading">{activePlanDetail?.subscription_detail && Object.keys(activePlanDetail?.subscription_detail).length > 0 ? activePlanDetail?.subscription_detail?.Subscription_type?.length > 0 && getUpparCaseName(activePlanDetail?.subscription_detail?.Subscription_type) : activePlanDetail?.[0]?.masterSubscrption?.length > 0 && getUpparCaseName(activePlanDetail?.[0]?.masterSubscrption)}</div>
                <div className="d-flex mt-3" >
                  <div className="price-manager-heading" >${activePlanDetail?.subscription_detail && Object.keys(activePlanDetail?.subscription_detail).length > 0 ? activePlanDetail?.subscription_detail?.subscription_price : activePlanDetail?.[0]?.subscription_price}</div>
                  <div className="month-manager-heading">/ {subscriptionDetail?.Subscription_type === "Long term" ? "year" : "month"}</div>
                </div>
                <div className="recurring-description">Recurring</div>
                <Row className="mt-4">
                  <Col xs={6} sm={4} md={3} lg={3} xl={3} xxl={3}>
                    <div className="payment-description">Active since</div>
                    <div className="date-description">{activePlanDetail?.subscription_detail && Object.keys(activePlanDetail?.subscription_detail).length > 0 ? activePlanDetail?.created_at && moment.tz(activePlanDetail?.created_at, activePlanDetail?.created_at).format(("DD MMM YYYY")) : activePlanDetail?.[0]?.created_at && moment.tz(activePlanDetail?.[0]?.created_at, activePlanDetail?.[0]?.created_at).format(("DD MMM YYYY"))}</div>
                  </Col>
                  <Col xs={6} sm={4} md={3} lg={3} xl={3} xxl={3}>
                    <div className="payment-description-end">Ending date</div>
                    <div className="date-description">{activePlanDetail?.subscription_detail && Object.keys(activePlanDetail?.subscription_detail).length > 0 ? activePlanDetail?.end_date && moment.tz(activePlanDetail?.end_date, activePlanDetail?.end_date).format(("DD MMM YYYY")) : activePlanDetail?.[0]?.end_date && moment.tz(activePlanDetail?.[0]?.end_date, activePlanDetail?.[0]?.end_date).format(("DD MMM YYYY"))}</div>
                  </Col>
                </Row>
                <div className="plan-description" style={{ paddingTop: "20px" }}>Plan Usage</div>
                <hr />
                <div className="d-flex justify-content-between percent-box">
                  <div className="plan-description-list">Listings</div>
                  <div className="percent-description">{activePlanDetail?.subscription_detail && Object.keys(activePlanDetail?.subscription_detail).length > 0 ? activePlanDetail?.subscription_detail?.remaining_list : subscriptionDetail?.remaining_list}<span>/{activePlanDetail?.subscription_detail && Object.keys(activePlanDetail?.subscription_detail).length > 0 ? activePlanDetail?.subscription_detail?.total_list_property : subscriptionDetail?.total_list_property}</span></div>
                </div>
                <ProgressBar completed={listingActive / totalListing * 100} bgColor="#0075CA" isLabelVisible={false} className="progressBar" height="10px" />
                <div className="footer-description">*Unused listings will be carried forward to next month</div>
                <div class="d-none d-sm-flex justify-content-end mt-2 mb-4">
                  <ContinueButton text={'Cancel Subscription'} color={'#2F86D1'} backgroundColor={'#fff'} border={'2px solid #2F86D1'} borderRadius={"4px"} fontWeight={500} width={"217px"} onClick={() => opneCancelModal()} />
                  <ContinueButton text={'Change Plan'} color={'#fff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={'217px'} borderRadius={"4px"} fontWeight={600} onClick={() => handleChangePlan()} />
                </div>
                <div className="d-flex d-sm-none justify-content-center mt-3 mb-2">
                  <ContinueButton text={'Cancel Subscription'} color={'#2F86D1'} backgroundColor={'#fff'} border={'2px solid #2F86D1'} borderRadius={"4px"} fontWeight={500} width={"217px"} onClick={() => opneCancelModal()} />
                </div>
                <div className="d-flex d-sm-none justify-content-center mt-2 mb-4">
                  <ContinueButton text={'Change Plan'} color={'#fff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={'217px'} borderRadius={"4px"} fontWeight={600} onClick={() => handleChangePlan()} />
                </div>
              </div>
            </Col>
          </Row>

          :
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <div className="subscription-container">
                <div className="current-heading">Current Plan</div>
                <hr />
                <div class="d-flex justify-content-center active-heading">No Active Plan</div>
                <div class="d-flex justify-content-center active-description">Subscribe to start managing properties</div>
                <div className="d-flex justify-content-center select-package-heading">Select a Subscription Package</div>
                <div className="d-flex justify-content-center select-package-description">Any unused listing will be carried over to the next month</div>
                <div className="row">
                  {subscriptionList && subscriptionList.length > 0 && subscriptionList.map((item, index) => {
                    return (
                      <>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 subscription-box" >
                          <div className="item-heading">{item?.name?.length > 0 && getUpparCaseName(item?.name)} <span className="item-below-description">({item?.description})</span></div>
                          <div className="subscribe-button" onClick={() => handleSubscriptionSelect(item)} style={{ border: subscriptionSelect === item?.subscription_details?.[0]?.id ? "3px solid #0075CA" : "2px solid #505050" }}>
                            <div className="amount-term">{item?.subscription_details?.[0]?.pricePerListing} <span className="listing-amount">{item?.subscription_details?.[0]?.listing_text}</span></div>
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
                <div className="d-flex justify-content-end checkout-button">
                  <ContinueButton text="Continue to Checkout" color={'#fff'} onClick={() => handleContinue()} />
                </div>
              </div>
            </Col>
          </Row>

        }
      </ManageSubscription>
      <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
        <div className="d-flex justify-content-center">
          <div className="modal-subscription-heading">Are you sure you want to cancel your subscription?</div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="modal-subscription-description">You can still use the remaining listings after you cancel the subscription</div>
        </div>
        <div className="d-none d-sm-flex justify-content-end">
          <div className="d-flex" style={{ marginTop: "60px" }}>
            <div className="stay-heading" onClick={() => handleCloseModal()}>Stay Subscribed</div>
            <div>
              <ContinueButton text="Proceed to Cancel Subscription" color={'#fff'} onClick={() => handleCancelSubscription()} width={"300px"} borderRadius={"4px"} />
            </div>
          </div>
        </div>
        <div className="d-flex d-sm-none justify-content-center mt-3 mb-4 stay-heading" onClick={() => handleCloseModal()}>Stay Subscribed</div>
        <div className="d-flex d-sm-none justify-content-center mt-2">
          <ContinueButton text="Proceed to Cancel Subscription" color={'#fff'} onClick={() => handleCancelSubscription()} width={"300px"} borderRadius={"4px"} />
        </div>
      </Modal>
    </>
  )
}

export default SubscriptionPlanList
