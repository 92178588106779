import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import Navbar from '../../../../Components/commonComponents/navbar/Navbar'
import Sidebar from '../../../../Components/commonComponents/sidebar/Sidebar'
import ArrowBack from "../../../../assets/images/listingManager/arrow_back.svg";
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import PhoneIcon from "../../../../assets/images/listingManager/call.svg"
import Mail from "../../../../assets/images/listingManager/mail.svg"
import "./ListNameDetail.scss";
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getMethodWithToken, postMethodWithToken } from '../../../../utils/services/apis';
import url from "../../../../utils/services/urls.json"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Bedroom from "../../../../assets/images/listingManager/bedroom.svg"
import Bathroom from "../../../../assets/images/listingManager/bathroom.svg"
import PropertySize from "../../../../assets/images/listingManager/square_foot.svg"
import Parking from "../../../../assets/images/listingManager/parking.svg"
import Clock from "../../../../assets/images/listingManager/clock.svg"
import Home from "../../../../assets/images/listingManager/home.svg"
import Furnishing from "../../../../assets/images/listingManager/furnishing.svg"
import Reply from "../../../../assets/images/listingManager/reply.svg"
import userImage from "../../../../assets/images/listingManager/listing-manager-user.svg"
import LikeIcon from "../../../../assets/images/listingManager/like-property.svg"
import FilledLikeIcon from "../../../../assets/images/listingManager/filledLikedImage.svg"
import FlagIcon from "../../../../assets/images/listingManager/flag.svg"
import ArrowForward from "../../../../assets/images/listingManager/Arrow-property.svg"
import ContinueButton from "../../../../Components/Buttons/ContinueButton/ContinueButton"
import close from "../../../../assets/images/searchproperty/close.svg";
import back from "../../../../assets/images/searchproperty/back_month.svg";
import fordward from "../../../../assets/images/searchproperty/ford_month.svg";
import calender from "../../../../assets/icons/calendar_month.svg"
import DatePicker from 'react-datepicker';
import Modal from "react-modal";
import FormInput from '../../../../Components/Inputs/FormInput/FormInput';
import slots from '../../../../assets/images/searchproperty/slots.svg';
import ArrowRight from '../../../../assets/images/searchproperty/ArrowRight.svg';
import enGB from 'date-fns/locale/en-GB';
import { toast } from 'react-toastify';
import { navigateLogin } from '../../../../utils/services/common';
import { addDays, addMonths, addYears } from 'date-fns'
import SideBarMobile from '../../../../Components/commonComponents/SideBarMobile/SideBarMobile';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const CustomImage = styled.img`
background:url('${props => props.image}');
height: 182px;
width:100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;

@media (min-width: 1400px) and (max-width: 1690px){
height: 132px;
}

@media (min-width:915px) and (max-width: 1199px) {
     height: 162px;
   }

   @media (min-width: 820px) and (max-width: 915px) {
     height: 130px;
   }
      
 @media (min-width:575px) and  (max-width:768px) {
   height: 142px;
  }

   @media (min-width:431px) and  (max-width:575px) {
   height: 320px;
  }

   @media  (min-width:400px) and  (max-width:431px) {
   height: 220px;
  }

   @media (max-width:400px) {
   height: 190px;
  }
`

const images = [
    {
        imgPath: 'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
    },
    {
        imgPath: 'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
    },
    {
        imgPath: 'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
    },
    {
        imgPath: 'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
    },
];


const ListNameDetail = () => {
    const navigate = useNavigate();
    const moment = require('moment-timezone');
    const params = useParams();
    const today = new Date()
    const theme = useTheme();
    const [userAllList, setUserAllList] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [selectedRentDate, setSelectedRentDate] = useState();
    const [selectedRentEndDate, setSelectedRentEndDate] = useState();
    const [rentalDuration, setRentalDuration] = useState("")
    const [rentPropertyId, setRentPropertyId] = useState('')
    const [rentPrice, setRentPrice] = useState("")
    const [openRentModal, setOpenRentModal] = useState(false)
    const maxSteps = images.length;

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
            // width: "27vw",
            // height: "680px",
            transform: screenSize.width <= 800 ? 'translate(-50%, -50%)' : 'translate(-42%, -50%)',
            width: screenSize.width <= 800 ? "" : screenSize.width <= 1100 ? "" : "40vw",
            height: screenSize.width <= 800 ? "68vh" : "68vh",
            padding: '0px',
        },
    };

    // const handleNext = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    const handleNext = (data, galleryIndex) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === data?.property_images?.length - 1
                ? 0
                : (prevSteps[data?.propertyMaster_id] || 0) + 1
        }));
    };

    const handleBack = (data, galleryIndex) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === 0
                ? data?.property_images?.length - 1
                : (prevSteps[data?.propertyMaster_id] || 0) - 1
        }))

    };

    const handleStepChange = (data, step) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: step
        }));
    };

    useEffect(() => {
        getAllUserList()
    }, [])

    const getAllUserList = async () => {
        await getMethodWithToken(url.userList + params?.id + "/").then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setUserAllList(response?.data)
            } else {

            }
        })
    }

    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }

    const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
        const monthYear = date.toLocaleDateString(undefined, {
            month: 'long',
            year: 'numeric',
        });

        const days = ['Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat', 'Sun'];

        return (
            <div className="custom-header">
                <div className='date_month'>
                    <p className='monthyear'>{monthYear}</p>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <img src={back} onClick={decreaseMonth} alt='back' style={{ cursor: "pointer" }} />
                        <img src={fordward} onClick={increaseMonth} alt='fordward' style={{ cursor: "pointer" }} />
                    </div>

                </div>
                <div style={{ fontSize: '20px', justifyContent: 'space-evenly', display: 'flex', fontFamily: 'Proxima_nove_reg', paddingBottom: '20px' }}>
                    {days.map((day, index) => (
                        <span key={index}>{day}</span>
                    ))}
                </div>
            </div>
        );
    };

    const handleMainCalenderDate = (date) => {
        if (!selectedRentDate) {
            setSelectedRentDate(date);
            if (rentalDuration === "Long term") {
                const newEndDate = new Date(date);
                newEndDate.setFullYear(newEndDate.getFullYear() + 1);
                setSelectedRentEndDate(newEndDate);
            }
        } else if (!selectedRentEndDate || date > selectedRentEndDate) {
            setSelectedRentEndDate(date);
        } else {
            setSelectedRentDate(date);
            if (rentalDuration === "Long term") {
                const newEndDate = new Date(date);
                newEndDate.setFullYear(newEndDate.getFullYear() + 1);
                setSelectedRentEndDate(newEndDate);
            }
        }
    };

    const handleRentProperty = async (id, allValues) => {
        let api = `${url.GetRentProperty}?rentProperty_id=${id}`;
        await getMethodWithToken(api).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setSelectedRentDate(new Date(response.data.start_date));
                setSelectedRentEndDate(new Date(response.data.end_date));
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            } else {
                setSelectedRentDate('');
                setSelectedRentEndDate('');
            }
        });
        setRentPropertyId(id);
        setRentPrice((Number(allValues?.subscription_details?.price) + Number(allValues?.subscription_details?.price) * 5 / 100) + Number(allValues?.subscription_details?.deposit_amount))
        setRentalDuration(allValues?.subscription_details?.masterSubscrption)
        setOpenRentModal(true);
    };

    const dates = addDays(selectedRentDate, 30);
    const midDate = addMonths(selectedRentDate, 10)
    const longDate = addYears(selectedRentDate, 1)

    const handleCloseClick = () => {
        setOpenRentModal(false)
    }
    const handleCloseRentClick = async (start_date, end_date) => {
        let body = {
            property_id: +rentPropertyId,
            start_date: start_date,
            end_date: end_date,
            is_rent: true
        }
        await postMethodWithToken(url.RentPropertyDates, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                navigate(`/Dashboard/list/manager/PaymentDetail/${rentPropertyId}/manager`, {
                    state: {
                        rentPrice: rentPrice
                    }
                })
                setOpenRentModal(false)
            } else {
                toast.error(response?.data?.success)
            }
        })
    }

    const handleChatWithManager = async (propertyMasterId, bedroom, bathroom, propertyType, price, address, per_month, propertySize) => {
        let perMonth = per_month === true ? "per_month" : "per_day";
        const receiverPropertyDetail = {
            // Id: mangerid,
            PropertyMasterId: propertyMasterId,
            bedroom: bedroom,
            bathroom: bathroom,
            propertyType: propertyType,
            price: price,
            address: address,
            per_month: perMonth,
            propertySize: propertySize

        }
        let body = {
            // receiver: mangerid,
            propertyMaster: propertyMasterId,
            content: JSON.stringify(receiverPropertyDetail)
        }

        await postMethodWithToken(url.sendMessage, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                localStorage.setItem('receiverPropertyDetail', response?.data?.content)
                navigate("/Dashboard/Chats")
            }
        })
    }

    const handleSearchDetail = (propertyId) => {
        navigate(`/Dashboard/SearchList/SearchDetail/${propertyId}`)
    }

    const [mobileModal, setMobileModal] = useState(false)

    const handleGetModal = (value = false) => {
        console.log(value)
        setMobileModal(value)
    }

    return (
        <>
            <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
                <Navbar />
                <div className="d-flex">
                    <div className="Sidebar_control side-resposne">
                        <Sidebar />
                    </div>
                    <div className="Sidebar_control side-value-list">
                        <div style={{ marginTop: '80px', marginBottom: '5.5vh' }}>
                            <div className="list-profile">
                                <div className="d-none d-lg-flex">
                                    <div className="arrow-box-list" onClick={() => navigate(-1)}>
                                        <img src={ArrowBack} alt="" />
                                    </div>
                                </div>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={4}>
                                        <div className="list-profile-section mt-2">
                                            <div className="d-flex d-lg-none">
                                                <div className="arrow-box-list" onClick={() => navigate(-1)}>
                                                    <img src={ArrowBack} alt="" />
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <img src={userAllList?.profilePic?.file} alt='' className='tenantProfilePic'></img>
                                            </div>
                                            <div className='tenantName d-flex justify-content-center '>{userAllList?.name}</div>

                                            <div className="phone-container" style={{ marginTop: "13px" }}>
                                                <div className='d-flex justify-content-center'>
                                                    <div>
                                                        <img src={PhoneIcon} alt="" className='phone-icon' />
                                                    </div>
                                                    <div className="phone-number">{userAllList?.contact_number}</div>
                                                </div>

                                                <div><hr style={{ border: "1px solid black", height: "20px", marginLeft: "10px", marginTop: "2px" }} /></div>
                                                <div className='d-flex justify-content-center'>
                                                    <div style={{ marginLeft: "10px" }}>
                                                        <img src={Mail} alt="" className='phone-icon' />
                                                    </div>
                                                    <div className="phone-number">{userAllList?.email}</div>
                                                </div>

                                            </div>

                                            <div className="email-conatiner mt-1">
                                                <div>
                                                    <img src={PhoneIcon} alt="" />
                                                </div>
                                                <div className="phone-number">{userAllList?.contact_number}</div>
                                            </div>
                                            <div className="email-conatiner mt-1">
                                                <div style={{ marginLeft: "10px" }}>
                                                    <img src={Mail} alt="" />
                                                </div>
                                                <div className="phone-number">{userAllList?.email}</div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={7} >
                                        <div className="user-profile-list-container">
                                            {userAllList?.property_listing && userAllList?.property_listing.length > 0 && userAllList?.property_listing.map((data, index) => {
                                                return (
                                                    <div className="user-profile-list">
                                                        <Row>
                                                            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                                                                <div>
                                                                    <SwipeableViews
                                                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                                        index={activeStep[data?.propertyMaster_id] || 0}
                                                                        onChangeIndex={(step) => handleStepChange(data, step)}
                                                                        enableMouseEvents
                                                                    >
                                                                        {data?.property_images && data?.property_images.length > 0 && data?.property_images.map((step, index) => {
                                                                            return (
                                                                                <>
                                                                                    <div key={index}>
                                                                                        <CustomImage src={step?.media?.file} alt={step.label} />
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </SwipeableViews>
                                                                    <MobileStepper
                                                                        position="static"
                                                                        variant="text"
                                                                        nextButton={
                                                                            <Button size="small" style={{ color: "white" }} onClick={() => handleNext(data, index)}>
                                                                                {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                                            </Button>
                                                                        }
                                                                        backButton={
                                                                            <Button size="small" style={{ color: "white" }} onClick={() => handleBack(data, index)}>
                                                                                {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                                            </Button>
                                                                        }
                                                                    />
                                                                </div>

                                                                <div class="d-flex justify-content-center rent-box">
                                                                    <div>
                                                                        <div className="rental-price">${data?.subscription_details?.price}<span style={{ fontSize: "16px", color: "#505050", fontWeight: 400, }} >{data?.subscription_details?.per_month ? '/ month' : '/ day'}</span></div>
                                                                        <div className="rent-heading" >Rent</div>
                                                                    </div>
                                                                    <div><hr style={{ border: "1px solid black", height: "40px", marginLeft: "10px", marginTop: "20px" }} /></div>
                                                                    <div>
                                                                        <div className="deposit-price" style={{ marginLeft: "10px" }}>$<span>{data?.subscription_details?.deposit_amount}</span></div>
                                                                        <div className="rent-heading" style={{ marginLeft: "25px" }}>Deposit</div>
                                                                    </div>
                                                                </div>

                                                                <div className="deposit-type" style={{ paddingLeft: "10px" }}>
                                                                    <div className="d-flex">
                                                                        <div className="rent-heading" >Rent : </div>
                                                                        <div className="rental-price">${data?.subscription_details?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{data?.subscription_details?.per_month ? '/ month' : '/ day'}</span></div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="rent-heading">Deposit : </div>
                                                                        <div className="deposit-price">$<span>{data?.subscription_details?.deposit_amount}</span></div>

                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                                                <div className="listing-card-detail" >
                                                                    <div className="mobile-subscription" style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px" }}>{data?.apartmentName} {data?.location?.locality}</div>
                                                                    <Row style={{ borderBottom: "1px solid #B7B7B7", paddingBottom: "5px", paddingTop: "8px" }}>
                                                                        <Col xs={5} sm={5} md={4} lg={4} xl={3} xxl={3} className='d-flex'>
                                                                            <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                            <div className="categroy-heading">Bedroom</div>
                                                                            <div className="category-value">{data?.bathroomCount}</div>
                                                                        </Col>
                                                                        <Col xs={5} sm={5} md={6} lg={6} xl={7} xxl={7} className='d-flex'>
                                                                            <div  ><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                            <div className="categroy-heading">Bathroom</div>
                                                                            <div className="category-value">{data?.bedroomCount}</div>
                                                                        </Col>

                                                                    </Row>
                                                                    <div>
                                                                        <Row >
                                                                            <Col md={12} lg={12} xl={12} xxl={12}>
                                                                                <div className="d-none d-sm-inline">
                                                                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                                        <div><img src={Clock} alt="" style={{ width: "15px", height: "15px" }} /></div>
                                                                                        <div className="categroy-heading">Available from</div>
                                                                                    </div>
                                                                                    <div className="select-value-heading">{data?.subscription_details?.propertyAvailfrom !== null && moment.tz(data?.subscription_details?.propertyAvailfrom?.[0], data?.subscription_details?.propertyAvailfrom?.[0]).format("YYYY-MM-DD")}</div>

                                                                                </div>
                                                                            </Col>


                                                                            <Col xs={5} sm={5} md={6} className="d-flex d-sm-none">
                                                                                <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                                    <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                                    <div className="categroy-heading">Available from</div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col xs={7} sm={7} md={6} className="d-flex d-sm-none">
                                                                                <div className="select-value-heading">{data?.subscription_details?.propertyAvailfrom !== null && moment.tz(data?.subscription_details?.propertyAvailfrom?.[0], data?.subscription_details?.propertyAvailfrom?.[0]).format("YYYY-MM-DD")}</div>
                                                                            </Col>

                                                                        </Row>
                                                                        <div className="d-flex justify-content-end view-check">
                                                                            <div class="d-flex align-items-center">
                                                                                <div className="d-flex align-items-center" style={{ marginLeft: "20px", cursor: "pointer" }}>
                                                                                    <div className="view-heading" onClick={() => handleSearchDetail(data?.propertyMaster_id, data?.Property_owner)}>View Details</div>
                                                                                    <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <Modal style={customStyles} isOpen={openRentModal} overlayClassName="myoverlay" >
                                                                            <div className="modal-container" >
                                                                                <div style={{ borderBottom: " 1px solid #EAEAEA", marginBottom: "10px" }} >
                                                                                    <div className="modal-main-heading  calender_header" > <p style={{ fontSize: '24px', fontWeight: '600', fontFamily: 'Proxima_nova_reg', marginBottom: "0px" }}>Select date range</p>
                                                                                        <img src={close} onClick={handleCloseClick} style={{ cursor: 'pointer' }} alt='close' /></div>
                                                                                    <div style={{ display: "flex" }}>
                                                                                        <div className='inputField_calender'>
                                                                                            <FormInput
                                                                                                type='text' name={'Start Date'} id={'name'}
                                                                                                value={selectedRentDate ? selectedRentDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }) : ''}
                                                                                                readOnly />
                                                                                        </div>
                                                                                        <img src={ArrowRight} alt='right' />
                                                                                        <div className='inputField_calender' style={{ position: "relative" }}>
                                                                                            <FormInput
                                                                                                type='text' name={'End Date'} id={'name'}
                                                                                                value={selectedRentEndDate ? selectedRentEndDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }) : ''}
                                                                                                readOnly={false} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='main_calender'>
                                                                                    <DatePicker selected={selectedRentDate || selectedRentEndDate} onChange={handleMainCalenderDate} inline dateFormat="MMMM yyyy" dayClassName={(date) => {
                                                                                        let classes = '';
                                                                                        if (selectedRentDate && selectedRentEndDate) {
                                                                                            if (date.getTime() === new Date(selectedRentDate).getTime()) {
                                                                                                classes += 'start-date';
                                                                                            } else if (date.getTime() === new Date(selectedRentEndDate).getTime()) {
                                                                                                classes += 'end-date';
                                                                                            } else if (date > new Date(selectedRentDate) && date < new Date(selectedRentEndDate)) {
                                                                                                classes += 'range-selected';
                                                                                            }
                                                                                        }
                                                                                        return classes;
                                                                                    }} renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                                                        <CustomHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />)}
                                                                                        locale={enGB}
                                                                                        formatWeekDayShort={(locale, dayOfWeek) => {
                                                                                            const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
                                                                                            return days[dayOfWeek];
                                                                                        }}
                                                                                        minDate={today}
                                                                                        maxDate={rentalDuration === "short term" ? dates : rentalDuration === "mid term" ? midDate : longDate}
                                                                                    />
                                                                                </div>
                                                                                <div style={{ display: "flex", justifyContent: "space-between", padding: "20px 32px 20px 32px ", borderTop: "1px solid #EAEAEA", alignItems: "baseline" }}>
                                                                                    <div className='calender_footer'>
                                                                                        <img src={slots} alt='Like' />
                                                                                        <p className='footer_para'>Available Slots</p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <button className={selectedRentEndDate ? 'continue_button' : 'Disablecontine'} onClick={() => handleCloseRentClick(selectedRentDate, selectedRentEndDate)}>Continue to checkout</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Modal>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                )
                                            })}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className="Sidebar_control side-mobile-responsive">
                <SideBarMobile getModal={(val) => handleGetModal(val)} />
            </div>
        </>
    )
}

export default ListNameDetail
