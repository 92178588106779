import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import url from "../../../utils/services/urls.json"
import { getMethodWithToken, postMethodWithToken, putMethodWithToken } from '../../../utils/services/apis';
import { navigateLogin } from '../../../utils/services/common';
import FilledInput from '@material-ui/core/FilledInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import SendIcon from "../../../assets/images/searchproperty/send.svg"
import Bedroom from "../../../assets/images/searchproperty/Bed.svg"
import Bathroom from "../../../assets/images/searchproperty/bath-chat.svg"
import { makeStyles } from '@material-ui/core/styles';
import "./ChatList.scss";
import { Col, Row } from 'react-bootstrap';
import DefaultImage from "../../../assets/images/default-image.svg";
import ArrowBackChat from "../../../assets/images/arrow-back-chat.svg"

const useStyles = makeStyles(() => ({
    noBorder: {
        '& .MuiFilledInput-root': {
            border: 'none',
        },
        '& .MuiFilledInput-underline:before': {
            borderBottom: 'none',
        },
        '& .MuiFilledInput-underline:after': {
            borderBottom: 'none',
        },
    },
}));

const CustomImage = styled.img`
background:url('${props => props.image}');
height: 60px;
width:60px;
border-radius: 50%;

   @media (max-width:575px) {
    height: 40px;
    width: 40px;
  }
`

const ChatList = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const classes = useStyles();
    const jsonPattern = /^\s*(\{.*\}|\[.*\])\s*$/;
    const [federalChatList, setFederalChatList] = useState([])
    const [searchListId, setSearchListId] = useState("")
    const [propertyId, setPropertyId] = useState("")
    const [receiverPropertyDetail, setReceiverPropertyDetail] = useState([]);
    const [count, setCount] = useState("")
    const [chatComponent, setChatComponent] = useState(false)
    const message = []
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [ListingName, setListingName] = useState("")
    const [listingImage, setListingImage] = useState("")
    const [listingAll, setListingAll] = useState([])
    const [receiveId, setReceivedId] = useState("")

    useEffect(() => {
        handleChatUserList()
        setSearchListId("")
        const intervalId = setInterval(() => {
            handleChatUserList();
        }, 2000);

        return () => clearInterval(intervalId);
    }, [location.pathname === "/Dashboard/Chats"]);

    const handleChatUserList = async () => {
        await getMethodWithToken(url.chatUserList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setFederalChatList(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const handleHistory = async (managerId, propertyId) => {
        await getMethodWithToken(url.chatHistory + managerId + '/').then((response) => {
            if (response.status === 200 || response.status === 201) {
                setReceiverPropertyDetail(response?.data)
            }
            else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    useEffect(() => {
        if (location?.state?.receiverId && location?.state?.propertyMasterId && location?.state?.propertyValue) {
            handleChatHistory(location?.state?.receiverId, location?.state?.propertyMasterId)
        }
    }, [])

    const handleMessage = async (id, propertyId, send_message) => {
        let body = {
            receiver: id,
            propertyMaster: propertyId,
            content: send_message
        }
        await postMethodWithToken(url.sendMessage, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                message.push(response?.data?.content)
                setCount('')
                handleChatHistory(id, propertyId, listingAll)
            }
        })
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleMessage(searchListId, propertyId, count);
        }
    };

    const handleChatHistory = async (managerId, propertyId, listName) => {
        setChatComponent(true)
        setReceivedId("")
        setListingAll(listName)
        setListingName(listName?.other_user?.name)
        setListingImage(listName?.other_user?.profilePic?.file)
        setSearchListId(+managerId)
        setPropertyId(+propertyId)
        await putMethodWithToken(url.updateMessage + managerId + '/').then((response) => {
            if (response.status === 200 || response.status === 201) {
                handleHistory(managerId, propertyId)
            }
        })

    }

    const handleChange = (event) => {
        setCount(event?.target?.value)
    }

    const JsonparseData = (data) => {
        return (
            JSON.parse(data)
        )
    };

    const handleArrowchange = () => {
        setChatComponent(false)
        setSearchListId("")
    }

    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }

    return (
        <Row>
            {(!chatComponent || screenSize.width > 1200) &&
                <Col xs={12} sm={12} md={8} lg={5} xl={4} xxl={4}>
                    <div className="chat-container" style={{ height: (federalChatList?.length <= 7 && screenSize.width < 500) && "82vh" }}>
                        <div style={{ fontSize: "20px", fontWeight: 400, marginLeft: "20px" }} >Messages</div>
                        <div className={federalChatList && federalChatList.length > 0 ? "chat-seaction" : "manager-chat-without-result"} style={{ boxShadow: "0px 2px 8px 0px #0000001F" }}>
                            {federalChatList && federalChatList.length > 0 ?
                                federalChatList.map((item, index) => {
                                    return (
                                        <Row style={{ background: ((searchListId === item?.other_user?.id) || (item?.other_user?.id === receiveId)) ? "#D9EFFF" : "", paddingBottom: "5px", cursor: "pointer", borderBottom: "0.5px solid rgb(217, 217, 217)", padding: "16px" }} onClick={() => handleChatHistory(item?.other_user?.id, item?.propertyId, item)}>
                                            <Col xs={1} sm={1} md={2} lg={2} xl={2} xxl={2} >
                                                <CustomImage src={item?.other_user?.profilePic?.file ? item?.other_user?.profilePic?.file : DefaultImage} alt="" />
                                            </Col>
                                            <Col xs={9} sm={9} md={8} lg={8} xl={8} xxl={8} >
                                                <div className="users-heading" >
                                                    <div className="user-main-heading" >{item?.other_user?.name?.length > 0 && getUpparCaseName(item?.other_user?.name)}</div>
                                                    <div className="user-heading">{item?.other_user?.organization}</div>
                                                </div>
                                            </Col>
                                            <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                                                <div className="request-profile">{item?.message_count}</div>
                                            </Col>
                                        </Row>
                                    )
                                })
                                :
                                <div className="result-heading" >No Chat List Found</div>
                            }
                        </div>

                    </div>
                </Col>
            }
            <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={7}>
                {chatComponent &&
                    <div style={{ marginTop: screenSize.width <= 575 ? "-3px" : "10px" }}>
                        {screenSize.width > 1200 &&
                            <div className="chat-heading">Chats</div>
                        }
                        {screenSize.width < 1200 &&
                            <div className="d-flex chat-section-mobile">
                                <img src={ArrowBackChat} alt="" style={{ paddingRight: "12px", cursor: "pointer" }} onClick={handleArrowchange} />
                                <img src={listingImage} alt="" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
                                <div className="listing-name-mobile">{ListingName?.length > 0 && getUpparCaseName(ListingName)}</div>
                            </div>
                        }
                        <div className="chat-contact-main" style={{ boxShadow: "0px 2px 8px 0px #0000001F" }}>
                            <div className="chat-detail-container" >
                                {receiverPropertyDetail.length > 0 && receiverPropertyDetail.map((data, index) => {
                                    return (
                                        jsonPattern.test(data?.content) ? (
                                            <div className="chat-contact-left" >
                                                <div class="notch"></div>
                                                <div className="d-flex" >
                                                    <div className="listing-user-heading" >${JsonparseData(data?.content)?.price}/</div>
                                                    <div className="listing-user-month">{JsonparseData(data?.content)?.per_month}</div>
                                                </div>
                                                <div className="chat-description">{JsonparseData(data?.content)?.address}</div>
                                                <Row style={{ paddingTop: "7px" }}>
                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="d-flex">
                                                        <div>
                                                            <img src={Bedroom} alt="" />
                                                        </div>
                                                        <div className="chat-bed-heading">{JsonparseData(data?.content)?.bedroom} Bedrooms</div>
                                                    </Col>
                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="d-flex">
                                                        <div>
                                                            <img src={Bathroom} alt="" />
                                                        </div>
                                                        <div className="chat-bed-heading">{JsonparseData(data?.content)?.bathroom} Bedrooms</div>
                                                    </Col>
                                                </Row>
                                                <Row className="d-flex" style={{ paddingTop: "5px" }}>
                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="chat-sqare">{JsonparseData(data?.content)?.propertySize} <span>sq/ft</span></Col>
                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="chat-sqare" style={{ paddingLeft: "30px" }}>{JsonparseData(data?.content)?.propertyType}</Col>
                                                </Row>
                                            </div>
                                        )
                                            :
                                            data?.direction === "received" ? (
                                                <div className="chat-contact-left-2" >
                                                    <div class="notch"></div>
                                                    <div className="chat-contact-heading">{data?.content}</div>
                                                </div>
                                            )
                                                :

                                                <div className="d-flex justify-content-end">
                                                    <div className="chat-contact-right">
                                                        <div class="notch-right"></div>
                                                        <div className="chat-contact-right-heading">{data?.content}</div>
                                                    </div>
                                                </div>
                                    )
                                })}
                            </div>
                            <div className="sent-box">
                                <FormControl fullWidth variant="standard" className={classes.noBorder}>
                                    <FilledInput
                                        id="filled-adornment-weight"
                                        value={count}
                                        onChange={(e) => handleChange(e)}
                                        onKeyDown={handleKeyDown}
                                        endAdornment={<InputAdornment position="end">
                                            <img src={SendIcon} alt="" style={{ cursor: "pointer", paddingRight: "10px" }} onClick={() => handleMessage(searchListId, propertyId, count)} />
                                        </InputAdornment>}
                                        style={{ background: "#F2F2F7", borderBottom: "none", outline: "none" }}
                                        aria-describedby="filled-weight-helper-text"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>
                }

            </Col>
        </ Row >
    )
}

export default ChatList
