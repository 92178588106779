import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import "../TenantManagerList/TenantManagerList.scss"
import { getMethodWithToken } from '../../../utils/services/apis';
import url from '../../../utils/services/urls.json';
import TenantManagerTabDetail from "../TenantManagerTabDetail/TenantManagerTabDetail";
import { navigateLogin } from '../../../utils/services/common';
import { Col, Row } from 'react-bootstrap';
import PhoneIcon from "../../../assets/images/call.svg"
import Mail from "../../../assets/images/mail.svg"
import Message from "../../../assets/images/listingManager/sms.svg"
import ArrowForward from "../../../assets/images/listingManager/Arrow-property.svg"
import DefaultImage from "../../../assets/images/default-image.svg";
import ArrowBackChat from "../../../assets/images/arrow-back-chat.svg"

const TenantList = styled.div`
margin-top: 30px;
width:100%;
`

const TenantManagerList = () => {
    const navigate = useNavigate()
    const [tenantDetail, setTenantDetail] = useState(false)
    const [tenantId, setTenantId] = useState('')
    const [tenantArray, setTenantArray] = useState([])
    const [tenetProfileDetail, SetTenetProfileDetail] = useState([])
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const handleListdetail = (value) => {
        setTenantDetail(true)

        // let arr = [...tenantArray]
        // arr.map((item, index) => (
        //     arr[index].isSelect = false
        // ))
        // const index = arr.findIndex(item => item.id === value.id);
        // arr[index].isSelect = true
        // setTenantArray(arr)
        setTenantId(value?.id)

    }
    const handleTenantList = async () => {
        await getMethodWithToken(url.TenantManagerList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setTenantArray(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    useEffect(() => {
        handleTenantList()
    }, [])

    useEffect(() => {
        if (tenantId) {
            handleTenantDetail()
        }
    }, [tenantId])


    const handleTenantDetail = async () => {
        await getMethodWithToken(url.TenantManagerDetail + tenantId + '/').then((response) => {
            if (response.status == 200 || response.status == 201) {
                SetTenetProfileDetail(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const handleTenantProfile = (tenantId) => {
        navigate(`/Dashboard/TenantManagement/TenantProfile/${tenantId}`)
    }

    const handleArrowchange = () => {
        setTenantDetail(false)
        setTenantId("")
    }

    const arrary = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]

    return (
        <>
            <div className="tenent-manager" >
                <Row>
                    {(!tenantDetail || screenSize.width > 1200) &&
                        <Col xs={12} sm={12} md={7} lg={4} xl={4} xxl={4} className="manager-list-card">
                            <div className="d-flex justify-content-between manager-list">
                                <div className="manager-heading">Tenants</div>
                            </div>
                            <div className="manager-card" style={{ boxShadow: "0px 2px 8px 0px #0000001F" }}>
                                {tenantArray && tenantArray.length > 0 ?
                                    tenantArray.map((data, i) => {
                                        console.log(data)
                                        return (
                                            <div style={{ borderBottom: tenantId === data?.id ? "0.5px solid #2F86D1" : "0.5px solid #D9D9D9", paddingBottom: "5px", cursor: "pointer", background: tenantId === data?.id && "#D9EFFF" }} onClick={() => handleListdetail(data)} >
                                                <div className="d-flex" style={{ paddingLeft: "15px", paddingTop: "10px" }}>
                                                    <div>
                                                        <img src={data?.profilePic ? data?.profilePic : DefaultImage} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                                    </div>
                                                    <div className="users-heading" >
                                                        <div className="user-main-heading">{data?.name}</div>
                                                        <div className="user-heading">{data?.companyName}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )
                                    :
                                    <div style={{ height: "400px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "18px", fontWeight: "500" }}>No Tenants List Found</div>
                                }
                            </div>
                        </Col >

                    }
                    <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={7}>
                        {!tenantDetail ?
                            <div className="listing-manager-detail" >
                                <div className="lising-managed-heading">Tenant Details</div>
                                <div className="manager-card">
                                    <div className="select-dec">
                                        <div className="select-des-value">Select a Tenant to see their details</div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="listing-detail-data">
                                    <div className="d-flex">
                                        <img src={ArrowBackChat} alt="" style={{ paddingRight: "12px", cursor: "pointer" }} onClick={handleArrowchange} />
                                    </div>
                                    <div className="d-flex justify-content-center listing-data" >
                                        <img src={tenetProfileDetail?.tenant_profile?.tenant?.profilePic ? tenetProfileDetail?.tenant_profile?.tenant?.profilePic : DefaultImage} alt="" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                                    </div>
                                    <div className="d-flex justify-content-center ">
                                        <div className="listing-data-heading">{tenetProfileDetail?.tenant_profile?.tenant?.name}</div>
                                    </div>
                                    <Row className="g-2 phone-container">
                                        <Col lg={6}>
                                            <div className="d-flex justify-content-end">
                                                <img src={PhoneIcon} alt="" style={{ marginTop: "-7px" }} />
                                                <div className="phone-number">{tenetProfileDetail?.tenant_profile?.tenant?.contact_number}</div>
                                                <div><hr style={{ border: "1px solid black", height: "20px", marginLeft: "10px", marginTop: "2px" }} /></div>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="d-flex justify-content-start">
                                                <img src={Mail} alt="" />
                                                <div className="phone-number">{tenetProfileDetail?.tenant_profile?.tenant?.email}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="email-conatiner mt-2">
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{tenetProfileDetail?.tenant_profile?.tenant?.contact_number}</div>
                                    </div>
                                    <div className="email-conatiner">
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{tenetProfileDetail?.tenant_profile?.tenant?.email}</div>
                                    </div>
                                    <div className="d-flex justify-content-center" style={{ marginTop: "15px", alignItems: 'center' }}>

                                        <div className="view-heading" onClick={() => handleTenantProfile(tenantId, tenetProfileDetail?.tenant_profile?.tenant)}>View Full Profile</div>
                                        <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                    </div>
                                </div>
                                <div className="responsive-find-list">
                                    <TenantManagerTabDetail tenantId={tenantId} data={tenetProfileDetail} />
                                </div>

                            </div>
                        }
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={7} xxl={7}>
                        {tenantDetail &&
                            <div className="responsive-find-main">
                                <TenantManagerTabDetail tenantId={tenantId} data={tenetProfileDetail} />
                            </div>
                        }
                    </Col>
                </Row>
            </div>

        </>

    )
}
export default TenantManagerList