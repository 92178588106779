import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../../../../Components/commonComponents/navbar/Navbar'
import Sidebar from '../../../../Components/commonComponents/sidebar/Sidebar'
import { FormControl } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ContinueButton from "../../../../Components/Buttons/ContinueButton/ContinueButton";
import { postMethodWithToken, getMethodWithToken } from '../../../../utils/services/apis';
import url from '../../../../utils/services/urls.json';
import ArrowBack from "../../../../assets/images/listingManager/arrow_back.svg";
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RequestUserProfile from "../../../../assets/images/listingManager/manager-user.svg";
import AddManagerListing from '../AddManagerListing/AddManagerListing';
import closeIcon from "../../../../assets/images/listingManager/close.svg";
import MarkerSearch from "../../../../assets/images/listingManager/marker.svg";
import SearchManager from "../../../../assets/images/listingManager/search-manager.svg";
import Modal from "react-modal"
import "./AddManager.scss"
import { toast } from 'react-toastify';
import DefaultImage from "../../../../assets/images/default-image.svg"
import { navigateLogin } from '../../../../utils/services/common';
import { Col, Row } from 'react-bootstrap';
import shareIcon from "../../../../assets/images/listingManager/share-list.svg"
import DetailProfile from "../../../../assets/images/listingManager/listing-manager-user.svg"
import PhoneIcon from "../../../../assets/images/listingManager/call.svg"
import Mail from "../../../../assets/images/listingManager/mail.svg"
import Message from "../../../../assets/images/listingManager/add-manager.svg"
import ArrowBackChat from "../../../../assets/images/arrow-back-chat.svg"
import { makeStyles } from '@material-ui/core/styles';
import SideBarMobile from '../../../../Components/commonComponents/SideBarMobile/SideBarMobile'


const useStyles = makeStyles((theme) => ({
    textField: {
        backgroundColor: '#ffffff',
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#AAAAAA",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#AAAAAA",
                borderWidth: "1px"
            },
            "&:hover fieldset": {
                borderColor: "#AAAAAA",
            },
        },
    },
}));

const AddManager = () => {
    const navigate = useNavigate()
    const classes = useStyles();
    const [managerSearchList, setManagerSearchList] = useState([])
    const [addManager, setAddManager] = useState([])
    const [searchEmail, setSearchEmail] = useState("")
    const [continueButton, setcontinueButton] = useState(false);
    const [listDetail, setListDetail] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [searchInputAddress, setSearchInputAddress] = useState("")
    const [searchListId, setSearchListId] = useState("")
    const [serachInputName, setSearchInputName] = useState("")
    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    // Function to handle window resize
    const handleResize = () => {
        setScreenSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    // Set an event listener to track window size changes
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Custom styles for different screen sizes
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: screenSize.width < 768 ? "80vw" : "700px", 
            height: "250px",
        },
    };

    const handleSearchAddressChange = (event) => {
        setSearchInputAddress(event.target.value)
    }
    const handleSeacrhNameChange = (event) => {
        setSearchInputName(event.target.value)
    }
    useEffect(() => {
        getManagerSearchList();
    }, [])

    const getManagerSearchList = async () => {
        await getMethodWithToken(url.managerSearch + "?manager_search=" + serachInputName + "&address=" + searchInputAddress).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setManagerSearchList(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const handleListdetail = (value) => {
        setSearchListId(value.id)
        setListDetail(true)
    }
    const handleOpenModal = () => {
        setOpenModal(true)
        setSearchEmail("")
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }
    const handleSearchChange = (event) => {
        setSearchEmail(event.target.value)
    }

    useEffect(() => {
        if (searchListId) {
            getAddManagerDetailList()
        }
    }, [searchListId])

    const getAddManagerDetailList = async () => {
        await getMethodWithToken(url.getAddManagerDetail + searchListId + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                setAddManager(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }


    useEffect(() => {
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(searchEmail)) {
            setcontinueButton(true)
        } else {
            setcontinueButton(false)
        }
    }, [searchEmail])


    const handleInvitation = async () => {
        let body = {
            email: searchEmail
        }
        await postMethodWithToken(url.inviteManager, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setOpenModal(false)
                toast.success(response?.data?.message)
            } else {
                toast.error(response?.data?.message)
            }
        });
    }
    const handleAddManager = async (id) => {
        let body = {
            manager_id: id
        }
        postMethodWithToken(url.addManager, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.message)
            }
        })
    }
    const handleArrowchange=()=>{
        setListDetail(false)
        
    }
    const [mobileModal, setMobileModal] = useState(false)

 const handleGetModal = (value = false) => {
    console.log(value)
    setMobileModal(value)
  }
    return (
        <>
            <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
                <Navbar />
                <div className="d-flex add-manager-icon" >
                    <div className="Sidebar_control side-resposne" >
                        <Sidebar />
                    </div>
                    <div className="Sidebar_control side-value-list">
                        <div style={{ marginTop: '80px', marginBottom: '5.5vh' }}>
                            <Row >
                                {(!listDetail || screenSize.width > 1200) && 
                                <Col xs={12} sm={12} md={7} lg={5} xl={4} xxl={4} className="add-manager">
                                    <div className="d-flex" >
                                        <div className="arrow-box-profile" onClick={() => navigate(-1)}>
                                            <img src={ArrowBack} alt="" />
                                        </div>
                                        <div style={{ fontSize: "20px", fontWeight: 600, marginLeft: "10px" }} >Add a manager</div>
                                    </div>
                                    <div className="add-card" >
                                        {/* <div> */}
                                        <div>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="search"
                                                    placeholder="Search by address or ZIP code"
                                                    variant="outlined"
                                                    value={searchInputAddress}
                                                    onChange={(e) => handleSearchAddressChange(e)}
                                                    className={classes.textField}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <IconButton>
                                                                    <img src={MarkerSearch} alt="MarkerSearch" />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}

                                                />

                                            </FormControl>
                                        </div>


                                        <div>
                                            <FormControl fullWidth style={{ marginTop: "12px" }}>
                                                <TextField
                                                    fullWidth
                                                    name="search"
                                                    id="outlined-basic"
                                                    placeholder="Search by name..."
                                                    variant="outlined"
                                                    value={serachInputName}
                                                    onChange={(e) => handleSeacrhNameChange(e)}
                                                    className={classes.textField}
                                                />

                                            </FormControl>

                                        </div>
                                        <div className="d-flex justify-content-end" style={{ marginTop: "20px" }}>
                                            <div className="add-manager-description" onClick={handleOpenModal}>Add manager through invitation</div>
                                            <div className="search-btn-manager" onClick={() => getManagerSearchList()} >
                                                <div><img src={SearchManager} alt="SearchManager" style={{ paddingLeft: "15px" }} /></div>
                                                <div className="search-heading">Search</div>
                                            </div>
                                        </div>
                                        <div className="manager-heading">{managerSearchList && managerSearchList.length > 0 && "Manager near your location"}</div>
                                        <div className={managerSearchList?.length > 0 ? "manager-card" : "manager-card-without-result"}>
                                            {managerSearchList && managerSearchList.length > 0 ?
                                                managerSearchList.map((item, index) => {
                                                    return (
                                                        <Row style={{ background: searchListId === item.id ? "#D9EFFF" : "", paddingBottom: "5px", cursor: "pointer" }} onClick={() => handleListdetail(item)} >
                                                            <Col xs={12} sm={12} md={8} lg={12} xl={8} xxl={8} className="d-flex" style={{ paddingLeft: "15px", paddingTop: "10px" }}>
                                                                <div>
                                                                    <img src={item?.profilePic ? item?.profilePic : DefaultImage} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                                                </div>
                                                                <div className="users-heading" >
                                                                    <div className="user-main-heading" >{item?.name}</div>
                                                                    <div className="user-heading">{item?.companyName}</div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} sm={12} md={4} lg={12} xl={4} xxl={4} className="listing-count" onClick={() => handleAddManager(item?.id)}>Add manager</Col>
                                                        </Row>
                                                    )
                                                })
                                                :
                                                <div className="result-heading" >No results found</div>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                }
                                <Col xs={12} sm={12} md={12} lg={7} xl={7} xxl={7}>
                                    {listDetail ?
                                        <div>
                                            <div className="add-manager-listing-data">
                                                <div className="d-flex justify-content-md-end justify-content-between profile-addmanager"  >
                                                <img src={ArrowBackChat} alt="back" className='d-block d-md-none' style={{ cursor: "pointer" }}  onClick={handleArrowchange}/>
                                                    <img src={shareIcon} alt=""  />
                                                </div>
                                                <div className="d-flex justify-content-center listing-data" >
                                                    <img src={addManager?.manager_profile?.profilePic ? addManager?.manager_profile?.profilePic : DefaultImage} alt="profilePic" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                                                </div>
                                                <div className="d-flex justify-content-center ">
                                                    <div className="listing-data-heading">{addManager?.manager_profile?.name}</div>
                                                </div>
                                                <div className="phone-container d-block d-md-flex" style={{ marginTop: "13px" }}>
                                                    <div className='d-flex justify-content-center'>
                                                    <div>
                                                        <img src={PhoneIcon} alt="" className='phone-icon' />
                                                    </div>
                                                    <div className="phone-number">{addManager?.manager_profile?.contact_number}</div>
                                                    </div>
                                                   
                                                    <div className='d-none d-md-block'><hr style={{ border: "1px solid black", height: "20px", marginLeft: "10px", marginTop: "2px" }} /></div>
                                                    <div className='d-flex justify-content-center'>
                                                    <div style={{ marginLeft: "10px" }}>
                                                        <img src={Mail} alt="" className='phone-icon'/>
                                                    </div>
                                                    <div className="phone-number">{addManager?.manager_profile?.email}</div>
                                                    </div>
                                                   
                                                </div>
                                                <div className="email-conatiner mt-2">
                                                    <div>
                                                        <img src={PhoneIcon} alt="" />
                                                    </div>
                                                    <div className="phone-number">{addManager?.manager_profile?.contact_number}</div>
                                                </div>
                                                <div className="email-conatiner mt-2">
                                                    <div>
                                                        <img src={Mail} alt="" />
                                                    </div>
                                                    <div className="phone-number">{addManager?.manager_profile?.email}</div>
                                                </div>
                                                <div className="d-flex justify-content-center" style={{ marginTop: "15px" }}>
                                                    <div className="msg-box" onClick={() => handleAddManager(addManager?.manager_profile?.id)}>
                                                        <div style={{ marginRight: "10px" }}>
                                                            <img src={Message} alt="" />
                                                        </div>
                                                        <div style={{ fontSize: "16px", fontWeight: 500, color: "#ffffff" }} className='text-add-manager'>Add manager</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="responsive-find-list">
                                                <AddManagerListing searchListId={searchListId} data={addManager} />
                                            </div>
                                        </div>
                                        :
                                        ""
                                    }

                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={7} >
                                    {listDetail &&
                                        <div className="responsive-find-main">
                                            <AddManagerListing searchListId={searchListId} data={addManager} />
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
                <div className="add-manager-modal" >
                    <div className="d-flex justify-content-end" style={{ cursor: "pointer" }} onClick={() => handleCloseModal()}>
                        <img src={closeIcon} alt="" />
                    </div>
                    <div className="manager-modal-heading">Invite Managers</div>

                    <Row className="g-2" style={{ marginTop: "20px" }}>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                            <FormControl fullWidth>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={searchEmail}
                                    placeholder="Enter an email ID"
                                    onChange={(e) => handleSearchChange(e)}
                                    className={classes.textField}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{ paddingLeft: "10px", width: "45px", height: "25px" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <ContinueButton className={continueButton ? "continueMail" : "continueMailDisabled"} backgroundColor={continueButton ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} disabled={!continueButton} text={'Invite'} width={'100%'} borderRadius={"4px"}  color={'#fff'} onClick={() => handleInvitation()} />
                        </Col>
                    </Row>
                </div>
            </Modal>
            <div className="Sidebar_control side-mobile-responsive">
          <SideBarMobile getModal={(val) => handleGetModal(val)} />
        </div>
        </>
    )
}

export default AddManager
