import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../../Components/commonComponents/navbar/Navbar'
import Sidebar from '../../Components/commonComponents/sidebar/Sidebar'
import UserProfile from '../UserProfile/UserProfile'
import FavoritePropertiesListing from '../FavoritedProperties/FavoritePropertiesListing/FavoritePropertiesListing'
import ListingCard from '../MyListings/ListingCard/ListingCard'
import ListingManagerList from '../ListingManagers/ListingManager/ListingManagerList/ListingManagerList'
import SubscriptionPlanList from '../ManageSubscription/SubscriptionPlanList/SubscriptionPlanList'
import TenantManagerList from '../TenantManager/TenantManagerList/TenantManagerList'
import PaymentCard from '../PaymentOption/PaymentCard/PaymentCard'
import ChatList from '../ChatModule/ChatListFederal/ChatList'
import "./Dashboard.scss"
import FindPeopleTab from '../FindPeople/FindPeopleTab/FindPeopleTab'
import SideBarMobile from '../../Components/commonComponents/SideBarMobile/SideBarMobile'


const Dashboard = () => {
  const params = useParams()
  const [myContactModal, setMyContactModal] = useState(false)
  const [mobileModal, setMobileModal] = useState(false)

  const handleGetOpenModal = (data) => {
    setMyContactModal(data)
  }

  const handleGetModal = (value = false) => {
    setMobileModal(value)
  }
  return (
    <>
      <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
        <Navbar getOpenModal={(val) => handleGetOpenModal(val)} />
        <div className='d-flex'>
          <div className="Sidebar_control side-resposne">
            <Sidebar />
          </div>
          <div className={params.value === "PaymentOptions" ? "Sidebar_control side-value-pay" : params.value === "ListingManagers" ? "Sidebar_control side-value-list" : params.value === "Chats" ? "Sidebar_control side-value-list" : params.value === "TenantManagement" ? "Sidebar_control side-value-list" : params.value === "FindPeople" ? "Sidebar_control side-value-list" : params.value === "FavoritedProperties" ? "Sidebar_control side-value-list-mobile" : params.value === "MyListings" ? "Sidebar_control side-value-my-list" : "Sidebar_control side-value-component"}>
            <div
              style={{
                display: (params.value !== "PaymentOptions" && params.value !== "ListingManagers" && params.value !== "TenantManagement" && params.value !== "Chats" && params.value !== "userProfile" && params.value !== "ManageSubscription" && params.value !== "FindPeople" && params.value !== "FavoritedProperties") && 'inline-block',
                marginTop: '60px',
                marginBottom: (params.value !== "Chats" && params.value !== "MyListings" && '5.5vh'),
                // marginLeft: "2px"
              }}
            >
              {
                params.value === "userProfile" &&
                <UserProfile />
              }
              {
                params.value === "FavoritedProperties" &&
                <FavoritePropertiesListing mobileModal={mobileModal} />
              }
              {
                params.value === "FindPeople" &&
                <FindPeopleTab myContactModal={myContactModal} mobileModal={mobileModal} />
              }
              {params.value === "MyListings" &&
                <ListingCard myContactModal={myContactModal} mobileModal={mobileModal} />
              }
              {params.value === "ListingManagers" &&
                <ListingManagerList />
              }
              {
                params.value === "Chats" &&
                <ChatList />
              }
              {
                params.value === "TenantManagement" &&
                <TenantManagerList />
              }
              {
                params.value === "ManageSubscription" &&
                <SubscriptionPlanList />
              }
              {
                params.value === "PaymentOptions" &&
                <PaymentCard />
              }

            </div>
          </div>
        </div>
        <div className="Sidebar_control side-mobile-responsive">
          <SideBarMobile getModal={(val) => handleGetModal(val)} />
        </div>

      </div >
    </>
  )
}

export default Dashboard
