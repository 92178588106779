import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import "./FavoritePropertiesListing.scss";
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Bedroom from "../../../assets/images/listingManager/bedroom.svg"
import Bathroom from "../../../assets/images/listingManager/bathroom.svg"
import Clock from "../../../assets/images/listingManager/clock.svg"
import FilledLikeIcon from "../../../assets/images/listingManager/filledLikedImage.svg"
import FlagIcon from "../../../assets/images/listingManager/flag.svg"
import ArrowForward from "../../../assets/images/listingManager/Arrow-property.svg"
import ContinueButton from "../../../Components/Buttons/ContinueButton/ContinueButton"
import { getMethodWithToken, postMethodWithToken } from '../../../utils/services/apis';
import url from '../../../utils/services/urls.json';
import { toast } from "react-toastify";
import close from "../../../assets/images/searchproperty/close.svg";
import back from "../../../assets/images/searchproperty/back_month.svg";
import fordward from "../../../assets/images/searchproperty/ford_month.svg";
import DatePicker from 'react-datepicker';
import Modal from "react-modal";
import FormInput from '../../../Components/Inputs/FormInput/FormInput';
import slots from '../../../assets/images/searchproperty/slots.svg';
import ArrowRight from '../../../assets/images/searchproperty/ArrowRight.svg';
import enGB from 'date-fns/locale/en-GB';
import { navigateLogin } from '../../../utils/services/common';
import { addDays, addMonths, addYears } from 'date-fns'
import DefaultImage from "../../../assets/images/default-image.svg"
import filledMobileFav from "../../../assets/images/listingManager/filled-mobile-fav.svg"

const CustomImage = styled.img`
background:url('${props => props.image}');
height: 182px;
width:100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;

@media (min-width:915px) and (max-width: 1199px) {
     height: 162px;
   }

   @media (min-width: 820px) and (max-width: 915px) {
     height: 162px;
   }
      
 @media (min-width:575px) and  (max-width:768px) {
   height: 142px;
  }

   @media (min-width:431px) and  (max-width:575px) {
   height: 320px;
  }

   @media  (min-width:400px) and  (max-width:431px) {
   height: 220px;
  }

   @media (max-width:400px) {
   height: 190px;
  }
`

const FavoritePropertiesListing = ({ mobileModal }) => {
    const navigate = useNavigate()
    const moment = require('moment-timezone');
    const today = new Date()
    const theme = useTheme();
    const [favoritePropertyList, setFavoritePropertyList] = useState([])
    const [activeStep, setActiveStep] = useState(0);
    const [rentPropertyId, setRentPropertyId] = useState('')
    const [openRentModal, setOpenRentModal] = useState(false)
    const [selectedRentDate, setSelectedRentDate] = useState();
    const [selectedRentEndDate, setSelectedRentEndDate] = useState();
    const [rentalDuration, setRentalDuration] = useState("")
    const [rentPrice, setRentPrice] = useState("")

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: screenSize.width <= 800 ? 'translate(-50%, -50%)' : 'translate(-42%, -50%)',
            width: screenSize.width <= 800 ? "" : screenSize.width <= 1100 ? "" : "40vw",
            height: screenSize.width <= 800 ? "78vh" : screenSize.width <= 1100 ? "75vh" : screenSize.width <= 1700 ? "77vh" : "75vh",
            padding: '0px',
        },
    };

    useEffect(() => {
        handleFavoritePropertyList()
    }, [])

    const handleFavoritePropertyList = async () => {
        await getMethodWithToken(url.favoritePropertyList).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setFavoritePropertyList(response?.data);
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const handleNext = (data, galleryIndex) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === data?.property_images?.length - 1
                ? 0
                : (prevSteps[data?.propertyMaster_id] || 0) + 1
        }));
    };

    const handleBack = (data, galleryIndex) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === 0
                ? data?.property_images?.length - 1
                : (prevSteps[data?.propertyMaster_id] || 0) - 1
        }))

    };

    const handleStepChange = (data, step) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: step
        }));
    };

    const handleFavoritePropertyDetail = (id) => {
        navigate(`/Dashboard/favourite/favouriteDetail/${id}/favourite`)
    }
    const handleChatWithManager = async (propertyMasterId, bedroom, bathroom, propertyType, price, address, per_month, propertySize, id, propertyValue) => {
        let perMonth = per_month === true ? "per_month" : "per_day";
        const receiverPropertyDetail = {
            Id: id,
            PropertyMasterId: propertyMasterId,
            bedroom: bedroom,
            bathroom: bathroom,
            propertyType: propertyType,
            price: price,
            address: address,
            per_month: perMonth,
            propertySize: propertySize,

        }
        let body = {
            receiver: id,
            propertyMaster: propertyMasterId,
            content: JSON.stringify(receiverPropertyDetail)
        }

        await postMethodWithToken(url.sendMessage, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                localStorage.setItem('receiverPropertyDetail', response?.data?.content)
                navigate("/Dashboard/Chats", {
                    state: {
                        receiverId: response?.data?.receiver,
                        propertyMasterId: response?.data?.propertyMaster,
                        propertyValue: propertyValue
                    }
                })
            }
        })
    }

    const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
        const monthYear = date.toLocaleDateString(undefined, {
            month: 'long',
            year: 'numeric',
        });

        const days = ['Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat', 'Sun'];

        return (
            <div className="custom-header">
                <div className='date_month'>
                    <p className='monthyear'>{monthYear}</p>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <img src={back} onClick={decreaseMonth} alt='back' style={{ cursor: "pointer" }} />
                        <img src={fordward} onClick={increaseMonth} alt='fordward' style={{ cursor: "pointer" }} />
                    </div>

                </div>
                <div style={{ fontSize: '20px', justifyContent: 'space-evenly', display: 'flex', fontFamily: 'Proxima_nove_reg', paddingBottom: '20px' }}>
                    {days.map((day, index) => (
                        <span key={index}>{day}</span>
                    ))}
                </div>
            </div>
        );
    };
    const handleMainCalenderDate = (date) => {
        if (!selectedRentDate) {
            setSelectedRentDate(date);
            if (rentalDuration === "Long term") {
                const newEndDate = new Date(date);
                newEndDate.setFullYear(newEndDate.getFullYear() + 1);
                setSelectedRentEndDate(newEndDate);
            }
        } else if (!selectedRentEndDate || date >= selectedRentEndDate) {
            setSelectedRentEndDate(date);
        } else {
            if (selectedRentDate && selectedRentEndDate) {
                setSelectedRentEndDate("")
                setSelectedRentDate("")
            }
            setSelectedRentDate(date);
            if (rentalDuration === "Long term") {
                const newEndDate = new Date(date);
                newEndDate.setFullYear(newEndDate.getFullYear() + 1);
                setSelectedRentEndDate(newEndDate);
            }
        }
    };
    const handleRentProperty = async (id, allValues) => {
        setRentalDuration(allValues?.subscription_details?.masterSubscrption)
        let api = `${url.GetRentProperty}?rentProperty_id=${id}`
        await getMethodWithToken(api).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setSelectedRentDate(new Date(response.data.start_date));
                setSelectedRentEndDate(new Date(response.data.end_date));
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            } else {
                setSelectedRentDate('');
                setSelectedRentEndDate('');
            }
        });
        setRentPropertyId(id)
        setRentPrice((Number(allValues?.subscription_details?.price) + Number(allValues?.subscription_details?.price) * 5 / 100) + Number(allValues?.subscription_details?.deposit_amount))
        setOpenRentModal(true)
    }
    const dates = addDays(selectedRentDate, 30);
    const midDate = addMonths(selectedRentDate, 10)
    const longDate = addYears(selectedRentDate, 1)
    const handleCloseClick = () => {
        setOpenRentModal(false)
    }
    const handleCloseRentClick = async (start_date, end_date) => {
        let body = {
            property_id: +rentPropertyId,
            start_date: start_date,
            end_date: end_date,
            is_rent: true
        }
        await postMethodWithToken(url.RentPropertyDates, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                navigate(`/Dashboard/FavoritePropertyList/PaymentDetail/${rentPropertyId}`, {
                    state: {
                        rentPrice: rentPrice
                    }
                })
                setOpenRentModal(false)
            } else {
                toast.error(response?.data?.success)
            }
        })

    }

    const handleAddFavouriteProperty = async (propertyId) => {
        let body = {
            "property_id": propertyId,
            "favourite": false
        }
        await postMethodWithToken(url.addFavoriteProperty, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                handleFavoritePropertyList()
            }
        })
    }

    return (
        <div className="favourite-listing">
            {favoritePropertyList && favoritePropertyList.length > 0 ?
                favoritePropertyList.map((data, index) => {
                    return (
                        <div className='listing-manager-fav'>
                            <Row style={{ padding: "0" }}>
                                <Col xs={12} sm={5} md={5} lg={4} xl={4} xxl={4} className='mobile-fav'>
                                    <div >
                                        <div className='d-block d-md-none mobile-fav-icon' style={{ zIndex: !mobileModal ? "1" : "0" }}>
                                            <img src={filledMobileFav} alt="" style={{ width: "25px", height: "25px", cursor: "pointer" }} onClick={() => handleAddFavouriteProperty(data?.propertyMaster_id)} />
                                        </div>
                                        <SwipeableViews
                                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                            index={activeStep[data?.propertyMaster_id] || 0}
                                            onChangeIndex={(step) => handleStepChange(data, step)}
                                            enableMouseEvents
                                        >
                                            {data?.property_images.map((step, index) => {
                                                return (
                                                    <>
                                                        <div key={index}>
                                                            <CustomImage src={step?.media?.file} alt={step.label} />
                                                        </div>

                                                    </>
                                                )
                                            })}
                                        </SwipeableViews>
                                        <MobileStepper
                                            position="static"
                                            variant="text"
                                            nextButton={
                                                <Button size="small" style={{ color: "white" }} onClick={() => handleNext(data, index)}>

                                                    {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                </Button>
                                            }
                                            backButton={
                                                <Button size="small" style={{ color: "white" }} onClick={() => handleBack(data, index)}>
                                                    {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                </Button>
                                            }
                                        />

                                    </div>
                                    <div class="d-flex justify-content-center rent-box">
                                        <div>
                                            <div className="rental-price">${data?.subscription_details?.price}<span style={{ fontSize: "16px", color: "#505050", fontWeight: 400, }} >{data?.subscription_details?.per_month ? '/ month' : '/ day'}</span></div>
                                            <div className="rent-heading" >Rent</div>
                                        </div>
                                        <div><hr style={{ border: "1px solid black", height: "40px", marginLeft: "10px", marginTop: "20px" }} /></div>
                                        <div>
                                            <div className="deposit-price" style={{ marginLeft: "10px" }}>$<span>{data?.subscription_details?.deposit_amount}</span></div>
                                            <div className="rent-heading" style={{ marginLeft: "25px" }}>Deposit</div>
                                        </div>
                                    </div>
                                    <div className="deposit-type">
                                        <div className="d-flex">
                                            <div className="rent-heading" >Rent : </div>
                                            <div className="rental-price">${data?.subscription_details?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{data?.subscription_details?.per_month ? '/ month' : '/ day'}</span></div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="rent-heading">Deposit : </div>
                                            <div className="deposit-price">$<span>{data?.subscription_details?.deposit_amount}</span></div>

                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={7} md={7} lg={8} xl={8} xxl={8}>
                                    <div className="listing-card-detail" >
                                        <div className="d-flex justify-content-between section-profile">
                                            <div className="d-flex" >
                                                <div>
                                                    <img src={data?.Property_owner?.[0]?.profilePic ? data?.Property_owner?.[0]?.profilePic : DefaultImage} alt="" className="profile-value" style={{ width: "50px", height: "50px", borderRadius: "50%", cursor: "pointer" }} />
                                                </div>
                                                <div >
                                                    <div className="d-none d-lg-flex" style={{ paddingLeft: "7px" }}>
                                                        <div className="user-heading">{data?.Property_owner?.[0]?.name}</div>
                                                        <div className="user-dot">.</div>
                                                        <div className="property-owner">Property Owner</div>
                                                    </div>
                                                    <div className="d-lg-none" style={{ paddingLeft: "7px" }}>
                                                        <div className="user-heading">{data?.Property_owner?.[0]?.name}</div>
                                                        <div className="property-owner">Property Owner</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex d-sm-none justify-content-end" style={{ cursor: "pointer" }}>
                                                    <div className="view-heading" onClick={() => handleFavoritePropertyDetail(data?.propertyMaster_id)}>View Details</div>
                                                    <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} onClick={() => handleFavoritePropertyDetail(data?.propertyMaster_id)} /></div>
                                                </div>
                                            </div>

                                            <div className="d-none d-sm-flex">
                                                <div className="d-none d-sm-flex">
                                                    <div><img src={FilledLikeIcon} alt="" style={{ width: "20px", height: "20px", cursor: "pointer" }} onClick={() => handleAddFavouriteProperty(data?.propertyMaster_id)} /></div>
                                                    <div><img src={FlagIcon} alt="" style={{ width: "20px", height: "20px", marginLeft: "10px" }} /></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mobile-type">
                                            <div className="d-flex">
                                                <div className="rent-heading" >Rent : </div>
                                                <div className="rental-price">${data?.subscription_details?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{data?.subscription_details?.per_month ? '/ month' : '/ day'}</span></div>
                                            </div>
                                            <div className="d-flex" style={{ marginLeft: "10px" }}>
                                                <div className="rent-heading">Deposit : </div>
                                                <div className="deposit-price">$<span>{data?.subscription_details?.deposit_amount}</span></div>

                                            </div>
                                        </div>

                                        <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{data?.apartmentName} {data?.location?.locality}</div>
                                        <Row style={{ borderBottom: "1px solid #B7B7B7", paddingBottom: "5px", paddingTop: "8px" }}>
                                            <Col xs={5} sm={5} md={6} lg={4} xl={3} xxl={3} className='d-flex'>
                                                <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                <div className="categroy-heading-type ">Bedroom</div>
                                                <div className="category-value">{data?.bedroomCount}</div>
                                            </Col>
                                            <Col xs={7} sm={7} md={6} lg={6} xl={6} xxl={6} className='d-flex'>
                                                <div ><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                <div className="categroy-heading-type ">Bathroom</div>
                                                <div className="category-value">{data?.propertyType?.name === "Room" ? data?.room_sharing?.name : data?.bathroomCount}</div>
                                            </Col>

                                        </Row>
                                        <div>
                                            <Row >
                                                <Col md={12} lg={12} xl={12} xxl={12}>
                                                    <div className="d-none d-sm-inline">
                                                        <div className="d-flex" style={{ marginTop: "10px" }} >
                                                            <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                            <div className="categroy-heading">Available from</div>
                                                        </div>
                                                        <div className="select-value-heading">{data?.subscription_details?.propertyAvailfrom !== null && moment?.tz(data?.subscription_details?.propertyAvailfrom?.[0], data?.subscription_details?.propertyAvailfrom?.[0]).format("YYYY-MM-DD")}</div>
                                                    </div>

                                                 
                                                </Col>

                                                <Col xs={5} sm={5} md={6} className="d-flex d-sm-none">
                                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                                        <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading">Available from</div>
                                                    </div>
                                                </Col>
                                                <Col xs={7} sm={7} md={6} className="d-flex d-sm-none">
                                                    <div className="select-value-heading">{data?.subscription_details?.propertyAvailfrom !== null && moment?.tz(data?.subscription_details?.propertyAvailfrom?.[0], data?.subscription_details?.propertyAvailfrom?.[0]).format("YYYY-MM-DD")}</div>
                                                </Col>

                                            </Row>
                                            <Row className="g-2 custom-margin">
                                                <Col className="d-none d-sm-flex" sm={5} md={5} lg={4} xl={4} xxl={4}>
                                                    <ContinueButton text={'Rent Property'} color={'#fff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={"100%"} borderRadius={"4px"} fontSize={"12px"} fontWeight={500} onClick={() => handleRentProperty(data?.propertyMaster_id, data)} />
                                                </Col>
                                                <Col className="d-none d-sm-flex" sm={5} md={5} lg={4} xl={4} xxl={4}>
                                                    <ContinueButton text={'Chat with Manager'} color={'#2F86D1'} backgroundColor={'#fff'} border={'2px solid #2F86D1'} borderRadius={"4px"} fontSize={"12px"} fontWeight={500} width={"100%"} onClick={() => handleChatWithManager(data?.propertyMaster_id, data?.bedroomCount, data?.bathroomCount, data?.propertyType?.name, data?.subscription_details?.price, data?.location?.locality, data?.subscription_details?.per_month, data?.propertySize, data?.Property_owner?.[0]?.id, data?.Property_owner?.[0])} />
                                                </Col>

                                                <Col sm={2} md={2} lg={4} xl={4} xxl={4} class="d-flex align-items-center">
                                                    <div className="d-none d-sm-flex align-items-center justify-content-end justify-content-lg-start  mt-2" style={{ cursor: "pointer", marginLeft: "15px" }}>
                                                        <div className="view-heading" onClick={() => handleFavoritePropertyDetail(data?.propertyMaster_id)}>View Details</div>
                                                        <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} onClick={() => handleFavoritePropertyDetail(data?.propertyMaster_id)} /></div>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </div>
                                        <Modal style={customStyles} isOpen={openRentModal} overlayClassName="myoverlay" >
                                            <div className="modal-container" >
                                                <div style={{ borderBottom: " 1px solid #EAEAEA", marginBottom: "10px" }} >
                                                    <div className="modal-main-heading  calender_header" > <p style={{ fontSize: '24px', fontWeight: '600', fontFamily: 'Proxima_nova_reg', marginBottom: "0px" }}>Select date range</p>
                                                        <img src={close} onClick={handleCloseClick} style={{ cursor: 'pointer' }} alt='close' /></div>
                                                    <div style={{ display: "flex" }}>
                                                        <div className='inputField_calender'>
                                                            <FormInput
                                                                type='text' name={'Start Date'} id={'name'}
                                                                value={selectedRentDate ? selectedRentDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }) : ''}
                                                                readOnly />
                                                        </div>
                                                        <img src={ArrowRight} alt='right' />
                                                        <div className='inputField_calender' style={{ position: "relative" }}>
                                                            <FormInput
                                                                type='text' name={'End Date'} id={'name'}
                                                                value={selectedRentEndDate ? selectedRentEndDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }) : ''}
                                                                readOnly={false} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='main_calender'>
                                                    <DatePicker onChange={handleMainCalenderDate} inline dateFormat="MMMM yyyy"
                                                        dayClassName={(date) => {
                                                            let classes = '';
                                                            if (selectedRentDate && selectedRentEndDate) {
                                                                if (date.getTime() === new Date(selectedRentDate).getTime()) {
                                                                    classes += 'start-date';
                                                                } else if (date.getTime() === new Date(selectedRentEndDate).getTime()) {
                                                                    classes += 'end-date';
                                                                } else if (date > new Date(selectedRentDate) && date < new Date(selectedRentEndDate)) {
                                                                    classes += 'range-selected';
                                                                }
                                                            }
                                                            return classes;
                                                        }}
                                                        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                            <CustomHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />)}
                                                        locale={enGB}
                                                        formatWeekDayShort={(locale, dayOfWeek) => {
                                                            const days = ['Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat', 'Sun'];
                                                            return days[dayOfWeek];
                                                        }}
                                                        minDate={today}
                                                        maxDate={rentalDuration === "short term" ? dates : rentalDuration === "mid term" ? midDate : longDate}
                                                    />
                                                </div>
                                                <div className="d-flex" style={{ justifyContent: "space-between", padding: "20px 32px 20px 32px ", borderTop: "1px solid #EAEAEA", alignItems: "baseline" }}>
                                                    <div className='calender_footer'>
                                                        <img src={slots} alt='Like' />
                                                        <p className='footer_para'>Available Slots</p>
                                                    </div>
                                                    <div>
                                                        <button className={selectedRentEndDate ? 'continue_button' : 'Disablecontine'} onClick={() => handleCloseRentClick(selectedRentDate, selectedRentEndDate)}>Continue to checkout</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )
                })
                :
                <Row >
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <div className="empty-fav-list">
                            <div className="empty-heading" >No favourite List</div>
                        </div>
                    </Col>
                </Row>
            }
        </div>

    )
}
export default FavoritePropertiesListing

