import React, { useState, useEffect } from 'react'
import Navbar from '../../../Components/commonComponents/navbar/Navbar'
import styled from 'styled-components';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Col, Row } from 'react-bootstrap'
import FormControl from '@mui/material/FormControl';
import { MenuItem, Select } from '@mui/material'
import "../SearchHome/SearchHome.scss"
import SearchNav from '../SearchNav/SearchNav';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FlagIcon from "../../../assets/images/Flag.png"
import Reply from "../../../assets/images/reply.png"
import Bedroom from "../../../assets/images/favoriteProperty/bedroom.svg"
import Bathroom from "../../../assets/images/favoriteProperty/bathroom.svg"
import Parking from "../../../assets/images/favoriteProperty/parking.svg"
import Clock from "../../../assets/images/favoriteProperty/clock.svg"
import Home from "../../../assets/images/favoriteProperty/home.svg"
import Furnishing from "../../../assets/images/favoriteProperty/furnishing.svg"
import ContinueButton from '../../../Components/Buttons/ContinueButton/ContinueButton';
import ArrowForward from "../../../assets/images/favoriteProperty/arrowForward.svg"
import { useLocation, useNavigate } from 'react-router-dom';
import url from "../../../utils/services/urls.json";
import { postMethodWithToken, getMethodWithToken } from '../../../utils/services/apis';
import { toast } from "react-toastify";
import close from "../../../assets/images/searchproperty/close.svg";
import back from "../../../assets/images/searchproperty/back_month.svg";
import fordward from "../../../assets/images/searchproperty/ford_month.svg";
import DatePicker from 'react-datepicker';
import Modal from "react-modal";
import FormInput from '../../../Components/Inputs/FormInput/FormInput';
import slots from '../../../assets/images/searchproperty/slots.svg';
import ArrowRight from '../../../assets/images/searchproperty/ArrowRight.svg';
import enGB from 'date-fns/locale/en-GB';
import { navigateLogin } from '../../../utils/services/common';
import LikeIcon from "../../../assets/images/listingManager/like-property.svg"
import FilledLikeIcon from "../../../assets/images/listingManager/filledLikedImage.svg"
import star from '../../../assets/icons/Star.svg';
import { addDays, addMonths, addYears } from 'date-fns'
import MapIcon from "../../../assets/images/searchproperty/map-icon.svg"

const SearchSelect = styled.div`
padding:15px;
background:#F2F2F7;
height:100vh;
font-family: 'Proxima Nova';
`

const CustomImage = styled.img`
background:url('${props => props.image}');
width:100%;
height: 210px;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;

// @media (min-width:770px) and (max-width: 1199px) {
//      height: 182px;
//    }

//   @media (max-width:768px) {
//    height: 142px;
//   }  

@media (min-width:770px) and (max-width: 1199px) {
     height: 182px;
   }
      
 @media (min-width:575px) and  (max-width:768px) {
   height: 142px;
  }

   @media (min-width:431px) and  (max-width:575px) {
   height: 320px;
  }

   @media  (min-width:400px) and  (max-width:431px) {
   height: 220px;
  }

   @media (max-width:400px) {
   height: 190px;
  }
`

const containerStyle = {
    width: '100%',
    height: '100%',
    // marginTop: "20px",
    // minHeight: '300px'
};

const center = {
    lat: -3.745,
    lng: -38.523
};


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        // height: 100,
        display: 'block',
        // maxWidth: 400,
        overflow: 'hidden',
        width: '100%',
    },
}));

const SearchHome = () => {
    const navigate = useNavigate()
    const today = new Date()
    const location = useLocation()
    const moment = require('moment-timezone');
    const [sortValue, setSortValue] = useState("")
    const [ismarker, setIsmarker] = useState(false)
    const [propId, setPropId] = useState('')
    const [longitude, setLongitude] = useState('')
    const [addressMarker, setAddressMarker] = useState('')
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [showMarkerAddress, setShowMarkerAddress] = useState('')
    const [latitude, setLatitude] = useState('')
    const [rentPropertyId, setRentPropertyId] = useState('')
    const [openRentModal, setOpenRentModal] = useState(false)
    const [selectedRentDate, setSelectedRentDate] = useState();
    const [rentalDuration, setRentalDuration] = useState("")
    const [selectedRentEndDate, setSelectedRentEndDate] = useState();
    const [array, setArray] = useState([])
    const [featureArray, setFeatureArray] = useState([])
    const [images, setImages] = useState([])
    const [featureimages, setFeatureImages] = useState([])
    const [propertytypedata, setPropertytypedata] = useState('');
    const [rentPrice, setRentPrice] = useState("")
    const [favorite, setFavorite] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [arraryMap, setArrayMap] = useState([])
    const [arrayPropertyId, setArrayPropertyId] = useState([])
    const [center, setCenter] = useState({
        lat: -3.745,
        lng: -38.523
    })
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBBNW2PnUIVek7ezyuNFTh1xn6BAj90x7s"
    })
    const [map, setMap] = React.useState(null)


    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);
        // setMap(map)
        map.fitBounds(bounds);

        map.setOptions({
            zoomControl: false,          // Disables the zoom controls (+/-)
            streetViewControl: false,    // Disables the Street View (yellow person) button
        });

        setMap(map);
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    const handleSortChange = (event) => {
        setSortValue(event.target.value)
    }
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const [activePropertyStep, setActivePropertyStep] = useState(0)
    const maxFeatureSteps = featureimages?.length;
    // const maxSteps = images.length;

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: screenSize.width <= 800 ? 'translate(-50%, -50%)' : 'translate(-42%, -50%)',
            width: screenSize.width <= 800 ? "" : screenSize.width <= 1100 ? "" : "40vw",
            height: screenSize.width <= 800 ? "78vh" : screenSize.width <= 1100 ? "75vh" : screenSize.width <= 1700 ? "77vh" : "75vh",
            padding: '0px',
        },
    };

    const handleNext = (data, galleryIndex) => {
        console.log(data, "777")

        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === data?.property_images?.length - 1
                ? 0
                : (prevSteps[data?.propertyMaster_id] || 0) + 1
        }));
    };

    const handleBack = (data, galleryIndex) => {

        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === 0
                ? data?.property_images?.length - 1
                : (prevSteps[data?.propertyMaster_id] || 0) - 1
        }))

    };

    const handleStepChange = (data, step) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: step
        }));
    };


    const handleNextProperty = (data, galleryIndex) => {
        console.log(data, "777")

        setActivePropertyStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMasterId]: prevSteps[data?.propertyMasterId] === data?.propertyImages?.length - 1
                ? 0
                : (prevSteps[data?.propertyMasterId] || 0) + 1
        }));
    };

    const handleBackProperty = (data, galleryIndex) => {

        setActivePropertyStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMasterId]: prevSteps[data?.propertyMasterId] === 0
                ? data?.propertyImages?.length - 1
                : (prevSteps[data?.propertyMasterId] || 0) - 1
        }))

    };

    const handleStepChangeProperty = (data, step) => {

        setActivePropertyStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMasterId]: step
        }));
    };

    const [randomImageIndex, setRandomImageIndex] = useState(null);

    const randomInadexValue = (data) => {
        if (data?.property_images?.length > 0) {
            setRandomImageIndex(getRandomNumber(data?.property_images?.length));
        }
    }

    const getRandomNumber = (number) => {
        return Math.floor(Math.random() * number);
    };

    const getRandomNumberProperty = (number) => {
        return Math.floor(Math.random() * number);
    };



    const handleSearchDetail = (propertyId) => {
        navigate(`/Dashboard/SearchList/SearchDetail/${propertyId}`)
    }
    const handleSearchFeatureDetail = (propertyId) => {
        navigate(`/Dashboard/featureList/${propertyId}/feature_deatil`)
    }

    const handleChildData = (dataFromChild) => {
        setPropertytypedata(dataFromChild);
    };

    const handleSearchList = async () => {
        let apiUrl;

        if (propertytypedata.propertyId || propertytypedata.bedroom || propertytypedata.bathroom || propertytypedata.petsValue || propertytypedata.minPrice || propertytypedata.maxPrice || propertytypedata.furnishingId || propertytypedata.startDate || propertytypedata.endDate || propertytypedata.shortTerm || propertytypedata.midTerm || propertytypedata.longTerm || propertytypedata.searchAddress) {
            apiUrl = `${url.propertyType}?Property_type=${propertytypedata.propertyId}&bedroomCount=${propertytypedata.bedroom}&bathroomCount=${propertytypedata.bathroom}&pet_id=${propertytypedata.petsValue}&furniture_type=${propertytypedata.furnishingId}&min_range=${propertytypedata.minPrice}&max_range=${propertytypedata.maxPrice}&startDate=${propertytypedata.startDate}&endDate=${propertytypedata.endDate}&short_term=${propertytypedata.shortTerm}&mid_term=${propertytypedata.midTerm}&long_term=${propertytypedata.longTerm}&search_address=${propertytypedata.searchAddress}`;
        } else if (location?.state?.data || location?.state?.dataone || location?.state?.datatwo || location?.state?.city || location?.state?.state || location?.state?.startDate || location?.state?.endDate || location?.state?.minPrice || location?.state?.maxPrice || location?.state?.per_month || location?.state?.per_day || location?.state?.short_term || location?.state?.long_term || location?.state?.mid_term) {
            apiUrl = `${url.propertyType}?Property_type=${location?.state?.data}&bedroomCount=${location?.state?.dataone}&bathroomCount=${location?.state?.datatwo}&state=${location?.state?.state}&city=${location?.state?.city}&startDate=${location?.state?.startDate}&endDate=${location?.state?.endDate}&min_range=${location?.state?.minPrice}&max_range=${location?.state?.maxPrice}&short_term=${location?.state?.short_term}&long_term=${location?.state?.long_term}&mid_term=${location?.state?.mid_term}`;
        }
        else if (location?.state?.collectionSlug) {
            apiUrl = `${url.featurePropertyCollection}?collection_slug=${location?.state?.collectionSlug}`
        }
        else {
            apiUrl = url.propertyType
        }
        await getMethodWithToken(apiUrl).then((response) => {
            if (response.status === 200 || response.status === 201) {
                if (location?.state?.collectionSlug) {
                    setFeatureArray(response?.data);
                    // response?.data.map((data) => setFeatureImages(data?.property_images))
                    let arr = [...arraryMap]
                    response?.data.map((data, index) => {
                        setFeatureImages(data?.property_images)
                        arr.push({ lat: data?.location?.latitude, long: data?.location?.longitude })
                        setArrayMap(arr)
                    })
                }
                // else if(propertytypedata.propertyId || propertytypedata.bedroom || propertytypedata.bathroom || propertytypedata.balcony || propertytypedata.searchAddress || propertytypedata.minPrice || propertytypedata.maxPrice || propertytypedata.furnishingId){
                //     setArray(response?.data);
                //     response?.data.map((data) => setImages(data?.propertyImages))
                // }
                else {
                    setArray(response?.data);
                    let arr = [...arraryMap]
                    response?.data.map((data, index) => {
                        setImages(data?.propertyImages)
                        arr.push({ lat: data?.latitude, long: data?.longitude })
                        setArrayMap(arr)
                    })
                }
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })

    };
    const [favPropertyId, setFavPropertyId] = useState("")
    const handleMarker = (id, latitude, longitude, address, allValues) => {
        setPropId(id)
        setLatitude(latitude)
        setLongitude(longitude)
        setIsmarker(true)
        setAddressMarker(address)
        setSelectedMarker({ id, latitude, longitude });
        if (location?.state?.featureDetail) {
            setRentPrice((Number(allValues?.subscription_details?.price) + Number(allValues?.subscription_details?.price) * 5 / 100) + Number(allValues?.subscription_details?.deposit_amount))
        } else {
            setRentPrice((Number(allValues?.price) + Number(allValues?.price) * 5 / 100) + Number(allValues?.deposit_amount))
        }
    }
    const handleMarkerAddress = () => {
        setShowMarkerAddress(addressMarker)
    }
    const handleCloseInfoWindow = () => {
        setShowMarkerAddress(null);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    useEffect(() => {
        handleSearchList();
        scrollToTop()
    }, [propertytypedata]);

    console.log(propertytypedata, "12121")

    // useEffect(() => {
    //     if (propertytypedata) {
    //         handleSearchList()
    //     }
    // }, [propertytypedata])

    const maxDate = new Date("2024-08-02")

    const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
        const monthYear = date.toLocaleDateString(undefined, {
            month: 'long',
            year: 'numeric',
        });

        const days = ['Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat', 'Sun'];

        return (
            <div className="custom-header">
                <div className='date_month'>
                    <p className='monthyear'>{monthYear}</p>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <img src={back} onClick={decreaseMonth} alt='back' style={{ cursor: "pointer" }} />
                        <img src={fordward} onClick={increaseMonth} alt='fordward' style={{ cursor: "pointer" }} />
                    </div>

                </div>
                <div style={{ fontSize: '20px', justifyContent: 'space-evenly', display: 'flex', fontFamily: 'Proxima_nove_reg', paddingBottom: '20px' }}>
                    {days.map((day, index) => (
                        <span key={index}>{day}</span>
                    ))}
                </div>
            </div>
        );
    };
    const handleMainCalenderDate = (date) => {
        // if (!selectedRentDate) {
        //     setSelectedRentDate(date);
        //     if (rentalDuration === "Long term") {
        //         const newEndDate = new Date(date);
        //         newEndDate.setFullYear(newEndDate.getFullYear() + 1);
        //         setSelectedRentEndDate(newEndDate);
        //     }
        // }
        // else if (!selectedRentEndDate || date > selectedRentEndDate) {
        //     setSelectedRentEndDate(date);
        // } else {
        //     if (selectedRentDate && selectedRentEndDate) {
        //         setSelectedRentEndDate("")
        //         setSelectedRentDate("")
        //     }
        //     setSelectedRentDate(date);
        //     if (rentalDuration === "Long term") {
        //         const newEndDate = new Date(date);
        //         newEndDate.setFullYear(newEndDate.getFullYear() + 1);
        //         setSelectedRentEndDate(newEndDate);
        //     }
        // }
        if (!selectedRentDate) {
            setSelectedRentDate(date);
            if (rentalDuration === "Long term") {
                const newEndDate = new Date(date);
                newEndDate.setFullYear(newEndDate.getFullYear() + 1);
                setSelectedRentEndDate(newEndDate);
            }


        } else if (!selectedRentEndDate && date >= selectedRentDate) {
            setSelectedRentEndDate(date);
        } else {
            if (selectedRentDate && selectedRentEndDate) {
                setSelectedRentEndDate("")
                setSelectedRentDate("")
            }
            setSelectedRentDate(date);
            if (rentalDuration === "Long term") {
                const newEndDate = new Date(date);
                newEndDate.setFullYear(newEndDate.getFullYear() + 1);
                setSelectedRentEndDate(newEndDate);
            }
        }
    };

    const handleRentProperty = async (id, value) => {
        if (location?.state?.featureDetail) {
            setRentalDuration(value?.subscription_details?.masterSubscrption)
        } else {
            setRentalDuration(value?.rentalDuration)
        }
        let api = `${url.GetRentProperty}?rentProperty_id=${id}`;
        await getMethodWithToken(api).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setSelectedRentDate(new Date(response.data.start_date));
                setSelectedRentEndDate(new Date(response.data.end_date));
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            } else {
                setSelectedRentDate('');
                setSelectedRentEndDate("");
            }
        });
        setRentPropertyId(id);
        setOpenRentModal(true);
    };
    const dates = addDays(selectedRentDate, 30);
    const midDate = addMonths(selectedRentDate, 10)
    const longDate = addYears(selectedRentDate, 1)

    const handleCloseClick = () => {
        setOpenRentModal(false)
    }
    const handleCloseRentClick = async (start_date, end_date) => {
        let body = {
            property_id: +rentPropertyId,
            start_date: start_date,
            end_date: end_date,
            is_rent: true
        }
        await postMethodWithToken(url.RentPropertyDates, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                navigate(`/Dashboard/search/PaymentDetail/${rentPropertyId}/search/`, {
                    state: {
                        data: location?.state?.data,
                        dataone: location?.state?.dataone,
                        datatwo: location?.state?.datatwo,
                        city: location?.state?.city,
                        startDate: location?.state?.startDate,
                        endDate: location?.state?.endDate,
                        minPrice: location?.state?.minPrice,
                        maxPrice: location?.state?.maxPrice,
                        per_month: (location?.state?.per_month),
                        per_day: (location?.state?.per_day),
                        per_month: (location?.state?.per_month),
                        per_day: (location?.state?.per_day),
                        collectionSlug: (location?.state?.collectionSlug),
                        rentPrice: rentPrice,
                        short_term: (location?.state?.short_term),
                        mid_term: (location?.state?.mid_term),
                        long_term: (location?.state?.long_term)
                    }
                })
                setOpenRentModal(false)
            } else {
                toast.error(response?.data?.success)
            }
        })

    }
    const handleChatWithManager = async (propertyMasterId, bedroom, bathroom, propertyType, price, address, per_month, propertySize, id) => {
        let perMonth = per_month === true ? "per_month" : "per_day";
        const receiverPropertyDetail = {
            Id: id,
            PropertyMasterId: propertyMasterId,
            bedroom: bedroom,
            bathroom: bathroom,
            propertyType: propertyType,
            price: price,
            address: address,
            per_month: perMonth,
            propertySize: propertySize

        }
        let body = {
            receiver: id,
            propertyMaster: propertyMasterId,
            content: JSON.stringify(receiverPropertyDetail)
        }

        await postMethodWithToken(url.sendMessage, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                localStorage.setItem('receiverPropertyDetail', response?.data?.content)
                navigate("/Dashboard/Chats", {
                    state: {
                        receiverId: response?.data?.receiver,
                        propertyMasterId: response?.data?.propertyMaster
                    }
                })
            }
        })
    }
    const handleAddFavouriteProperty = async (propertyId, favoriteValue) => {
        setFavorite(!favorite)
        setFavPropertyId(propertyId)
        let array = [...arrayPropertyId]
        let favoriteClicked;
        if (favoriteValue === false) {
            favoriteClicked = true
        } else {
            favoriteClicked = false
        }
        // if (array.includes(propertyId)) {
        //     let index = array.indexOf(propertyId)
        //     array.splice(index, 1)
        //     favoriteClicked = false
        // } else {
        //     array.push(propertyId)
        //     favoriteClicked = true
        // }
        setArrayPropertyId(array)
        let body = {
            "property_id": propertyId,
            "favourite": favoriteClicked
        }
        await postMethodWithToken(url.addFavoriteProperty, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                handleSearchList()
            }
        })
    }
    const handleMapNavigate = () => {
        if (location?.state?.collectionSlug) {
            navigate("/Dashboard/searchlist/map", {
                state: {
                    featureArray: JSON.stringify(featureArray)
                }
            })
        } else {
            navigate("/Dashboard/searchlist/map", {
                state: {
                    propertyArray: JSON.stringify(array)
                }
            })

        }
    }

    return (
        <>
            <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
                <Navbar />
            </div>
            <div className="search-all-list">
                <div>
                    <SearchNav onData={handleChildData} onClick={handleSearchList} />
                </div>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={7} xxl={7}>
                        <div className="search-section">
                            {featureArray?.length > 0 ? featureArray.map((data, index) => {
                                return (
                                    <div style={{ position: 'relative' }}>
                                        <div className='featurePropertyIcon'>
                                            <img src={star} />
                                            <p className='featurePropertyText'>Featured Property</p>
                                        </div>
                                        <div className="search-conatiner" onClick={() => handleMarker(data?.propertyMaster_id, data?.location?.latitude, data?.location?.longitude, data?.locationlocality, data)} style={data?.propertyMaster_id === propId ? { border: '2px solid #0075CA' } : { border: 'none' }}>
                                            <Row>
                                                <Col md={4} lg={4} xl={4} xxl={4}>
                                                    <div>
                                                        <SwipeableViews
                                                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                            index={activeStep[data?.propertyMaster_id] || 0}
                                                            onChangeIndex={(step) => handleStepChange(data, step)}
                                                            enableMouseEvents
                                                        >
                                                            {data?.property_images.map((step, index) => {
                                                                return (
                                                                    <>
                                                                        <div key={index}>
                                                                            <CustomImage className={classes.img} src={step?.media?.file} alt={step.label} />
                                                                        </div>

                                                                    </>
                                                                )
                                                            })}
                                                        </SwipeableViews>
                                                        <MobileStepper
                                                            position="static"
                                                            variant="text"
                                                            nextButton={
                                                                <Button size="small" style={{ color: "white" }} onClick={() => handleNext(data, index)}>

                                                                    {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                                </Button>
                                                            }
                                                            backButton={
                                                                <Button size="small" style={{ color: "white" }} onClick={() => handleBack(data, index)}>
                                                                    {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                                </Button>
                                                            }
                                                        />

                                                    </div>

                                                    <div class="d-flex justify-content-center rent-box">
                                                        <div>
                                                            <div className="rental-price">${data?.subscription_details?.price}<span style={{ fontSize: "14px", color: "#505050", fontWeight: 400 }} >{data?.subscription_details?.per_month ? '/ month' : '/ day'}</span></div>
                                                            <div className="rent-heading" >Rent</div>
                                                        </div>
                                                        <div><hr style={{ border: "1px solid black", height: "40px", marginLeft: "10px", marginTop: "20px" }} /></div>
                                                        <div>
                                                            <div className="deposit-price" style={{ marginLeft: "10px" }}>$<span>{data?.subscription_details?.deposit_amount}</span></div>
                                                            <div className="rent-heading" style={{ marginLeft: "25px" }}>Deposit</div>
                                                        </div>
                                                    </div>
                                                    <div className="deposit-type">
                                                        <div className="d-flex" style={{ paddingLeft: "10px" }}>
                                                            <div className="rent-heading" >Rent : </div>
                                                            <div className="rental-price">${data?.subscription_details?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{data?.subscription_details?.per_month ? ' / month' : ' / day'}</span></div>
                                                        </div>
                                                        <div className="d-flex" style={{ paddingLeft: "10px" }}>
                                                            <div className="rent-heading">Deposit : </div>
                                                            <div className="deposit-price">$<span>{data?.subscription_details?.deposit_amount}</span></div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                                    <div className="detail-conatiner">
                                                        <div className="d-flex justify-content-between section-profile">
                                                            {data?.Property_owner.map((data) => (
                                                                <div className="d-flex" >
                                                                    <div>
                                                                        <img src={data?.profilePic} alt="" style={{ width: "50px", height: "50px", border: "2px solid #D9D9D9", borderRadius: "50%" }} />
                                                                    </div>

                                                                    <div >
                                                                        <div className="d-flex" style={{ paddingLeft: "7px" }}>
                                                                            <div className="user-heading">{data?.name}</div>

                                                                        </div>
                                                                        <div style={{ color: "#707070", paddingLeft: "7px", fontSize: "16px" }}>{data?.companyName}</div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div>
                                                                <div className="d-flex">
                                                                    <div style={{ cursor: 'pointer' }}><img src={data?.is_favourite ? FilledLikeIcon : LikeIcon} alt="" style={{ width: "20px", height: "20px" }} onClick={() => handleAddFavouriteProperty(data?.propertyMaster_id, data?.is_favourite)} /></div>

                                                                    <div><img src={FlagIcon} alt="" style={{ width: "20px", height: "20px", marginLeft: "10px" }} /></div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="mobile-type">
                                                            <div className="d-flex">
                                                                <div className="rent-heading" >Rent : </div>
                                                                <div className="rental-price">${data?.subscription_details?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{data?.subscription_details?.per_month ? ' / month' : ' / day'}</span></div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="rent-heading">Deposit : </div>
                                                                <div className="deposit-price">$<span>{data?.subscription_details?.deposit_amount}</span></div>
                                                            </div>
                                                        </div>

                                                        <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px" }}>{data?.location?.locality}</div>
                                                        <Row style={{ marginTop: "10px", borderBottom: "1px solid #B7B7B7", paddingBottom: "5px" }} >
                                                            <Col xs={5} sm={5} md={6} lg={4} xl={3} xxl={3} className='d-flex'>
                                                                <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                <div className="categroy-heading">Bedroom</div>
                                                                <div className="category-value">{data?.bedroomCount}</div>
                                                            </Col>
                                                            <Col xs={7} sm={7} md={6} lg={6} xl={6} xxl={6} className='d-flex'>
                                                                <div><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                <div className="categroy-heading">Bathroom</div>
                                                                <div className="category-value">{data?.propertyType?.name === "Room" ? data?.room_sharing?.name : data?.bathroomCount} </div>
                                                            </Col>

                                                        </Row>

                                                        <Row >
                                                            <Col md={12} lg={12} xl={12} xxl={12}>
                                                                <div className="d-none d-sm-inline">
                                                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                        <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                        <div className="categroy-heading">Available from</div>
                                                                    </div>
                                                                    <div className="select-value-heading">{data?.subscription_details?.propertyAvailfrom !== null && moment?.tz(data?.subscription_details?.propertyAvailfrom?.[0], data?.subscription_details?.propertyAvailfrom?.[0]).format("YYYY-MM-DD")}</div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={5} sm={5} md={6} className="d-flex d-sm-none">
                                                                <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                    <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                    <div className="categroy-heading">Available from</div>
                                                                </div>
                                                            </Col>

                                                            <Col xs={7} sm={7} md={6} className="d-flex d-sm-none">
                                                                <div className="select-value-heading">{data?.subscription_details?.propertyAvailfrom !== null && moment?.tz(data?.subscription_details?.propertyAvailfrom?.[0], data?.subscription_details?.propertyAvailfrom?.[0]).format("YYYY-MM-DD")}</div>
                                                            </Col>

                                                        </Row>

                                                        <Row className="g-2" style={{ marginTop: "17px" }}>
                                                            <Col className="d-none d-sm-flex" md={4} lg={4} xl={4} xxl={4}>
                                                                <ContinueButton text={'Rent Property'} color={'#fff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={'100%'} borderRadius={"4px"} fontSize={"12px"} fontWeight={600} onClick={() => handleRentProperty(data?.propertyMaster_id, data)} />
                                                            </Col>
                                                            <Col className="d-none d-sm-flex" md={4} lg={4} xl={4} xxl={4}>
                                                                <ContinueButton text={'Chat with Manager'} color={'#2F86D1'} backgroundColor={'#fff'} border={'2px solid #2F86D1'} borderRadius={"4px"} fontSize={"12px"} fontWeight={600} width={"100%"} onClick={() => handleChatWithManager(data?.propertyMaster_id, data?.bedroomCount, data?.bathroomCount, data?.propertyType?.name, data?.subscription_details?.price, data?.location?.locality, data?.subscription_details?.per_month, data?.propertySize, data?.Property_owner?.[0]?.id)} />
                                                            </Col>
                                                            <Col md={4} lg={4} xl={4} xxl={4} class="d-flex align-items-center">
                                                                <div className="d-flex align-items-center justify-content-end justify-content-lg-start" style={{ marginTop: "10px", cursor: "pointer", marginLeft: "15px" }}>
                                                                    <div className="view-heading" onClick={() => handleSearchFeatureDetail(data?.propertyMaster_id)} style={{ cursor: "pointer" }}>View Details</div>
                                                                    <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} onClick={() => handleSearchFeatureDetail(data?.propertyMaster_id)} /></div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Modal style={customStyles} isOpen={openRentModal} overlayClassName="myoverlay" >
                                                            <div className="modal-container" >
                                                                <div style={{ borderBottom: " 1px solid #EAEAEA", marginBottom: "10px" }} >
                                                                    <div className="modal-main-heading  calender_header" > <p style={{ fontSize: '24px', fontWeight: '600', fontFamily: 'Proxima_nova_reg', marginBottom: "0px" }}>Select date range</p>
                                                                        <img src={close} onClick={handleCloseClick} style={{ cursor: 'pointer' }} alt='close' /></div>
                                                                    <div style={{ display: "flex" }}>
                                                                        <div className='inputField_calender'>
                                                                            <FormInput
                                                                                type='text' name={'Start Date'} id={'name'}
                                                                                value={selectedRentDate ? selectedRentDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }) : ''}
                                                                                readOnly />
                                                                        </div>
                                                                        <img src={ArrowRight} alt='right' />
                                                                        <div className='inputField_calender' style={{ position: "relative" }}>
                                                                            <FormInput
                                                                                type='text' name={'End Date'} id={'name'}
                                                                                value={selectedRentEndDate ? selectedRentEndDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }) : ''}
                                                                                readOnly={false} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='main_calender'>
                                                                    <DatePicker onChange={handleMainCalenderDate} inline dateFormat="MMMM yyyy" dayClassName={(date) => {
                                                                        let classes = '';
                                                                        if (selectedRentDate && selectedRentEndDate) {
                                                                            if (date.getTime() === new Date(selectedRentDate).getTime()) {
                                                                                classes += 'start-date';
                                                                            } else if (date.getTime() === new Date(selectedRentEndDate).getTime()) {
                                                                                classes += 'end-date';
                                                                            } else if (date > new Date(selectedRentDate) && date < new Date(selectedRentEndDate)) {
                                                                                classes += 'range-selected';
                                                                            }
                                                                        }
                                                                        return classes;
                                                                    }}
                                                                        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                                            <CustomHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />
                                                                        )}
                                                                        locale={enGB}
                                                                        formatWeekDayShort={(locale, dayOfWeek) => {
                                                                            const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
                                                                            return days[dayOfWeek];
                                                                        }}
                                                                        minDate={today}
                                                                        maxDate={rentalDuration === "short term" ? dates : rentalDuration === "mid term" ? midDate : longDate}
                                                                    />
                                                                </div>
                                                                <div className="d-xl-flex" style={{ justifyContent: "space-between", padding: "20px 32px 20px 32px ", borderTop: "1px solid #EAEAEA", alignItems: "baseline" }}>
                                                                    <div className='calender_footer'>
                                                                        <img src={slots} alt='Like' />
                                                                        <p className='footer_para'>Available Slots</p>
                                                                    </div>
                                                                    <div>
                                                                        <button className={selectedRentEndDate ? 'continue_button' : 'Disablecontine'} onClick={() => handleCloseRentClick(selectedRentDate, selectedRentEndDate)}>Continue to checkout</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Modal>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                )
                            }

                            ) :
                                array?.length > 0 ? array.map((data, index) => {
                                    return (
                                        <>
                                            <div style={{ position: 'relative' }}>
                                                {data?.is_featured &&
                                                    <div className='featurePropertyIcon'>
                                                        <img src={star} />
                                                        <p className='featurePropertyText'>Featured Property</p>
                                                    </div>}
                                                <div className="search-conatiner" onClick={() => handleMarker(data?.propertyMasterId, data?.latitude, data?.longitude, data?.locality, data)} style={data?.propertyMasterId === propId ? { border: '2px solid #0075CA' } : { border: 'none' }}>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                                                            <div>
                                                                <SwipeableViews
                                                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                                    index={activePropertyStep[data?.propertyMasterId] || 0}
                                                                    onChangeIndex={(step) => handleStepChangeProperty(data, step)}
                                                                    enableMouseEvents
                                                                >
                                                                    {data?.propertyImages.map((step, index) => {
                                                                        return (
                                                                            <>
                                                                                <div key={index}>
                                                                                    <CustomImage className={classes.img} src={step?.file_url} alt={step.label} />
                                                                                </div>

                                                                            </>
                                                                        )
                                                                    })}
                                                                </SwipeableViews>
                                                                <MobileStepper
                                                                    position="static"
                                                                    variant="text"
                                                                    nextButton={
                                                                        <Button size="small" style={{ color: "white" }} onClick={() => handleNextProperty(data, index)}>

                                                                            {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                                        </Button>
                                                                    }
                                                                    backButton={
                                                                        <Button size="small" style={{ color: "white" }} onClick={() => handleBackProperty(data, index)}>
                                                                            {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                                        </Button>
                                                                    }
                                                                />
                                                            </div>
                                                            <div class="d-flex justify-content-center rent-box">
                                                                <div>
                                                                    <div className="rental-price">${data?.price}<span style={{ fontSize: "14px", color: "#505050", fontWeight: 400 }} >{data?.per_month ? '/ month' : '/ day'}</span></div>
                                                                    <div className="rent-heading" >Rent</div>
                                                                </div>
                                                                <div><hr style={{ border: "1px solid black", height: "40px", marginLeft: "10px", marginTop: "20px" }} /></div>
                                                                <div>
                                                                    <div className="deposit-price" style={{ marginLeft: "10px" }}>$<span>{data?.deposit_amount}</span></div>
                                                                    <div className="rent-heading" style={{ marginLeft: "25px" }}>Deposit</div>
                                                                </div>
                                                            </div>
                                                            <div className="deposit-type">
                                                                <div className="d-flex" style={{ paddingLeft: "10px" }}>
                                                                    <div className="rent-heading" >Rent : </div>
                                                                    <div className="rental-price">${data?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{data?.per_month ? ' / month' : ' / day'}</span></div>
                                                                </div>
                                                                <div className="d-flex" style={{ paddingLeft: "10px" }}>
                                                                    <div className="rent-heading">Deposit : </div>
                                                                    <div className="deposit-price">$<span>{data?.deposit_amount}</span></div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                                            <div className="detail-conatiner">
                                                                <div className="d-flex justify-content-between section-profile">
                                                                    <div className="d-flex" >
                                                                        <div>
                                                                            <img src={data?.profile} alt="" style={{ width: "50px", height: "50px", border: "2px solid #D9D9D9", borderRadius: "50%" }} />
                                                                        </div>
                                                                        <div >
                                                                            <div className="d-flex" style={{ paddingLeft: "7px" }}>
                                                                                <div className="user-heading">{data?.name}</div>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="d-flex">
                                                                            <div style={{ cursor: 'pointer' }}><img src={(data?.is_favourite) ? FilledLikeIcon : LikeIcon} alt="" style={{ width: "20px", height: "20px" }} onClick={() => handleAddFavouriteProperty(data?.propertyMasterId, data?.is_favourite)} /></div>
                                                                            <div><img src={FlagIcon} alt="" style={{ width: "20px", height: "20px", marginLeft: "10px" }} /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="mobile-type">
                                                                    <div className="d-flex">
                                                                        <div className="rent-heading" >Rent : </div>
                                                                        <div className="rental-price">${data?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{data?.per_month ? ' / month' : ' / day'}</span></div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="rent-heading">Deposit : </div>
                                                                        <div className="deposit-price">$<span>{data?.deposit_amount}</span></div>

                                                                    </div>
                                                                </div>

                                                                <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px" }}>{data?.locality}</div>
                                                                <Row style={{ marginTop: "10px", borderBottom: "1px solid #B7B7B7", paddingBottom: "5px" }} >
                                                                    <Col xs={5} sm={5} md={6} lg={4} xl={3} xxl={3} className='d-flex'>
                                                                        <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                        <div className="categroy-heading">Bedroom</div>
                                                                        <div className="category-value">{data?.bedroomCount}</div>
                                                                    </Col>
                                                                    <Col xs={7} sm={7} md={6} lg={6} xl={6} xxl={6} className='d-flex'>
                                                                        <div><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                        <div className="categroy-heading">Bathroom</div>
                                                                        <div className="category-value">{data?.propertyType === "Room" ? data?.room_sharing : data?.bathroomCount} </div>
                                                                    </Col>

                                                                </Row>
                                                                <div>
                                                                    <Row >
                                                                        <Col md={4} lg={4} xl={4} xxl={4}>
                                                                            <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                                <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                                <div className="categroy-heading">Available from</div>
                                                                            </div>
                                                                            <div className="select-value-heading">{data?.propertyAvailfrom !== null && moment?.tz(data?.propertyAvailfrom, data?.propertyAvailfrom).format("YYYY-MM-DD")}</div>
                                                                        </Col>

                                                                    </Row>
                                                                </div>
                                                                <Row className="g-2" style={{ marginTop: "17px" }}>
                                                                    <Col className="d-none d-sm-flex" md={4} lg={4} xl={4} xxl={4}>
                                                                        <ContinueButton text={'Rent Property'} color={'#fff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={'100%'} borderRadius={"4px"} fontSize={"12px"} fontWeight={600} onClick={() => handleRentProperty(data?.propertyMasterId, data)} />
                                                                    </Col>
                                                                    <Col className="d-none d-sm-flex" md={4} lg={4} xl={4} xxl={4}>
                                                                        <ContinueButton text={'Chat with Manager'} color={'#2F86D1'} backgroundColor={'#fff'} border={'2px solid #2F86D1'} borderRadius={"4px"} fontSize={"12px"} fontWeight={600} width={"100%"} onClick={() => handleChatWithManager(data?.propertyMasterId, data?.bedroomCount, data?.bathroomCount, data?.propertyType, data?.price, data?.locality, data?.per_month, data?.propertySize, data?.id)} />
                                                                    </Col>
                                                                    <Col md={4} lg={4} xl={4} xxl={4} class="d-none d-md-flex align-items-center">
                                                                        <div className="d-flex align-items-center justify-content-end justify-content-lg-start" style={{ marginTop: "10px", cursor: "pointer", marginLeft: "15px" }}>
                                                                            <div className="view-heading" onClick={() => handleSearchDetail(data?.propertyMasterId)} style={{ cursor: "pointer" }}>View Details</div>
                                                                            <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} onClick={() => handleSearchDetail(data?.propertyMasterId)} /></div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                {/* <div class="d-flex d-md-none align-items-center justify-content-end">
                                                                    <div className="d-flex align-items-center" style={{ marginRight: "20px", marginTop: "10px", cursor: "pointer" }}>
                                                                        <div className="view-heading" oonClick={() => handleSearchDetail(data?.propertyMasterId)} style={{ cursor: "pointer" }}>View Details</div>
                                                                        <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                    </div>
                                                                </div> */}

                                                                <Modal style={customStyles} isOpen={openRentModal} overlayClassName="myoverlay" >
                                                                    <div className="modal-container" >
                                                                        <div style={{ borderBottom: " 1px solid #EAEAEA", marginBottom: "10px" }} >
                                                                            <div className="modal-main-heading  calender_header" > <p style={{ fontSize: '24px', fontWeight: '600', fontFamily: 'Proxima_nova_reg', marginBottom: "0px" }}>Select date range</p>
                                                                                <img src={close} onClick={handleCloseClick} style={{ cursor: 'pointer' }} alt='close' /></div>
                                                                            <div style={{ display: "flex" }}>
                                                                                <div className='inputField_calender'>
                                                                                    <FormInput
                                                                                        type='text' name={'Start Date'} id={'name'}
                                                                                        value={selectedRentDate ? selectedRentDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }) : ''}
                                                                                        readOnly />
                                                                                </div>
                                                                                <img src={ArrowRight} alt='right' />
                                                                                <div className='inputField_calender' style={{ position: "relative" }}>
                                                                                    <FormInput
                                                                                        type='text' name={'End Date'} id={'name'}
                                                                                        value={selectedRentEndDate ? selectedRentEndDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }) : ''}
                                                                                        readOnly={false} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='main_calender'>
                                                                            <DatePicker onChange={handleMainCalenderDate} inline dateFormat="MMMM yyyy" dayClassName={(date) => {
                                                                                let classes = '';
                                                                                if (selectedRentDate && selectedRentEndDate) {
                                                                                    if (date.getTime() === new Date(selectedRentDate).getTime()) {
                                                                                        classes += 'start-date';
                                                                                    } else if (date.getTime() === new Date(selectedRentEndDate).getTime()) {
                                                                                        classes += 'end-date';
                                                                                    } else if (date > new Date(selectedRentDate) && date < new Date(selectedRentEndDate)) {
                                                                                        classes += 'range-selected';
                                                                                    }
                                                                                }
                                                                                return classes;
                                                                            }}
                                                                                renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                                                    <CustomHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />)}
                                                                                locale={enGB}
                                                                                formatWeekDayShort={(locale, dayOfWeek) => {
                                                                                    const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
                                                                                    return days[dayOfWeek];
                                                                                }}
                                                                                minDate={today}
                                                                                maxDate={rentalDuration === "short term" ? dates : rentalDuration === "mid term" ? midDate : longDate}
                                                                            />
                                                                        </div>
                                                                        <div className="d-xl-flex" style={{ justifyContent: "space-between", padding: "20px 32px 20px 32px ", borderTop: "1px solid #EAEAEA", alignItems: "baseline" }}>
                                                                            <div className='calender_footer'>
                                                                                <img src={slots} alt='Like' />
                                                                                <p className='footer_para'>Available Slots</p>
                                                                            </div>
                                                                            <div>
                                                                                <button className={selectedRentEndDate ? 'continue_button' : 'Disablecontine'} onClick={() => handleCloseRentClick(selectedRentDate, selectedRentEndDate)}>Continue to checkout</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Modal>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                        </>

                                    )
                                }
                                )
                                    :
                                    <div className="empty-property-home">
                                        No Property List Found
                                    </div>
                            }

                        </div>

                    </Col>
                    <div className="d-flex d-xl-none justify-content-center">
                        <div className="map-show" onClick={() => handleMapNavigate()}>
                            <div>Show map</div>
                            <img src={MapIcon} alt="" style={{ marginLeft: "12px" }} />
                        </div>
                    </div>
                    <Col xs={12} sm={12} md={12} lg={12} xl={5} xxl={5} className="map-large-section">
                        {isLoaded &&
                            <div className="map-container">
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={center}
                                    zoom={10}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}>
                                    {
                                        arraryMap && arraryMap?.length > 0 && arraryMap.map((item, index) => {
                                            if (item?.lat && item?.long) {
                                                return (
                                                    <Marker position={{ lat: item?.lat, lng: item?.long }} icon={{
                                                        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                                    <rect 
                                                                        width="38" 
                                                                        height="38" 
                                                                        fill="white" 
                                                                        stroke="#0075CA" 
                                                                        stroke-width="2" 
                                                                        rx="50" 
                                                                        ry="50" 
                                                                        x="1" 
                                                                        y="1"
                                                                    />
                                                                    <text 
                                                                        x="20" 
                                                                        y="25" 
                                                                        font-size="16" 
                                                                        fill="#0075CA" 
                                                                        text-anchor="middle"
                                                                    >${index + 1}</text>
                                                                </svg>
                                                            `)}`,
                                                        scaledSize: new window.google.maps.Size(40, 40)
                                                    }} />
                                                )
                                            }
                                        })
                                    }
                                    {/* {showMarkerAddress && selectedMarker && (
                                            <InfoWindow
                                                position={{ lat: selectedMarker.latitude, lng: selectedMarker.longitude }}
                                                onCloseClick={handleCloseInfoWindow}
                                            >
                                                <div>{addressMarker}</div>
                                            </InfoWindow>
                                        )} */}
                                </GoogleMap>
                            </div>
                        }
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default SearchHome
