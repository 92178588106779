import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import FavoriteSelect from '../FavoriteSelect/FavoriteSelect';
import './FavoriteUploadIamge.scss'
import ContinueButton from "../../../../Components/Buttons/ContinueButton/ContinueButton"
import { useDropzone } from "react-dropzone"
import uploadIamgeIcon from "../../../../assets/images/uploadImage.png"
import url from "../../../../utils/services/urls.json"
import { getMethodWithToken, postMethodWithToken } from '../../../../utils/services/apis';
import Add from '../../../../assets/images/add.svg';
import Cross from '../../../../assets/images/favoriteProperty/cross.svg';
import { toast } from 'react-toastify';
import Modal from "react-modal"
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import imageCompression from "browser-image-compression";
import { navigateLogin } from '../../../../utils/services/common';

const ContainerSection = styled.div`
font-family: Proxima Nova;
`
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "700px",
        height: "230px",
    },
};

const FavoriteUploadIamge = () => {
    const navigate = useNavigate()
    const param = useParams()
    const [uploadImageId, setUploadImageId] = useState([])
    const [file, setFile] = useState([])
    const [uploadImageData, setUploadImageData] = useState([])
    const [openImageCrop, setOpenImageCrop] = useState(false);
    const [imageCrop, setImageCrop] = useState();
    const [pickerImageIndex, setpickerImageIndex] = useState(0);
    const [openModal, setOpenModal] = useState(false)
    const [completedCrop, setCompletedCrop] = useState(null);
    const [activeStep, setActiveStep] = useState(0);

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const imageModalStyle = {
        content: {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: screenWidth > 768 ? "70vw" : "75vw",
            height: screenWidth > 768 ? "80vh" : "45vh",
            outline: "none",
            padding: "15px",
            ...(screenWidth < 768 ? {
                top: '50%',
                left: '40px',
                right: '40px',
                bottom: '40px',
                transform: 'translateY(-50%)'
            } : {})
        },
    };

    const [cropProfile, setCropProfile] = useState({
        unit: '%',
        x: 25,
        y: 25,
        width: 150,
        height: 150,
        aspect: 1,
        minWidth: 100, // minimum width in pixels
        minHeight: 100, // minimum height in pixels
    });
    const [cropFlag, setCropFlag] = useState(true)
    const imgRefProfile = useRef(null);
    const handleRemoveImage = (index, event) => {
        event.stopPropagation();
        const newUploadImageData = [...uploadImageData];
        newUploadImageData.splice(index, 1);
        uploadImageId.splice(index, 1)
        setUploadImageData(newUploadImageData);
    }


    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, } = useDropzone({
        accept: '.jpg, .png, .jpeg',
        onDrop: (acceptedFile) => {
            let formData = new FormData();
            acceptedFile.map((file, index) => {
                formData.append("file", file);

            });
            postMethodWithToken(url.profileImageUpload, formData).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    let imageListIds = [...uploadImageId];
                    let imageListData = [...uploadImageData]
                    imageListIds.push(response.data.id)
                    imageListData.push({
                        file: response.data.file
                    })
                    setUploadImageId(imageListIds)
                    setUploadImageData(imageListData)
                }
            })
        }
    });

    useEffect(() => {
        getSteps()
    }, [])
    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getSteps = async () => {
        await getMethodWithToken(url.selectlist + param.id + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                const allDetailStepFive = response?.data?.detail?.stepFive;
                const newIds = allDetailStepFive.map(value => value.id);
                const newFiles = allDetailStepFive.map(item => item);
                setUploadImageId(prevState => [...prevState, ...newIds]);
                setUploadImageData(prevState => [...prevState, ...newFiles])
                setActiveStep(response?.data?.detail?.stepsCompleted)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const handleUploadSubmit = async () => {
        let body = {
            "media": uploadImageId
        }
        await postMethodWithToken(url.uploadMedia + param.id + "/", body).then((response) => {
            if (uploadImageData.length >= 5 && (response.status === 200 || response.status === 201)) {
                navigate(`/Dashboard/FavoritePropertieList/Payment/${response.data.propertyId}`, {
                    state: {
                        paymentMethod: "paymentMethod"
                    }
                })
            }
            else if (uploadImageData.length < 5) {
                toast.error("Please add minimum 5 photos")
            }
        })
    }

    const [errorMsg, setErrorMsg] = useState('');
    const handleOpenBack = () => {
        setOpenModal(true)
    }
    const handleCloseBack = () => {
        setOpenModal(false)
    }
    const getCroppedImg = (image, crop, fileName) => {

        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        ctx.imageSmoothingQuality = "high";
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        var uri = canvas.toDataURL("image/jpeg", 1);
        return new Promise((resolve, reject) => {
            canvas.toBlob(
                function (blob) {
                    if (!blob) {
                        console.log("Canvas is empty");
                        return;
                    }
                    blob.name = fileName;
                    blob.src = uri;
                    resolve(blob);
                },
                "image/jpeg",
                1
            );
        });
    };
    // const onSelectFileProfile = (e) => {
    //     if (e.target.files && e.target.files.length > 0) {
    //         const reader = new FileReader();
    //         reader.addEventListener("load", () => setImageCrop(reader.result));
    //         reader.readAsDataURL(e.target.files[0]);
    //         setOpenImageCrop(true);
    //     } else {
    //         toast.error("ImageSizeError");
    //     }
    // };
    console.log(screenSize)
    const onSelectFileProfile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const img = new Image();
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                img.src = reader.result;
                img.onload = () => {
                    const { naturalWidth, naturalHeight } = img;
                    console.log(naturalHeight)
                    if (screenSize.width <= 575) {
                        if (naturalWidth < 100 || naturalHeight < 80) {
                            toast.error("Image dimensions must be at least 282x148 pixels.");
                        } else {
                            setImageCrop(reader.result);
                            setOpenImageCrop(true);
                        }
                    } else {
                        if (naturalWidth < 280 || naturalHeight < 148) {
                            toast.error("Image dimensions must be at least 282x148 pixels.");
                        } else {
                            setImageCrop(reader.result);
                            setOpenImageCrop(true);
                        }

                    }
                };
            });
            reader.readAsDataURL(file);
        } else {
            toast.error("ImageSizeError");
        }
    };

    const onImageLoad = (img) => {
        imgRefProfile.current = img.currentTarget
    }

    const imageCropConversionProfile = async () => {

        if (screenSize.width <= 575) {
            if (cropProfile.width < 150 || cropProfile.height < 80) {
                setErrorMsg('The cropped image dimensions should be at least 280px x 148px.');
                setCropFlag(false);
                return;
            }
        } else {
            if (cropProfile.width < 280 || cropProfile.height < 148) {
                setErrorMsg('The cropped image dimensions should be at least 280px x 148px.');
                setCropFlag(false);
                return;
            }

        }


        const croppedImage = await getCroppedImg(
            imgRefProfile.current,
            cropProfile,
        );

        setCompletedCrop(croppedImage);
        setCropFlag(true);
    };

    const handleCropChange = (newCrop) => {
        // if (newCrop.width >= cropProfile.minWidth && newCrop.height >= cropProfile.minHeight) {
        //     setCropProfile(newCrop);
        // }

        const updatedCrop = { ...newCrop };

        if (updatedCrop.width < cropProfile.minWidth) {
            updatedCrop.width = cropProfile.minWidth;
        }

        // Enforce minimum height
        if (updatedCrop.height < cropProfile.minHeight) {
            updatedCrop.height = cropProfile.minHeight;
        }

        if (screenSize.width <= 575) {
            if (updatedCrop.width < 150 || updatedCrop.height < 80) {
                setErrorMsg('The cropped image dimensions should be at least 150px x 80px.');
            } else {
                setErrorMsg(''); // Clear the error message if size is valid
            }
        } else {
            if (updatedCrop.width < 280 || updatedCrop.height < 148) {
                setErrorMsg('The cropped image dimensions should be at least 280px x 148px.');
            } else {
                setErrorMsg(''); // Clear the error message if size is valid
            }

        }

        setCropProfile(updatedCrop);
    };

    const handleProfile = async (e) => {
        const imageFile = e;
        if (imageFile.size < 10000000) {
            const options = {
                maxSizeMB: 100,
                maxWidthOrHeight: 800,
                useWebWorker: true,
            };
            const compressedFile = await imageCompression(imageFile, options);
            let formData = new FormData();
            formData.append("file", compressedFile, compressedFile.name);

            postMethodWithToken(url.profileImageUpload, formData).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    let imageListIds = [...uploadImageId];
                    let imageListData = [...uploadImageData]
                    imageListIds.push(response.data.id)
                    imageListData.push({
                        file: response.data.file
                    })
                    setUploadImageId(imageListIds)
                    setUploadImageData(imageListData)
                }
            });
        }
    }
    const handleSaveExit = () => {
        navigate(`/Dashboard/homePage`)
    }
    return (
        <>
            <ContainerSection>
                <Row style={{ margin: "0" }} >
                    {/* <div className={uploadImageData.length > 0 ? 'col-lg-3' : 'col-lg-4'}>
                        <FavoriteSelect />
                    </div> */}
                    <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                        <FavoriteSelect activeStep={activeStep} value={4} />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                        <div className="upload-container">
                            <div className="upload-heading">Add some photos of your place</div>
                            <div className="upload-description">
                                <div className="upload-description-value" >Add minimum 5 photos. You can add or remove photos after the listing get published.</div>
                            </div>
                            <div className="storeImageBlock">
                                <div className="imageUpload" {...getRootProps()}>
                                    <input {...getInputProps()} onChange={(e) => onSelectFileProfile(e)} />
                                    {isDragActive ? (
                                        <p>Drop the files here ...</p>
                                    ) : (
                                        <>
                                            <div className="uploadPhoto" style={uploadImageData.length > 0 ? { display: 'flex', flexWrap: 'wrap', gap: '10px', border: 'none' } : {}}>
                                                {uploadImageData && uploadImageData.length > 0 && uploadImageData.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div key={index} style={{ position: 'relative' }}>
                                                                <img src={item?.file} alt="" className="update-pick" />
                                                                <img src={Cross} alt="Remove" style={{ position: 'absolute', top: '5px', right: '5px', cursor: 'pointer' }} onClick={(event) => handleRemoveImage(index, event)} />
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                                {uploadImageData.length == 0 &&
                                                    <div style={{ cursor: "pointer" }} className="uploadInfo">

                                                        <div>
                                                            <div className="uploadImage"><img width={screenWidth < 768 ? '48px' : '80px'} height={screenWidth < 768 ? '48px' : '80px'} src={uploadIamgeIcon} /></div>
                                                        </div>
                                                        <div>
                                                            <div className="uploadText">Drag & drop your photo here</div>
                                                        </div>
                                                        <div>
                                                            <div className="uploadTextDes">Add minimum of 5 photos</div>
                                                        </div>
                                                        <div>
                                                            <div className="uploadTextDes" style={{ marginTop: "70px" }}>OR</div>
                                                        </div>
                                                        <div>
                                                            <div className="uploadTextDesLast">Browser photos from your device</div>
                                                        </div>
                                                    </div>}
                                                {uploadImageData.length > 0 &&
                                                    <div className="uploadInfotwo" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        <div>
                                                            <div className="uploadImagetwo"><img width={screenWidth < 768 ? '10px' : '24px'} height={screenWidth < 768 ? '10px' : '24px'} src={Add} /></div>
                                                        </div>
                                                        <div>
                                                            <div className="uploadTexttwo">Add more images</div>
                                                        </div>
                                                    </div>}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-md-flex d-none buttons" style={{ margin: "20px 0 20px 30px", alignItems: "center" }} >
                            <div>
                                <ContinueButton className="back-list" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={handleOpenBack} fontSize={"20px"} fontWeight={"400"} />
                            </div>
                            <div className="d-flex justify-content-end">
                                <div style={{ paddingRight: "50px", display: "flex", alignItems: "center" }}>
                                    <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                                <div style={{ marginRight: "50px" }}>
                                    <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => handleUploadSubmit()} widht={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                            </div>
                        </div>
                        <div className="d-md-none d-flex justify-content-between mt-3 mb-3">
                            {/* <div style={{ fontSize: "20px", cursor: "pointer", paddingRight: "70px", display: "flex", alignItems: "center" }} onClick={() => handleSaveExit()}>Save & Exit</div> */}
                            <div style={{ paddingRight: "50px", display: "flex", alignItems: "center" }} className='saveButton'>
                                <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={screenWidth < 768 ? '140px' : '200px'} fontSize={screenWidth < 768 ? '14px' : '20px'} fontWeight={"400"} />
                            </div>
                            <div className='saveButton'>
                                <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => handleUploadSubmit()} width={screenWidth < 768 ? '140px' : '200px'} fontSize={screenWidth < 768 ? '14px' : '20px'} fontWeight={"400"} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </ContainerSection>
            <Modal isOpen={openImageCrop} style={imageModalStyle} overlayClassName="myoverlay" effect="fadeInUp" onClickAway={() => setOpenImageCrop(false)}>
                <div style={imageModalStyle} overlayClassName="myoverlay">
                    <div style={{ marginTop: 20, marginBottom: 10, display: "flex", flexDirection: "row" }} className='align-md-items-center align-items-baseline justify-content-evenly justify-content-md-around'>
                        <p className='cropImageText d-flex align-items-center fw-bold mt-0 text-center'>cropImage</p>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} className=' gap-2  justify-content-md-between' >
                            <div onClick={() => setOpenImageCrop(false)} className='canelButton'>Cancel</div>

                            {completedCrop && cropFlag ? (
                                <div >
                                    <div onClick={() => { setOpenImageCrop(false); handleProfile(completedCrop); }} className=' d-flex justify-content-center  canelButton' style={{ cursor: 'pointer' }}>Save</div>
                                </div>
                            ) : (
                                <div >
                                    <div className='saveButtonModal' style={{ background: '#a3a3a3', color: 'white' }}>Save</div>
                                </div>
                            )}
                        </div>
                    </div>

                    {errorMsg && (
                        <p style={{ color: 'red', textAlign: 'center' }}>{errorMsg}</p>
                    )}
                    <ReactCrop crop={cropProfile} onChange={handleCropChange} onComplete={(c) => imageCropConversionProfile(c)}>
                        <img src={imageCrop} onLoad={onImageLoad} />
                    </ReactCrop>

                </div>
            </Modal>
            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
                <div className="d-flex justify-content-center">
                    <div className="back-heading">Are you sure you want to back?</div>
                </div>
                <div className="d-flex justify-content-center" >
                    <div style={{ marginTop: "10px" }}>All the changes you will made will be discarded</div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="d-flex" style={{ marginTop: "50px" }}>
                        <div className="stay-heading" onClick={handleCloseBack}>Stay on this page</div>
                        <div>
                            <ContinueButton text="Go back" color={'#fff'} onClick={() => navigate(-1)} width={"210px"} borderRadius={"4px"} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default FavoriteUploadIamge
