import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap'
import "./ListingManagerDetail.scss"
import DetailProfile from "../../../../assets/images/listingManager/listing-manager-user.svg"
import shareIcon from "../../../../assets/images/listingManager/share-list.svg"
import PhoneIcon from "../../../../assets/images/listingManager/call.svg"
import Mail from "../../../../assets/images/listingManager/mail.svg"
import Message from "../../../../assets/images/listingManager/sms.svg"
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Modal from "react-modal"
import closeIcon from "../../../../assets/images/listingManager/close.svg"
import { FormControl, InputAdornment, TextField, } from "@material-ui/core";
import SearchIcon from '@mui/icons-material/Search';
import ContinueButton from '../../../../Components/Buttons/ContinueButton/ContinueButton';
import Reply from "../../../../assets/images/listingManager/reply.svg"
import Bedroom from "../../../../assets/images/listingManager/bedroom.svg"
import Bathroom from "../../../../assets/images/listingManager/bathroom.svg"
import Parking from "../../../../assets/images/listingManager/parking.svg"
import PropertySize from "../../../../assets/images/listingManager/square_foot.svg"
import Clock from "../../../../assets/images/listingManager/clock.svg"
import Home from "../../../../assets/images/listingManager/home.svg"
import Furnishing from "../../../../assets/images/listingManager/furnishing.svg"
import ListingManagerTabDetail from "../../ListingManager/ListingManagerTabDetail/ListingManagerTabDetail";
import { getMethodWithToken, postMethodWithToken } from '../../../../utils/services/apis';
import url from '../../../../utils/services/urls.json'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { navigateLogin } from '../../../../utils/services/common';
import { toast } from 'react-toastify';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
// const customStyles = {
//     content: {
//         top: "50%",
//         left: "50%",
//         transform: 'translate(-50%, -50%)',
//         backgroundColor: "white",
//         border: "1px #ccc",
//         borderRadius: "4px",
//         overflow: "auto",
//         WebkitOverflowScrolling: "touch",
//         outline: "none",
//         padding: "15px",
//         width: "950px",
//         height: "770px",
//     },
// };

const CustomImage = styled.img`
background:url('${props => props.image}');
height: 182px;
width:100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;

     @media (min-width:768px) and (max-width: 1199px) {
     height: 142px;
   }
`
const ManagerListDetail = styled.div`
margin-top: 20px;
`
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    rootValue: {
        maxWidth: 400,
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        // height: 220,
        display: 'block',
        // maxWidth: 300,
        overflow: 'hidden',
        width: '100%',
    },
}));
const ListingManagerDetail = ({ mangerid, data }) => {
    const navigate = useNavigate()
    const [openModal, setOpenModal] = useState(false)
    const [searchInput, setSearchInput] = useState("")
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const [checked, setChecked] = useState(false)
    const [id, setId] = useState('')
    const [propertyListArray, setPropertyListArray] = useState([])
    const [likeIndex,setLikeIndex]=useState(true)
    const maxSteps = 5;
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
            // width: "27vw",
            // height: "680px",
            transform: screenSize.width <= 800 ? 'translate(-50%, -50%)' : 'translate(-50%, -50%)',
            width: screenSize.width <= 800
                ? "95vw"
                : screenSize.width <= 1100
                    ? "70vw"
                    : screenSize.width <= 1372
                        ? "70vw"
                        : screenSize.width <= 1450
                            ? "68vw"
                            : screenSize.width <= 1600
                                ? "64vw"
                                : screenSize.width <= 1800
                                    ? "52vw"
                                    : "50vw",
                                    height: screenSize.width <= 575
                                    ? "70vh"
                                    : screenSize.width <= 820 // Remove the exact match, use <= for better range support
                                        ? "42vh"
                                        : screenSize.width <= 1024
                                            ? "40vh"
                                            : screenSize.width <= 1100
                                                ? "70vh"
                                                : "68vh",
                                

            padding: '10px',
        },
    };

    // const handleNext = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    // const handleStepChange = (step) => {
    //     setActiveStep(step);
    // };

    const handleNext = (data, galleryIndex) => {
        console.log(data, "777")

        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === data?.property_images?.length - 1
                ? 0
                : (prevSteps[data?.propertyMaster_id] || 0) + 1
        }));
    };

    const handleBack = (data, galleryIndex) => {

        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === 0
                ? data?.property_images?.length - 1
                : (prevSteps[data?.propertyMaster_id] || 0) - 1
        }))

    };

    const handleStepChange = (data, step) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: step
        }));
    };

    const handleOpenModal = () => {
        setOpenModal(true)
        setPropertyId()
        setLikeIndex(false)
    }

    const getRandomNumberProperty = (number) => {
        return Math.floor(Math.random() * number);
    };
    const handleCloseModal = () => {
        setOpenModal(false)
        setLikeIndex(true)
    }
    const handleSearchChange = (event) => {
        setSearchInput(event.target.value)
    }
    const [managerDetail, setManagerDetail] = useState([])
    const [managerName, setManagerName] = useState('')
    const [managerEmail, setManagerEmail] = useState('')
    const [managerContactNo, setManagerContactNo] = useState('')
    const [myListing, setMyListing] = useState([])
    const [otherListing, setOtherListing] = useState([])
    const [requestSent, setRequestSent] = useState([])
    const [managerId, setManagerId] = useState('')
    const [propertyId, setPropertyId] = useState('')

    const handleManagerDetail = async () => {
        await getMethodWithToken(url.managerDetails + mangerid + '/').then((response) => {
            if (response.status == 200 || response.status == 201) {
                setManagerDetail(response?.data)
                setManagerName(response?.data?.name)
                setManagerEmail(response?.data?.email)
                setManagerContactNo(response?.data?.contact_number)
                setMyListing(response?.data?.my_listing)
                setOtherListing(response?.data?.other_list)
                setRequestSent(response?.data?.request_sent)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    const handlePropertyList = async () => {
        let apiurl
        if (searchInput) {
            apiurl = `${url.ownerPropertyList}?name=${searchInput}?address=${searchInput}`
        }
        else apiurl = url.ownerPropertyList
        await getMethodWithToken(apiurl).then((response) => {
            if (response.status == 200 || response.status == 201) {
                setPropertyListArray(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    // useEffect(() => {
    //     if (data) {
    //         setPropertyListArray(data)
    //     }
    //     // handleManagerDetail()
    // }, [data])

    useEffect(() => {
        if (mangerid) {
            handlePropertyList()
        }
    }, [searchInput])

    const handlePropertyClick = (propertyId) => {
        setPropertyId(propertyId)
    }

    const handleSendRequest = async () => {
        let body = {
            "manager": mangerid,
            "managing_property": propertyId
        }
        await postMethodWithToken(url.sendManagingRequest, body).then((response) => {
            if (response.status == 200 || response.status == 201) {
                setOpenModal(false)
                toast.success(response?.data?.message)
            } else {
                toast.error(response?.data?.message)
            }
        })
    }


    return (
        <>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="listing-tab-detail">
                    <div className="d-flex justify-content-between mt-2">
                        <div className="listing-managed-heading">Listings managed</div>
                        <div className="request-heading" onClick={handleOpenModal}>Send Managing Request</div>
                    </div>

                </Col>
                <div>
                    <ListingManagerTabDetail mangerid={mangerid} data={data} likeIndex={likeIndex} />
                </div>
            </Row>
            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay"  >
                <div className="request-modal">
                    <div className="d-flex justify-content-end" style={{ cursor: "pointer" }} onClick={() => handleCloseModal()}>
                        <img src={closeIcon} alt="" />
                    </div>
                    <div className="request-heading">Send Managing Request</div>
                    <Row className="g-2" >
                        <Col xs={6} sm={4} md={9} lg={9} xl={8} xxl={8}>

                            <FormControl fullWidth style={{ marginTop: "20px" }}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={searchInput}
                                    placeholder="Search property by address, name or code"
                                    onChange={(e) => handleSearchChange(e)}
                                    style={{ height: "50px" }}
                                    InputProps={{
                                        style: { height: "50px", padding: 0 },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{ paddingLeft: "10px", }} className='search-model' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>

                        </Col>
                        <Col xs={6} sm={4} md={3} lg={3} xl={4} xxl={4} style={{ paddingTop: "14px", paddingRight: "16px" }}>
                            <ContinueButton text={'Send Request'} color={'#fff'} disabled={!propertyId} backgroundColor={propertyId ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} width={'100%'} borderRadius={"4px"} height={"55px"} onClick={() => handleSendRequest()} />
                        </Col>
                    </Row>
                    <div className="request-modal-section">
                        {propertyListArray && propertyListArray.length > 0 && propertyListArray.map((data, index) => {
                            return (
                                <div className="request-modal-card" onClick={() => handlePropertyClick(data?.propertyMaster_id)} style={{ border: propertyId === data?.propertyMaster_id ? '2px solid #40A0E5' : '1px solid black' }}>
                                    <Row style={{ paddingBottom: "10px" }}>
                                        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} >
                                            <div >
                                                {/* <SwipeableViews
                                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                    index={activeStep}
                                                    onChangeIndex={handleStepChange}
                                                    enableMouseEvents
                                                >
                                                    {(data?.property_images).map((step, index) => (
                                                        <div key={getRandomNumberProperty(data?.property_images?.length - 1)}>
                                                            <CustomImage className={classes.img} src={data?.property_images?.[getRandomNumberProperty(data?.property_images?.length - 1)]?.media?.file} alt={step.label} />
                                                        </div>
                                                    ))}
                                                </SwipeableViews> */}
                                                <SwipeableViews
                                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                    index={activeStep[data?.propertyMaster_id] || 0}
                                                    onChangeIndex={(step) => handleStepChange(data, step)}
                                                    enableMouseEvents
                                                >
                                                    {data?.property_images.map((step, index) => {
                                                        return (
                                                            <>
                                                                <div key={index}>
                                                                    <CustomImage className={classes.img} src={step?.media?.file} alt={step.label} />
                                                                </div>

                                                            </>
                                                        )
                                                    })}
                                                </SwipeableViews>
                                                <MobileStepper
                                                    position="static"
                                                    variant="text"
                                                    nextButton={
                                                        <Button size="small" style={{ color: "white" }} onClick={() => handleNext(data, index)}>

                                                            {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                        </Button>
                                                    }
                                                    backButton={
                                                        <Button size="small" style={{ color: "white" }} onClick={() => handleBack(data, index)}>
                                                            {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                        </Button>
                                                    }
                                                />


                                            </div>
                                            {/* <div className="listing-heading" >Rental Duration : <span style={{ color: "black", fontWeight: 600, fontSize: "16px" }}>{data?.subscription_details?.masterSubscrption}</span> </div> */}
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                            <div className="listing-card-detail" >
                                                <div className="d-flex" >
                                                    <div className="listing-user-heading" >${data?.subscription_details?.price}/</div>
                                                    <div className="listing-user-month">{data?.subscription_details?.per_day ? 'day' : 'month'}</div>
                                                </div>

                                                <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px" }}>{data?.apartmentName}{data?.location?.locality}</div>
                                                <Row style={{ marginTop: "10px", borderBottom: "1px solid #B7B7B7", paddingBottom: "5px" }}>
                                                    <Col xs={6} className='d-flex'>
                                                        <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading-type">Bedroom</div>
                                                        <div className="category-value">{data?.bedroomCount}</div>
                                                    </Col>
                                                    <Col xs={6} className='d-flex'>
                                                        <div ><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading-type">Bathroom</div>
                                                        <div className="category-value">{data?.propertyType?.name === "Room" ? data?.room_sharing?.name : data?.bathroomCount}</div>
                                                    </Col>
                                                    {/* <Col md={6} lg={6} xl={6} xxl={6} className='d-flex'>
                                                        <div ><img src={Parking} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading-type">Parking</div>
                                                        <div className="category-value">{data?.parkingType?.name}</div>
                                                    </Col>
                                                    <Col md={6} lg={6} xl={6} xxl={6} className='d-flex'>
                                                        <div ><img src={PropertySize} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="category-value">{data?.propertySize} sqft</div>
                                                    </Col> */}
                                                </Row>
                                                <div>
                                                    <Row >
                                                        <Col md={12} lg={12} xl={12} xxl={12} className='d-flex d-md-block justify-content-between align-items-baseline'>
                                                            <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                <div className="categroy-heading">Available from</div>
                                                            </div>
                                                            <div className="select-value-heading">{moment(data?.subscription_details?.propertyAvailfrom?.[0]).format("YYYY-MM-DD")}</div>
                                                        </Col>
                                                        {/* <Col md={4} lg={4} xl={4} xxl={4} style={{ borderRight: "1px solid #B7B7B7" }}>
                                                            <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                <div><img src={Home} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                <div className="categroy-heading">Property Type</div>
                                                            </div>
                                                            <div className="select-value-heading">{data?.propertyType?.name}</div>
                                                        </Col>
                                                        <Col md={4} lg={4} xl={4} xxl={4}>
                                                            <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                <div><img src={Furnishing} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                <div className="categroy-heading">Furnishing</div>
                                                            </div>
                                                            <div className="select-value-heading">{data?.furnishType?.name}</div>
                                                        </Col> */}
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                            )
                        })}

                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ListingManagerDetail
