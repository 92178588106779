import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { getMethodWithToken, postMethodWithToken } from '../../../utils/services/apis';
import url from '../../../utils/services/urls.json';
import Navbar from '../../../Components/commonComponents/navbar/Navbar';
import styled from 'styled-components';
import ArrowBack from "../../../assets/images/favoriteProperty/arrow-back.svg";
import './TenantProfile.scss';
import PhoneIcon from "../../../assets/images/call.svg"
import Mail from "../../../assets/images/mail.svg"
import Message from "../../../assets/images/listingManager/sms.svg"
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Modal from "react-modal";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Bedroom from "../../../assets/images/listingManager/bedroom.svg"
import Bathroom from "../../../assets/images/listingManager/bathroom.svg"
import PropertySize from "../../../assets/images/listingManager/square_foot.svg"
import Parking from "../../../assets/images/listingManager/parking.svg"
import Clock from "../../../assets/images/listingManager/clock.svg"
import Home from "../../../assets/images/listingManager/home.svg"
import Furnishing from "../../../assets/images/listingManager/furnishing.svg"
import Reply from "../../../assets/images/listingManager/reply.svg"
import LikeIcon from "../../../assets/images/listingManager/like-property.svg"
import FilledLikeIcon from "../../../assets/images/listingManager/filledLikedImage.svg"
import mobileFavIcon from "../../../assets/images/listingManager/mobile-fav-icon.svg";
import filledMobileFav from "../../../assets/images/listingManager/filled-mobile-fav.svg"
import FlagIcon from "../../../assets/images/listingManager/flag.svg"
import ArrowForward from "../../../assets/images/listingManager/Arrow-property.svg"
import ContinueButton from "../../../Components/Buttons/ContinueButton/ContinueButton"
import close from "../../../assets/images/searchproperty/close.svg";
import back from "../../../assets/images/searchproperty/back_month.svg";
import fordward from "../../../assets/images/searchproperty/ford_month.svg";
import calender from "../../../assets/icons/calendar_month.svg"
import DatePicker from 'react-datepicker';
import FormInput from '../../../Components/Inputs/FormInput/FormInput';
import slots from '../../../assets/images/searchproperty/slots.svg';
import ArrowRight from '../../../assets/images/searchproperty/ArrowRight.svg';
import enGB from 'date-fns/locale/en-GB';
import moment from 'moment/moment';
import { navigateLogin } from '../../../utils/services/common';
import { addDays, addMonths, addYears } from 'date-fns'
import DefaultImage from "../../../assets/images/default-image.svg";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const TenantProfileContainer = styled.div`
background:#F2F2F7;
// height:100vh;
// font-family: Proxima_nova_reg;
`

const CustomImage = styled.img`
background:url('${props => props.image}');
height: 182px;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;

//    @media only screen and (max-width: 768px) {
//     height: 182px;
//    }
//     @media only screen and (max-width: 767px) {
//     height: 182px;
//     width:100%;
//    }

@media (min-width:770px) and (max-width: 1199px) {
     height: 182px;
   }
      
 @media (min-width:575px) and  (max-width:768px) {
   height: 142px;
  }

   @media (min-width:431px) and  (max-width:575px) {
   height: 320px;
  }

   @media  (min-width:400px) and  (max-width:431px) {
   height: 220px;
  }

   @media (max-width:400px) {
   height: 190px;
  }
    
`

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        // height: 220,
        display: 'block',
        // maxWidth: 300,
        overflow: 'hidden',
        width: '100%',
    },
}));

const SelectListingCard = styled.div`
font-family: Proxima_nova_reg;
// width:840px;
height: auto;
background: white;
padding: 10px;
z-index:1
box-shadow: "0px 2px 8px 0px #0000001F";
margin-bottom:25px;

 @media (max-width: 767px) {
   padding:0px
   }
`;

const TenantProfile = () => {
    const navigate = useNavigate()
    const param = useParams()
    const today = new Date()
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const [favoritePropertyList, setFavoritePropertyList] = useState([])
    const [favoritePropertyListImages, setFavoritePropertyListImages] = useState([])
    const [activeStep, setActiveStep] = useState(0);
    const [propertyDetail, setPropertyDetail] = useState({});
    const [propertyImage, setPropertyImage] = useState([]);
    const [ownerProfilePic, setOwnerProfilePic] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [ownerEmail, setOwnerEmail] = useState('');
    const [ownerCompanyName, setOwnerCompanyName] = useState('');
    const [ownerPhoneNo, setOwnerPhoneNo] = useState('');
    const [rentPropertyId, setRentPropertyId] = useState('')
    const [openRentModal, setOpenRentModal] = useState(false)
    const [selectedRentDate, setSelectedRentDate] = useState();
    const [selectedRentEndDate, setSelectedRentEndDate] = useState();
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [favorite, setFavorite] = useState(false)
    const [favPropertyId, setFavPropertyId] = useState('')
    const [rentPrice, setRentPrice] = useState("")
    const [rentalDuration, setRentalDuration] = useState("")
    // const maxSteps = favoritePropertyList?.length;

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
            // width: "27vw",
            // height: "680px",
            transform: screenSize.width <= 800 ? 'translate(-50%, -50%)' : 'translate(-50%, -50%)',
            width: screenSize.width <= 800 ? "" : screenSize.width <= 1100 ? "40vw" : "40vw",
            height: screenSize.width <= 800 ? "78vh" : screenSize.width <= 1100 ? "75vh" : screenSize.width <= 1700 ? "77vh" : "75vh",
            padding: '0px',
        },
    };

    useEffect(() => {
        handleTenantManagerDetail()
        handleFavoritePropertyList()
    }, [])


    const handleTenantManagerDetail = async () => {
        await getMethodWithToken(url.TenantManagerDetail + param.id + '/').then((response) => {
            if (response.status === 200 || response.status === 201) {
                // if (key === "property_details") {
                //     setPropertyDetail(response?.data[key])
                //     response?.data[key].map((data) => {
                //         setPropertyImage(data?.property_images)
                //     })

                // }
                // else if (key === "tenant_profile") {
                //     const tenant = response?.data[key]?.tenant
                //     setOwnerName(tenant?.name)
                //     setOwnerEmail(tenant?.email)
                //     setOwnerPhoneNo(tenant?.contact_number)
                //     setOwnerCompanyName(tenant?.companyName)
                //     setOwnerProfilePic(tenant?.profilePic)
                // }
                setPropertyDetail(response?.data?.tenant_profile)

            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const handleFavoritePropertyList = async () => {

        await getMethodWithToken(url.propertyType + "?userId=" + param.id).then((response) => {
            if (response.status === 200 || response.status === 201) {
                console.log(response, "12121")
                setFavoritePropertyList(response?.data);
                let array = [...favoritePropertyListImages]
                response?.data?.[0]?.propertyImages?.length > 0 &&
                    array.push(response?.data?.[0]?.propertyImages)
                setFavoritePropertyListImages(array)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const maxSteps = favoritePropertyListImages?.[0]?.length;

    const handleNext = (data, galleryIndex) => {
        console.log(data, "777")

        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMasterId]: prevSteps[data?.propertyMasterId] === data?.propertyImages?.length - 1
                ? 0
                : (prevSteps[data?.propertyMasterId] || 0) + 1
        }));
    };

    const handleBack = (data, galleryIndex) => {

        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMasterId]: prevSteps[data?.propertyMasterId] === 0
                ? data?.propertyImages?.length - 1
                : (prevSteps[data?.propertyMasterId] || 0) - 1
        }))

    };

    const handleStepChange = (data, step) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMasterId]: step
        }));
    };

    const getRandomNumberProperty = (number) => {
        return Math.floor(Math.random() * number);
    };


    const handleMainCalenderDate = (date) => {
        if (!selectedRentDate) {
            setSelectedRentDate(date);
            if (rentalDuration === "Long term") {
                const newEndDate = new Date(date);
                newEndDate.setFullYear(newEndDate.getFullYear() + 1);
                setSelectedRentEndDate(newEndDate);
            }
        } else if (!selectedRentEndDate && date >= selectedRentEndDate) {
            setSelectedRentEndDate(date);
        } else {
            if (selectedRentDate && selectedRentEndDate) {
                setSelectedRentEndDate("")
                setSelectedRentDate("")
            }
            setSelectedRentDate(date);
            if (rentalDuration === "Long term") {
                const newEndDate = new Date(date);
                newEndDate.setFullYear(newEndDate.getFullYear() + 1);
                setSelectedRentEndDate(newEndDate);
            }
        }
    };

    const handleRentProperty = async (id, allValues) => {
        let api = `${url.GetRentProperty}?rentProperty_id=${id}`
        await getMethodWithToken(api).then((response) => {
            if (response.status === 200 || response.status === 201) {
                if (response?.propertyMaster === id) {
                    setSelectedRentDate(new Date(response?.data?.start_date))
                    setSelectedRentEndDate(new Date(response?.data?.end_date))
                }
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
        setRentPropertyId(id)
        setOpenRentModal(true)
        setRentPrice((Number(allValues?.price) + Number(allValues?.price) * 5 / 100) + Number(allValues?.deposit_amount))
        setRentalDuration(allValues?.rentalDuration)

    }

    const dates = addDays(selectedRentDate, 30);
    const midDate = addMonths(selectedRentDate, 10)
    const longDate = addYears(selectedRentDate, 1)

    const handleCloseClick = () => {
        setOpenRentModal(false)
    }
    const handleCloseRentClick = async (start_date, end_date) => {
        let body = {
            property_id: +rentPropertyId,
            start_date: start_date,
            end_date: end_date,
            is_rent: true
        }
        await postMethodWithToken(url.RentPropertyDates, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                navigate(`/Dashboard/tenant/PaymentDetail/${rentPropertyId}/tenantDetail`, {
                    state: {
                        rentPrice: rentPrice
                    }
                })
                setOpenRentModal(false)
            }
        })

    }

    const handleChatWithManager = async (propertyMasterId, bedroom, bathroom, propertyType, price, address, per_month, propertySize, chatId) => {
        let perMonth = per_month === true ? "per_month" : "per_day";
        const receiverPropertyDetail = {
            Id: chatId,
            PropertyMasterId: propertyMasterId,
            bedroom: bedroom,
            bathroom: bathroom,
            propertyType: propertyType,
            price: price,
            address: address,
            per_month: perMonth,
            propertySize: propertySize

        }
        let body = {
            receiver: chatId,
            propertyMaster: propertyMasterId,
            content: JSON.stringify(receiverPropertyDetail)
        }

        await postMethodWithToken(url.sendMessage, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                localStorage.setItem('receiverPropertyDetail', response?.data?.content)
                navigate("/Dashboard/Chats", {
                    state: {
                        receiverId: response?.data?.receiver,
                        propertyMasterId: response?.data?.propertyMaster
                    }
                })
            }
        })
    }
    const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {

        const monthYear = date.toLocaleDateString(undefined, {
            month: 'long',
            year: 'numeric',
        });

        const days = ['Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat', 'Sun'];

        return (
            <div className="custom-header">
                <div className='date_month'>
                    <p className='monthyear'>{monthYear}</p>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <img src={back} onClick={decreaseMonth} alt='back' style={{ cursor: "pointer" }} />
                        <img src={fordward} onClick={increaseMonth} alt='fordward' style={{ cursor: "pointer" }} />
                    </div>

                </div>
                <div style={{ fontSize: '20px', justifyContent: 'space-evenly', display: 'flex', fontFamily: 'Proxima_nove_reg', paddingBottom: '20px' }}>
                    {days.map((day, index) => (
                        <span key={index}>{day}</span>
                    ))}
                </div>
            </div>
        );
    };

    const handleAddFavouriteProperty = async (propertyId) => {
        setFavorite(!favorite)
        setFavPropertyId(propertyId)
        let body = {
            "property_id": propertyId,
            "favourite": !favorite
        }
        await postMethodWithToken(url.addFavoriteProperty, body).then((response) => {
            if (response.status === 200 || response.status === 201) {

            }
        })
    }


    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }

    const handleFavoritePropertyDetail = (id) => {
        navigate(`/Dashboard/SearchList/SearchDetail/${id}`)
    }

    return (
        <>
            <TenantProfileContainer>
                <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
                    <Navbar />
                </div>
                <div className='tenant-profile-section'>
                    <Row className='profile-icon'>
                        <Col md={5} lg={5} xl={5} xxl={5}>
                            <div className="d-none align-items-center d-md-flex" style={{ gap: '10px' }}>
                                <div className="arrow-box-profile" onClick={() => navigate(-1)}>
                                    <img src={ArrowBack} alt="" />
                                </div>
                                <div className='profile'>Profile</div>
                            </div>

                        </Col>
                    </Row>
                    <Row className='profile-mobile'>
                        <Col md={12} lg={12} xl={5} xxl={5} className="profileDetail">
                            <div className='d-flex d-md-none' style={{ textAlign: 'left', paddingLeft: '10px' }} onClick={() => navigate(-1)}>
                                <ArrowBackOutlinedIcon />
                            </div>

                            <div>
                                <img src={propertyDetail?.tenant?.profilePic ? propertyDetail?.tenant?.profilePic : DefaultImage} alt='' className='tenantProfilePic'></img>
                            </div>
                            <div className='tenantName'>{propertyDetail?.tenant?.name}</div>
                            <div className="d-md-flex justify-content-center d-block" style={{ marginTop: "13px", marginBottom: '13px' }}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div>
                                        <img src={PhoneIcon} alt="ownerPhoneNo" />
                                    </div>

                                    <div className='tenantCompanyName' style={{ paddingLeft: '10px', color: '#919CA5' }}>{propertyDetail?.tenant?.contact_number}</div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center' >
                                    <div style={{ marginLeft: "10px" }}>
                                        <img src={Mail} alt="ownerEmail" />
                                    </div>
                                    <div className='tenantCompanyName' style={{ paddingLeft: '10px', color: '#919CA5' }}>{propertyDetail?.tenant?.email}</div>
                                </div>
                            </div>

                        </Col>
                        <Col md={12} lg={12} xl={6} xxl={6} style={{ padding: screenSize.width <= 575 && '0' }}>
                            <div className='tenet-profile-heading'>Listings</div>
                            <div className="tenent-profile-list-container">
                                {favoritePropertyList?.length > 0 ?
                                    favoritePropertyList && favoritePropertyList.map((data, index) => {
                                        return (
                                            <SelectListingCard>
                                                <Row>
                                                    <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className='mobile-fav-pos'>
                                                        <div>
                                                            <div className='d-block d-md-none mobile-favIcon'>
                                                                <img src={(favorite && (data?.propertyMasterId === favPropertyId)) || data?.is_favourite ? filledMobileFav : mobileFavIcon} alt="" style={{ width: "25px", height: "25px", cursor: "pointer" }} onClick={() => handleAddFavouriteProperty(data?.propertyMasterId)} />
                                                            </div>

                                                            <SwipeableViews
                                                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                                index={activeStep[data?.propertyMasterId] || 0}
                                                                onChangeIndex={(step) => handleStepChange(data, step)}
                                                                enableMouseEvents
                                                            >
                                                                {data?.propertyImages?.length > 0 && data?.propertyImages.map((step, index) => {
                                                                    return (
                                                                        <>
                                                                            <div key={index}>
                                                                                <CustomImage className={classes.img} src={step?.file_url} alt={step.label} />
                                                                            </div>

                                                                        </>
                                                                    )
                                                                })}
                                                            </SwipeableViews>
                                                            <MobileStepper
                                                                position="static"
                                                                variant="text"
                                                                nextButton={
                                                                    <Button size="small" style={{ color: "white" }} onClick={() => handleNext(data, index)}>

                                                                        {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                                    </Button>
                                                                }
                                                                backButton={
                                                                    <Button size="small" style={{ color: "white" }} onClick={() => handleBack(data, index)}>
                                                                        {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                                    </Button>
                                                                }
                                                            />

                                                        </div>

                                                    </Col>
                                                    <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                                        <div className="listing-card-detail" >
                                                            <div className="d-flex justify-content-between section-profile">
                                                                <div className="d-flex" >
                                                                    <div>
                                                                        <img src={data?.profile ? data?.profile : DefaultImage} alt="" className="profile-value" style={{ width: "50px", height: "50px", borderRadius: "50%", cursor: "pointer" }} />
                                                                    </div>
                                                                    <div >
                                                                        <div className="d-none d-lg-flex" style={{ paddingLeft: "7px" }}>
                                                                            <div className="user-heading">{data?.name}</div>
                                                                            <div className="user-dot">.</div>
                                                                            <div className="property-owner">Property Owner</div>
                                                                        </div>
                                                                        <div className="d-lg-none" style={{ paddingLeft: "7px" }}>
                                                                            <div className="user-heading">{data?.name}</div>
                                                                            <div className="property-owner">Property Owner</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="d-flex d-sm-none justify-content-end" style={{ cursor: "pointer" }}>
                                                                        <div className="view-heading" onClick={() => handleFavoritePropertyDetail(data?.propertyMasterId)}>View Details</div>
                                                                        <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} onClick={() => handleFavoritePropertyDetail(data?.propertyMasterId)} /></div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-none d-sm-flex">
                                                                    <div className="d-none d-sm-flex">
                                                                        <div><img src={(favorite && (data?.propertyMasterId === favPropertyId)) || data?.is_favourite ? FilledLikeIcon : LikeIcon} alt="" style={{ width: "20px", height: "20px", cursor: "pointer" }} nClick={() => handleAddFavouriteProperty(data?.propertyMasterId)} /></div>
                                                                        <div><img src={FlagIcon} alt="" style={{ width: "20px", height: "20px", marginLeft: "10px" }} /></div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px" }}>{data?.apartmentName} {data?.locality}</div>

                                                            <Row style={{ marginTop: "10px", borderBottom: "1px solid #B7B7B7", paddingBottom: "5px" }}>
                                                                <Col xs={5} sm={5} className='d-flex'>
                                                                    <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                    <div className="categroy-heading d-flex align-items-center">Bedroom</div>
                                                                    <div className="category-value">{data?.bathroomCount}</div>
                                                                </Col>
                                                                <Col xs={7} sm={7} className='d-flex'>
                                                                    <div><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                    <div className="categroy-heading d-flex align-items-center">Bathroom</div>
                                                                    <div className="category-value" style={{ textOverflow: 'ellipsis' }}>{data?.propertyType === "Room" ? data?.room_sharing : data?.bedroomCount}</div>
                                                                </Col>
                                                            </Row>
                                                            <div>
                                                                <Row>
                                                                    <Col md={12} lg={12} xl={12} xxl={12}>
                                                                        <div className="d-none d-sm-inline">
                                                                            <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                                <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                                <div className="categroy-heading d-flex align-items-center">Available from</div>
                                                                            </div>
                                                                            <div className="select-value-heading"> {moment(data?.propertyAvailfrom).format("YYYY-MM-DD")}</div>
                                                                        </div>

                                                                        {/* <div className="d-flex d-sm-none justify-content-between">
                                                                            <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                                <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                                <div className="categroy-heading d-flex align-items-center">Available from</div>
                                                                            </div>
                                                                            <div className="select-value-heading"> {moment(data?.propertyAvailfrom).format("YYYY-MM-DD")}</div>
                                                                        </div> */}
                                                                    </Col>
                                                                    <Col xs={5} sm={5} className="d-flex d-sm-none">
                                                                        <div className="d-flex" style={{ marginTop: "10px" }} >
                                                                            <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                            <div className="categroy-heading d-flex align-items-center">Available from</div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={7} sm={7} className="d-flex d-sm-none">
                                                                        <div className="select-value-heading"> {data?.propertyAvailfrom && moment(data?.propertyAvailfrom).format("YYYY-MM-DD")}</div>
                                                                    </Col>

                                                                </Row>

                                                                <Row className="g-1 custom-margin">
                                                                    <Col sm={5} md={5} lg={4} xl={4} xxl={4} className='d-none d-md-flex'>
                                                                        <ContinueButton text={'Rent Property'} color={'#fff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={'100%'} borderRadius={"4px"} fontSize={"12px"} fontWeight={500} onClick={() => handleRentProperty(data?.propertyMasterId, data, data)} disabled={data?.is_rent} />
                                                                    </Col>
                                                                    <Col sm={5} md={5} lg={4} xl={4} xxl={4} className='d-none d-md-flex'>
                                                                        <ContinueButton text={'Chat with Manager'} color={'#2F86D1'} backgroundColor={'#fff'} border={'2px solid #2F86D1'} borderRadius={"4px"} fontSize={"12px"} fontWeight={500} width={"100%"} onClick={() => handleChatWithManager(data?.propertyMasterId, data?.bedroomCount, data?.bathroomCount, data?.propertyType, data?.price, data?.locality, data?.per_month, data?.propertySize, data?.id)} />
                                                                    </Col>
                                                                    <Col sm={2} md={2} lg={4} xl={4} xxl={4} class="d-flex align-items-center">
                                                                        <div className="d-none d-sm-flex align-items-center justify-content-end justify-content-lg-start  mt-2" style={{ cursor: "pointer", marginLeft: "15px" }}>
                                                                            <div className="view-heading" onClick={() => handleFavoritePropertyDetail(data?.propertyMasterId)}>View Details</div>
                                                                            <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} onClick={() => handleFavoritePropertyDetail(data?.propertyMasterId)} /></div>
                                                                        </div>

                                                                    </Col>
                                                                </Row>

                                                            </div>
                                                            <Modal style={customStyles} isOpen={openRentModal} overlayClassName="myoverlay" >
                                                                <div className="modal-container" >
                                                                    <div style={{ borderBottom: " 1px solid #EAEAEA", marginBottom: "10px" }} >
                                                                        <div className="modal-main-heading  calender_header" > <p style={{ fontSize: '24px', fontWeight: '600', fontFamily: 'Proxima_nova_reg', marginBottom: "0px" }}>Select date range</p>
                                                                            <img src={close} onClick={handleCloseClick} style={{ cursor: 'pointer' }} alt='close' /></div>
                                                                        <div style={{ display: "flex" }}>
                                                                            <div className='inputField_calender'>
                                                                                <FormInput
                                                                                    type='text' name={'Start Date'} id={'name'}
                                                                                    value={selectedRentDate ? selectedRentDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }) : ''}
                                                                                    readOnly />
                                                                            </div>
                                                                            <img src={ArrowRight} alt='right' />
                                                                            <div className='inputField_calender' style={{ position: "relative" }}>
                                                                                <FormInput
                                                                                    type='text' name={'End Date'} id={'name'}
                                                                                    value={selectedRentEndDate ? selectedRentEndDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }) : ''}
                                                                                    readOnly={false} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='main_calender'>
                                                                        <DatePicker selected={selectedRentDate || selectedRentEndDate} onChange={handleMainCalenderDate} inline dateFormat="MMMM yyyy"
                                                                            dayClassName={(date) => {
                                                                                let classes = '';
                                                                                if (selectedRentDate && selectedRentEndDate) {
                                                                                    if (date.getTime() === new Date(selectedRentDate).getTime()) {
                                                                                        classes += 'start-date';
                                                                                    } else if (date.getTime() === new Date(selectedRentEndDate).getTime()) {
                                                                                        classes += 'end-date';
                                                                                    } else if (date > new Date(selectedRentDate) && date < new Date(selectedRentEndDate)) {
                                                                                        classes += 'range-selected';
                                                                                    }
                                                                                }
                                                                                return classes;
                                                                            }}
                                                                            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                                                <CustomHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />)}
                                                                            locale={enGB}
                                                                            formatWeekDayShort={(locale, dayOfWeek) => {
                                                                                const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
                                                                                return days[dayOfWeek];
                                                                            }}
                                                                            minDate={today}
                                                                            maxDate={rentalDuration === "short term" ? dates : rentalDuration === "mid term" ? midDate : longDate}
                                                                        />
                                                                    </div>
                                                                    <div className="d-flex" style={{ justifyContent: "space-between", padding: "20px 32px 20px 32px ", borderTop: "1px solid #EAEAEA", alignItems: "baseline" }}>
                                                                        <div className='calender_footer'>
                                                                            <img src={slots} alt='Like' />
                                                                            <p className='footer_para'>Available Slots</p>
                                                                        </div>
                                                                        <div>
                                                                            <button className={selectedRentEndDate ? 'continue_button' : 'Disablecontine'} onClick={() => handleCloseRentClick(selectedRentDate, selectedRentEndDate)}>Continue to checkout</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Modal>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </SelectListingCard>
                                            // </div>

                                        )
                                    }


                                    )
                                    :
                                    <div className="empty-tenant-profile">No List Found</div>
                                }

                            </div>
                        </Col>
                    </Row>
                </div>

            </TenantProfileContainer>
        </>
    )
}
export default TenantProfile