import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap'
import Navbar from '../../../Components/commonComponents/navbar/Navbar';
import FormControl from '@mui/material/FormControl';
import { Input, MenuItem, Select, InputLabel, TextField } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ContinueButton from "../../../Components/Buttons/ContinueButton/ContinueButton"
import PropertyImage from "../../../assets/images/landingPage/property-img.svg"
import Like from "../../../assets/images/landingPage/like-property.svg"
import FilledLikeIcon from "../../../assets/images/listingManager/filledLikedImage.svg"
import Bedroom from "../../../assets/images/favoriteProperty/bedroom.svg"
import Bathroom from "../../../assets/images/favoriteProperty/bathroom.svg"
import Square from "../../../assets/images/listingManager/square-landing.svg"
import Dresser from "../../../assets/images/listingManager/furnishing.svg"
import ArrowForward from "../../../assets/images/landingPage/Arrow-property.svg"
import close from "../../../assets/images/searchproperty/close.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HeadingSection.scss"
import FooterSection from '../FooterSection/FooterSection';
import { getMethodWithToken, postMethodWithToken } from '../../../utils/services/apis';
import url from "../../../utils/services/urls.json";
import Geosuggest from "react-geosuggest";
import { useLoadScript } from "@react-google-maps/api";
import back from "../../../assets/images/searchproperty/back_month.svg";
import fordward from "../../../assets/images/searchproperty/ford_month.svg";
import calender from "../../../assets/icons/calendar_month.svg"
import DatePicker from 'react-datepicker';
import Modal from "react-modal";
import FormInput from '../../../Components/Inputs/FormInput/FormInput';
import slots from '../../../assets/images/searchproperty/slots.svg';
import ArrowRight from '../../../assets/images/searchproperty/ArrowRight.svg';
import enGB from 'date-fns/locale/en-GB';
import SingleFamilyHomes from '../../../assets/images/SingleFamilyHomes.png'
import Roommates from '../../../assets/images/Roommates.png'
import Townhouse from '../../../assets/images/Townhouse.png'
import Condominiums from '../../../assets/images/Condominiums.png'
import Washington from '../../../assets/images/Washington.png'
import NewYorkCity from '../../../assets/images/NewYork.png'
import Miami from '../../../assets/images/Miami.png'
import Houston from '../../../assets/images/Houston.png'
import SanDiego from '../../../assets/images/SanDiego.png'
import LosAngeles from '../../../assets/images/LosAngeles.png'
import ShortTermRentals from '../../../assets/images/ShortTermRentals.png'
import moment from 'moment';
import { navigateLogin } from '../../../utils/services/common';
import styled from 'styled-components';
import { useMediaQuery, useTheme } from '@mui/material';
// import landingIpad from "../../../assets/images/landing-image-ipaid.svg"
// import landingIpad from "../../../assets/images/anding-page-mobile.svg"



const CustomImage = styled.img`
background:url('${props => props.image}');
height:234px;
width:100%;
background-size: cover;
background-position: center center;
background-repeat: no-repeat;
 
`


const ariaLabel = { 'aria-label': 'description' };
const HeadingSection = () => {
    const navigate = useNavigate()
    const today = new Date()
    const [termName, setTermName] = useState('')
    // const [termId, setTermId] = useState('')
    const [apartment, setApartment] = useState('')
    const [apartmentType, setApartmentType] = useState([])
    const [apartmentid, setApartmentid] = useState('')
    const [bedroom, setBedroom] = useState('')
    const [bathroom, setBathroom] = useState('')
    const [zipcode, setZipcode] = useState("")
    const [stateCode, setStateCode] = useState("")
    const [firstprice, setFirstPrice] = useState("")
    const [lastprice, setLastPrice] = useState("")
    const [recentlyArray, setRecentlyArray] = useState([])
    const [openRentModal, setOpenRentModal] = useState(false)
    const [selectedRentDate, setSelectedRentDate] = useState('');
    const [selectedRentEndDate, setSelectedRentEndDate] = useState('');
    const [topStyle, setTopStyle] = useState({});
    const [featureCollection, setFeatureCollection] = useState([])
    const [timePeriod, setTimePeriod] = useState('')
    const [timePeriodValue, setTimePeriodValue] = useState('')
    const [perMonth, setPerMonth] = useState('')
    const [perDay, setPerDay] = useState('')
    const [shortTerm, setShortTerm] = useState('')
    const [midTerm, setMidTerm] = useState('')
    const [longTerm, setLongTerm] = useState('')
    const [favPropertyId, setFavPropertyId] = useState("")
    const [favorite, setFavorite] = useState(false)
    const [subscriptionType, setSubscriptionType] = useState([])
    const subscriptionDetail = localStorage.getItem("subscriptionData")
    const SubscriptionValue = subscriptionDetail !== "undefined" && JSON.parse(subscriptionDetail)
    const [arrayPropertyId, setArrayPropertyId] = useState([])

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const theme = useTheme();
  
  // Detect small screen size
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const customStyles = {
        content: {
            top: screenSize.width <= 800 ? "60%":'50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
            // width: "27vw",
            // height: "680px",
            transform: screenSize.width <= 800 ? 'translate(-50%, -50%)' : 'translate(-42%, -50%)',
            width: screenSize.width <= 800 ? "90vw" : screenSize.width <= 1100 ? "" : "40vw",
            height: screenSize.width <= 820 ? "65vh" : screenSize.width <= 1100 ? "75vh" : screenSize.width <= 1700 ? "77vh" : "75vh",
            padding: '0px',
        },
    };

    const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
        const monthYear = date.toLocaleDateString(undefined, {
            month: 'long',
            year: 'numeric',
        });

        const days = ['Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat', 'Sun'];

        return (
            <div className="custom-header">
                <div className='date_month'>
                    <p className='monthyear'>{monthYear}</p>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <img src={back} onClick={decreaseMonth} alt='back' style={{ cursor: "pointer" }} />
                        <img src={fordward} onClick={increaseMonth} alt='fordward' style={{ cursor: "pointer" }} />
                    </div>

                </div>
                <div style={{ fontSize: '20px', justifyContent: 'space-evenly', display: 'flex', fontFamily: 'Proxima_nove_reg', paddingBottom: '20px' }}>
                    {days.map((day, index) => (
                        <span key={index}>{day}</span>
                    ))}
                </div>
            </div>
        );
    };

    const libraries = ["places"];
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyBBNW2PnUIVek7ezyuNFTh1xn6BAj90x7s",  
        libraries,
    });
    const onSuggestSelect = (suggest) => {
        if (suggest) {
            const components = suggest.gmaps.address_components;
            for (let key of components) {
                if (key.types[0] === "administrative_area_level_1") {
                    setStateCode(key?.long_name)
                }
            }
        }
    }

    const onSuggestSelectCity = (suggest) => {
        if (suggest) {
            const components = suggest.gmaps.address_components;
            let postCodeValue = '';
            for (let key of components) {
                if (key.types[0] === "locality") {
                    postCodeValue = key?.long_name
                    setZipcode(key?.long_name)
                }
            }
        }
    }

    console.log(stateCode, "4545")
    console.log(zipcode, "12121")

    const handleFirstPriceChange = (event) => {
        setFirstPrice(event.target.value)
    }
    const handleLastPriceChange = (event) => {
        setLastPrice(event.target.value)
    }

    // const handleShortChange = (event, key) => {
    //     setTermName(event?.target?.value)
    //     setTermId(+key.key.substring(2))

    // };
    const handleMainCalenderDate = (date) => {
        // if (!selectedRentDate) {
        //     setSelectedRentDate(date);
        // } else if (!selectedRentEndDate && date > selectedRentEndDate) {
        //     setSelectedRentEndDate(date);
        // } else {
        //     if (selectedRentDate && selectedRentEndDate) {
        //         setSelectedRentEndDate("")
        //         setSelectedRentDate("")
        //     }
        //     setSelectedRentDate(date);
        // }
        if (!selectedRentDate) {
            setSelectedRentDate(date);

        } else if (!selectedRentEndDate && date >= selectedRentDate) {
            setSelectedRentEndDate(date);
        } else {
            if (selectedRentDate && selectedRentEndDate) {
                setSelectedRentEndDate("")
                setSelectedRentDate("")
            }
            setSelectedRentDate(date);
            // if (rentalDuration === "Long term") {
            //     const newEndDate = new Date(date);
            //     newEndDate.setFullYear(newEndDate.getFullYear() + 1);
            //     setSelectedRentEndDate(newEndDate);
            // }
        }
    };
    const handleApartmentChange = (event) => {
        setApartment(event?.target?.value);
        setApartmentid(event?.target?.value?.id)
        setTopStyle({ top: event.target.value ? '10px' : '-16px' });

    };
    const handleTimePeriod = (event) => {
        setTimePeriod(event?.target?.value)
        if (event?.target?.value === "Short Term (30 days or less)") {
            setShortTerm(true)
            Boolean(shortTerm)
        } else if (event?.target?.value === "Mid Term (12 months or less)") {
            setMidTerm(true)
            Boolean(midTerm)
        } else if (event?.target?.value === "Long Term (12 months or more)") {
            setLongTerm(true)
            Boolean(longTerm)
        }
        else {
            setShortTerm("")
            setLongTerm("")
            setMidTerm("")
        }

        setTopStyle({ top: event.target.value ? '16px' : '-16px' });
    }

    const handleBedroomChange = (event) => {
        const value = event.target.value;
        if (value <= 10) {
            setBedroom(value);
        }
    };
    const handleBathroomChange = (event) => {
        const value = event.target.value;
        if (value <= 10) {
            setBathroom(value);
        }
    }
    const handleInput = (e) => {
        let inputValue = parseInt(e.target.value, 10);
        if (isNaN(inputValue)) {
            inputValue = 0;
        }
        if (inputValue <= 5) {
            setBathroom(inputValue);
        }
    }
    const handleRentProperty = () => {
        setOpenRentModal(true)
    }
    const handleCloseRentClick = () => {
        setOpenRentModal(false)
    }
    var settings = {
        infinite: false,
        speed: 500,
        slidesToScroll: 4,
        slidesToShow: 4,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1629,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 1230,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 880,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const handlSearchChange = () => {
        let startDate;
        let endDate;

        startDate = (selectedRentDate) ? moment(selectedRentDate).format("YYYY-MM-DD") : ''
        endDate = selectedRentEndDate ? moment(selectedRentEndDate).format("YYYY-MM-DD") : ''
        navigate("/Dashboard/SearchList", {
            state: {
                data: apartmentid,
                dataone: bedroom,
                datatwo: bathroom,
                // termId: termId,
                // zip_code: zipcode,
                startDate: startDate,
                endDate: endDate,
                minPrice: firstprice,
                maxPrice: lastprice,
                per_month: '',
                per_day: '',
                short_term: (shortTerm),
                long_term: (longTerm),
                mid_term: (midTerm),
                city: zipcode,
                state: stateCode
            }
        })

    }
    const handleListChange = () => {
        navigate("/Dashboard/FavoritePropertieList")
    }
    const handleApartment = async () => {
        await getMethodWithToken(url.selectList,).then((response) => {
            if (response.status == 200 || response.status == 201) {
                response?.data.length > 0 && response?.data.map(function (item) {
                    if (item?.[1] == "property_type") {
                        setApartmentType(item?.[0])
                    }
                    if (item?.[1] == "rent_duration") {
                        setSubscriptionType(item?.[0])
                    }
                })
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/L-5pxogin")
            }
        }
        )
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // useEffect(() => {
    //     handleRecentlyAddedProperty()
    //     screenScrollTop()
    // }, [])



    const handleRecentlyAddedProperty = async () => {
        await getMethodWithToken(url.recentlyAddedProperty).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setRecentlyArray(response?.data)
                response?.data?.map((item, index) => (
                    setFavorite(item?.is_favourite)
                ))
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    const handleViewDetail = (propertyMaster_id) => {
        navigate(`/Dashboard/featureList/${propertyMaster_id}/recent_deatil`)
    }
    const handleFeaturePropertyCollection = async () => {
        await getMethodWithToken(url.featurePropertyDropdown).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setFeatureCollection(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }

        })
    }

    useEffect(() => {
        handleApartment()
        // handleRecentlyAddedProperty()
        handleRecentlyAddedProperty()
        handleFeaturePropertyCollection()
        scrollToTop()
    }, [])

    const featureCollectionImages = {
        town_houses: Townhouse,
        roommates: Roommates,
        single_family_home: SingleFamilyHomes,
        short_term_rental: ShortTermRentals,
    };
    const handleFilterFeatureCollection = (slug) => {
        // localStorage.setItem("slug",slug)
        navigate("/Dashboard/SearchList", {
            state: {
                collectionSlug: slug,
                "featureDetail": "featureDetail"
            }
        })
    }
    const aboutRef = useRef(null);
    const scrollToSection = (sectionId) => {
        if (sectionId === 'about' && aboutRef.current) {
            aboutRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    }

    const handleBlur = () => {
        setIsFocused(false);
    }
    const IsJSONString = (str) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    };

    const handleAddFavouriteProperty = async (propertyId) => {
        setFavorite(!favorite)
        setFavPropertyId(propertyId)
        let array = [...arrayPropertyId]
        let favoriteClicked
        if (array.includes(propertyId)) {
            let index = array.indexOf(propertyId)
            array.splice(index, 1)
            favoriteClicked = false
        } else {
            array.push(propertyId)
            favoriteClicked = true
        }
        setArrayPropertyId(array)
        let body = {
            "property_id": propertyId,
            "favourite": favoriteClicked
        }
        await postMethodWithToken(url.addFavoriteProperty, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                handleRecentlyAddedProperty()
            }
        })
    }
    const handleGetOpenModal = (data) => {

    }
    useEffect(() => {
        if (openRentModal) {
          document.body.style.overflowY = 'hidden'; 
        } else {
          document.body.style.overflowY = 'auto';  
        }
        return () => {
          document.body.style.overflowY = 'auto';
        };
      }, [openRentModal]);
    return (
        <div style={{ overflowX: 'hidden' }}>
            <div style={{ background: '#f2f2f7', height: 'fit-content' }} >
                <Navbar scrollToSection={scrollToSection} getOpenModal={(val) => handleGetOpenModal(val)} />
            </div>
            <div className="landing-image">
                <Row className="m-0 p-0">
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="img-title">I am interested in a</div>
                        <div className='mobile-property-type'>
                            <FormControl sx={{ m: 1, minWidth: 152 }}>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={apartment}
                                    variant="standard"
                                    onChange={(e) => handleApartmentChange(e)}
                                    sx={{
                                        '.MuiSelect-select': {
                                            paddingBottom: '0px',
                                        },
                                        '.MuiOutlinedInput-notchedOutline': {
                                            top: '0px',
                                        },
                                    }}
                                >
                                    {apartmentType && apartmentType.length > 0 && apartmentType.map((item, index) => {
                                        return (
                                            <MenuItem key={item} value={item}>{item.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="img-title " style={{ marginLeft: "10px" }}>in</div>
                        {isLoaded &&
                            <div className='state-code'>
                                <Geosuggest
                                    onSuggestSelect={onSuggestSelect}
                                    placeholder={isFocused ? "" : "State"}
                                    country={["US", "IN"]}
                                    types={['(regions)']}
                                    style={{ zIndex: "2" }}
                                    skipSuggest={function (suggest) {
                                        return !(suggest?.types?.includes('administrative_area_level_1'));
                                    }}
                                    getSuggestLabel={function (suggest) {
                                        return (suggest?.structured_formatting?.main_text);
                                    }}
                                >
                                    <TextField className='stateField'
                                        label="State"
                                        variant="standard"      
                                    />
                                </Geosuggest>
                            </div>}
                        <div className="img-title in-text" style={{ marginLeft: "10px" }}>in</div>
                        {isLoaded &&
                            <div className='zipCode'>
                                <Geosuggest
                                    onSuggestSelect={onSuggestSelectCity}
                                    placeholder={isFocused ? "" : "City"}
                                    country={["US", "IN"]}
                                    types={['(cities)']}
                                    style={{ zIndex: "2" }}
                                    skipSuggest={function (suggest) {
                                        return !(suggest.types);
                                    }}
                                    getSuggestLabel={function (suggest) {
                                        return (suggest?.structured_formatting?.main_text);
                                    }}
                                >
                                    <TextField
                                        label="City"
                                        variant="standard"
                                    />
                                </Geosuggest>
                            </div>}
                        <div className="img-title for-text" style={{ marginLeft: "10px" }}>for</div>
                        <div className="short-drop-down">
                            <FormControl className='formControl' variant="standard" sx={{ minWidth: "180px", marginTop: "8px" }}>
                                <Select
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    value={timePeriod}
                                    onChange={handleTimePeriod}
                                    sx={{
                                        '.MuiSelect-select': {
                                            paddingBottom: '0px',
                                        },
                                        '.MuiOutlinedInput-notchedOutline': {
                                            top: '0px',
                                        },
                                    }}
                                >
                                    {subscriptionType && subscriptionType.length > 0 && subscriptionType.map((data, key) => {
                                        return (
                                            <MenuItem value={data?.name}>{data?.name}</MenuItem>
                                        )
                                    })
                                    }

                                </Select>
                            </FormControl>
                        </div>
                        <div className="img-title from-text" style={{ marginLeft: "10px" }}>from</div>
                        <div className='calender-start' onClick={handleRentProperty}>
                            <FormControl className='formControl' variant="standard" >
                                <TextField id="standard-basic" label={!selectedRentDate && "mm/dd/yyyy"} variant="standard" value={selectedRentDate ? selectedRentDate.toLocaleDateString() : ''} sx={{
                                    width: '176px',
                                    '& input': {
                                        padding: 0,
                                    },
                                    '& .MuiInputLabel-root': {
                                        margin: 0,
                                    },
                                }}
                                />
                            </FormControl>
                            <img src={calender} alt='calender'  style={{ marginTop: "-13px" }} className='calender-icon'/>
                        </div>
                        <div className="img-title to-title">to</div>
                        <div className='calender-end' onClick={handleRentProperty}>
                            <FormControl className='formControl' variant="standard" sx={{ minWidth: "153px" }} >
                                <TextField hiddenLabel id="standard-basic" label={!selectedRentDate && "mm/dd/yyyy"} variant="standard" value={selectedRentEndDate ? selectedRentEndDate.toLocaleDateString() : ''} sx={{
                                    width: '176px',
                                    '& input': {
                                        padding: 0,
                                    },
                                    '& .MuiInputLabel-root': {
                                        margin: 0,
                                    },
                                }} />
                            </FormControl>
                            <img src={calender} alt='calender' style={{ marginTop: "-13px" }} className='calender-icon' />
                        </div>

                        <Modal style={customStyles} isOpen={openRentModal} overlayClassName="myoverlay" >
                            <div className="modal-container" >
                                <div style={{ borderBottom: " 1px solid #EAEAEA", marginBottom: "10px" }} >
                                    <div className="modal-main-heading  calender_header" > <p style={{ fontSize: '24px', fontWeight: '600', fontFamily: 'Proxima_nova_reg', marginBottom: "0px" }} className='dateRange'>Select date range</p>
                                        <img src={close} onClick={handleCloseRentClick} style={{ cursor: 'pointer' }} alt='close' /></div>
                                    <div style={{ display: "flex" }}>
                                        <div className='inputField_calender d-block inputField_calender_rent'>
                                            <FormInput
                                                type='text' name={'Start Date'} id={'name'}
                                                value={selectedRentDate ? selectedRentDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }) : ''}
                                                readOnly />
                                        </div>
                                        <img src={ArrowRight} alt='right' />
                                        <div className='inputField_calender d-block inputField_calender_rent' style={{ position: "relative" }}>
                                            <FormInput
                                                type='text' name={'End Date'} id={'name'}
                                                value={selectedRentEndDate ? selectedRentEndDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }) : ''}
                                                readOnly={false} />
                                        </div>
                                    </div>
                                </div>
                                <div className='main_calender'>
                                    <DatePicker onChange={handleMainCalenderDate} inline dateFormat="MMMM yyyy" dayClassName={(date) => {
                                        let classes = '';
                                        if (selectedRentDate && selectedRentEndDate) {
                                            if (date.getTime() === new Date(selectedRentDate).getTime()) {
                                                classes += 'start-date';
                                            } else if (date.getTime() === new Date(selectedRentEndDate).getTime()) {
                                                classes += 'end-date';
                                            } else if (date > new Date(selectedRentDate) && date < new Date(selectedRentEndDate)) {
                                                classes += 'range-selected';
                                            }
                                        }
                                        return classes;
                                    }} renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                        <CustomHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />)}
                                        locale={enGB}
                                        formatWeekDayShort={(locale, dayOfWeek) => {
                                            const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
                                            return days[dayOfWeek];
                                        }} minDate={today}
                                    />
                                </div>
                                <div className="sloting-type" style={{ display: "flex", justifyContent: "space-between", padding: "20px 32px 20px 32px ", borderTop: "1px solid #EAEAEA", alignItems: "baseline" }}>
                                    <div className='calender_footer'>
                                        <img src={slots} alt='Like' />
                                        <p className='footer_para'>Available Slots</p>
                                    </div>
                                    <div>
                                        <button className={selectedRentEndDate ? 'continue_button' : 'Disablecontine'} onClick={handleCloseRentClick} style={{ background: '#0075CA' }} >Done</button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                    <div className='seaction-bugget'>
                        <div className="d-flex">
                            <div className="img-title">I am looking for</div>
                            <div className="count">
                                <input type="number" min="0" value={bedroom} onChange={handleBedroomChange} max="10" className='mobile-input' />
                            </div>
                        </div>
                        <div className="d-flex " style={{ marginLeft: "10px" }}>
                            <div className="img-title">bedrooms and</div>
                            <div className="count">
                                <input type="number" min="0" value={bathroom} onChange={handleBathroomChange} max="10"  className='mobile-input'/>
                            </div>
                        </div>
                        <div className="d-flex ms-0 ms-md-2" >
                            <div className="img-title">bathrooms.</div>
                        </div>
                        <div className="my-bugget-large">
                            <div className="img-title-bugget">My budget is between</div>
                            <div className="buget-value">
                                <div className="d-flex align-items-center ">
                                    <AttachMoneyIcon style={{ width: "30px", height: "30px", marginLeft: "5px", color: "#0075CA", borderBottom: "1px solid #0075CA", marginTop: "-11px" }} />
                                    <input type="number" className="icon" value={firstprice} onChange={(e) => handleFirstPriceChange(e)} min="500" style={{ marginTop: '-9px' }} />
                                </div>
                                <div className="d-flex dashBetween" >and</div>
                                <div className="d-flex align-items-center ">
                                    <AttachMoneyIcon style={{ width: "30px", height: "30px", marginLeft: "5px", color: "#0075CA", borderBottom: "1px solid #0075CA", marginTop: "-11px" }} />
                                    <input type="number" className="icon" value={lastprice} onChange={(e) => handleLastPriceChange(e)} min={firstprice} style={{ marginTop: '-9px' }} />
                                </div>

                            </div>

                        </div>
                    </div>
                    {/* <div className='seaction-bugget'>
                        <div className="img-title">My budget is between</div>
                        <div className="buget-value">
                            <div className="d-flex align-items-center">
                                <AttachMoneyIcon style={{ width: "30px", height: "30px", marginLeft: "5px", color: "#0075CA", borderBottom: "1px solid #0075CA", marginTop: "-11px" }} />
                                <input type="number" className="icon" value={firstprice} onChange={(e) => handleFirstPriceChange(e)} min="500" style={{ marginTop: '-9px' }} />
                            </div>
                            <div className="d-flex dashBetween" >and</div>
                            <div className="d-flex align-items-center">
                                <AttachMoneyIcon style={{ width: "30px", height: "30px", marginLeft: "5px", color: "#0075CA", borderBottom: "1px solid #0075CA", marginTop: "-11px" }} />
                                <input type="number" className="icon" value={lastprice} onChange={(e) => handleLastPriceChange(e)} min={firstprice} style={{ marginTop: '-9px' }} />
                            </div>

                        </div>
                    </div> */}
                    <div className="d-flex heading-buttons">
                        <div>
                            <ContinueButton text={'Search for Rental'} color={'#fff'} onClick={() => handlSearchChange()} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={'176px'} borderRadius={"4px"} fontWeight={500} className="searchButton"/>
                        </div>
                        <div style={{ paddingLeft: "10px" }}>
                            <ContinueButton text={'List your Property'} color={'#101010'} onClick={() => handleListChange()} backgroundColor={'#fff'} border={'1px solid #101010'} borderRadius={"4px"} fontWeight={500}  className="searchButton"/>
                        </div>
                    </div>
                </Row>
            </div>

            <div className="recent-property" style={{ height: recentlyArray?.length === 0 ? "665px" : "auto" }}>
                <div className="recent-heading">Recently added properties</div>
                <div className="slider-container">
                    {!recentlyArray || recentlyArray.length === 0 ?
                        ""
                        :
                        <Slider {...settings} style={{ width: "100%" }}>
                            {recentlyArray && recentlyArray.map((data, index) => {
                                return (
                                    <div className="list-property-slider row">
                                        <div className="property-card col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3" >
                                            <div>
                                                <CustomImage image={data?.file?.[0]?.file} />
                                            </div>
                                            <div class="d-flex justify-content-between mt-2">
                                                <div className='d-flex px-2 align-items-baseline'>
                                                    <div className="property-price">${data?.price}</div>
                                                    <div className="property-day">/</div>
                                                    <div>{data?.per_day ? 'day' : 'month'}</div>
                                                </div>
                                                <div>
                                                    <img src={(data?.is_favourite) ? FilledLikeIcon : Like} alt="" style={{ marginTop: "15px", cursor: "pointer", width: "24px", height: "24px" }} onClick={() => handleAddFavouriteProperty(data?.propertyMaster_id)} />
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div className="col-6 d-flex">
                                                    <div>
                                                        <img src={Bedroom} alt="" style={{ width: "24px", height: "24px" }} />
                                                    </div>
                                                    <div className="count-heading" style={{ marginLeft: "5px" }}>{data?.bedroomCount} Bedrooms</div>
                                                </div>
                                                <div className="col-6 d-flex">
                                                    <div>
                                                        <img src={Bathroom} alt="" style={{ width: "24px", height: "24px" }} />
                                                    </div>
                                                    <div className="count-heading" style={{ marginLeft: "5px" }}>{data?.bathroomCount} Bathroom</div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div className="col-6 d-flex align-items-center">
                                                    <div>
                                                        <img src={Square} alt="" style={{ width: "24px", height: "24px" }} />
                                                    </div>
                                                    <div className="count-heading" style={{ marginLeft: "5px" }}>{data?.propertySize} sqft</div>
                                                </div>
                                                <div className="col-6 d-flex">
                                                    <div>
                                                        <img src={Dresser} alt="" style={{ width: "24px", height: "24px" }} />
                                                    </div>
                                                    <div className="count-heading" style={{ marginLeft: "5px" }}>{data?.name}</div>
                                                </div>
                                            </div>
                                            <div className="count-heading-location" style={{ marginTop: "20px" }}>{data?.locality}</div>
                                            <div class="d-flex justify-content-end p-3 p-md-1">
                                                <div className="d-flex" onClick={() => handleViewDetail(data?.propertyMaster_id)} style={{ cursor: "pointer" }}>
                                                    <div className="view-heading">View Details</div>
                                                    <div style={{ marginLeft: "10px" }}>
                                                        <img src={ArrowForward} alt="" style={{ width: '27px', paddingTop: "5px" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    }
                </div>
            </div>
            <div className="collection-container">
                <div className="recent-heading">Featured Collection</div>
                <Row style={{ justifyContent: 'center' }}>
                    {featureCollection && featureCollection.length > 0 && featureCollection.map((data, index) =>
                        <Col sm={12} md={6} lg={3} style={{ marginBottom: '30px' }} >
                            <img src={featureCollectionImages[data?.slug || '']} alt='' onClick={() => handleFilterFeatureCollection(data?.slug)} className='featureCollection' />
                        </Col>
                    )}
                </Row>
            </div>
            <div className="price-container" >
                <div className="price-color">
                    <div className="row d-flex justify-content-around">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <div className="price-heading">406,000+</div>
                            <div className="price-description">Landlord already included</div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <div className="price-heading">$40,000+</div>
                            <div className="price-description">Brokerage Saved</div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <div className="price-heading">20,000+</div>
                            <div className="price-description">New Listing Monthly</div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <div className="price-heading">No commisions</div>
                            <div className="price-description">No hidden costs</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <FooterSection ref={aboutRef} />
            </div>
        </div>
    )
}

export default HeadingSection
