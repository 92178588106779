import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import GovHome from "../../../../assets/images/GovHomeLogo.png";
import MobileLogo from "../../../../assets/icons/onlyLogo.png"
import Box from '@mui/material/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import "./FavoriteSelect.scss"
import url from "../../../../utils/services/urls.json"
import { getMethodWithToken } from '../../../../utils/services/apis';
import { useParams } from 'react-router-dom';
import { navigateLogin } from '../../../../utils/services/common';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StepConnector from '@mui/material/StepConnector';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ContinueButton from "../../../../Components/Buttons/ContinueButton/ContinueButton";
import Modal from "react-modal"



const SelectSection = styled.div`
    margin: 20px 0 0 50px; /* Default for larger screens */
    font-family: 'Proxima Nova';

    @media (max-width: 767px) { /* Small screens */
        margin: 0; /* Set margin to 0 */
    }
`;


const FavoriteSelect = ({ activeStep, value }) => {
    const navigate = useNavigate()
    const [openModal, setOpenModal] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const customStyles = {
        content: {
            top: screenWidth<576?'100%':'50%',
            left: '50%',
            bottom: screenWidth<576?'0px':'auto',
            transform: 'translate(-50%, -50%)',
            width: screenWidth<576?"100%":"700px",
            height: screenWidth<576?"380px":"230px",
            padding:'5px',
            borderRadius: screenWidth<576 && '24px 24px 0px 0px',
            right: screenWidth>576 && 'auto',
            bottom: 'auto',
            marginRight: screenWidth>576 && '-50%',
            
        },
    };
    
    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const params = useParams();

    const CustomConnector = styled(StepConnector)(({ theme }) => ({
        '& .MuiStepConnector-line': {
            borderColor: '#D9D9D9',
            borderTopWidth: 2,
        },
    }));

    const StepIconComponent = ({ icon, completed, index }) => {
        return completed ? (
            <CheckCircleIcon style={{ color: (value === index) ? "#1976d2" : "#00B707" }} />
        ) : (
            <div style={{ borderRadius: '50%', border: (value === 0 && index === 0) ? '2px solid #00B707' : '2px solid #1976d2', padding: '4px' }}>
                <div style={{ width: '8px', height: '8px', backgroundColor: (value === 0 && index === 0) ? '#00B707' : '#0075CA', borderRadius: '50%' }}></div>
            </div>
        );
    }

    const steps = [
        {
            label: 'Select your property type',
        },
        {
            label: 'Fill up property details',
        },
        {
            label: 'Select the property address',
        },
        {
            label: 'Select amenities your place provider',
        },
        {
            label: 'Add images or videos',
        },
        {
            label: 'Set your price',
        },
        {
            label: 'Review listing',
        },
    ];
    // const [activeStep, setActiveStep] = useState(0);


    // useEffect(() => {
    //     getSteps()
    // }, [])

    // const getSteps = async () => {
    //     await getMethodWithToken(url.selectlist + params.id + "/").then((response) => {
    //         if (response.status === 200 || response.status === 201) {
    //             setActiveStep(response?.data?.detail?.stepsCompleted)
    //         } else if (response.status === 401) {
    //             navigateLogin()
    //             navigate("/federalEmployee/Login")
    //         }
    //     })
    // }
    const handleHomePage = () => {
        navigate("/Dashboard/homePage")
    }
    const handleOpenBack = () => {
        setOpenModal(true)
    }
    const handleCloseBack = () => {
        setOpenModal(false)
    }

    return (
        <>
            <div className="d-none d-xl-flex ">
                <SelectSection>
                    <div>
                        <img src={GovHome} className="d-block" alt="" style={{ width: "100px", height: "80px", cursor: "pointer" }} onClick={() => handleHomePage()} />
                    </div>
                    
                    <div className="side-container">
                        <Stepper activeStep={activeStep} orientation="vertical" style={{ marginTop: "10px" }} >
                            {steps.map((step, index) => (
                                <Step className="step-line" key={step.label}>
                                    <StepLabel>
                                        {step.label}
                                    </StepLabel>

                                </Step>
                            ))}
                        </Stepper>

                    </div>
                </SelectSection>

            </div>
            <div className="d-inline d-xl-none">
                <SelectSection>
                    <div  className='d-md-flex justify-content-between align-items-center mt-1 d-none d-none'>
                        <img src={GovHome} alt="" style={{ width: "100px", height: "80px", cursor: "pointer" }} onClick={() => handleHomePage()} />
                    </div>
                    <div className='d-md-none justify-content-between align-items-center mt-3 d-flex d-flex'>
                    <ArrowBackOutlinedIcon style={{width:'24px',height:'24px'}} onClick={handleOpenBack}/>
                    <div style={{textAlign:'right'}}>
                        <img src={MobileLogo} alt='' style={{  cursor: "pointer",width:'22px',height:'32px' }} onClick={() => handleHomePage()} />
                    </div>
                    </div>
                   
                </SelectSection>
                <div className="side-section-large">
                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={activeStep} connector={<CustomConnector />}>
                            {steps.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel
                                        StepIconComponent={(props) => <StepIconComponent {...props} completed={index < activeStep} index={index} />}
                                    >
                                    </StepLabel>
                                </Step>

                            ))}
                        </Stepper>
                        <div style={{ marginTop: '25px', display: 'flex', justifyContent: 'start' ,marginLeft: '10px'}}>
                            <div className="active-value">
                                {value === 0 ? "Select your property type" : value === 1 ? "Fill up property details" : value === 2 ? "Select the property address" : value === 3 ? "Select amenities your place provider" : value === 4 ? "Add images or videos" : value === 5 ? "Set your price" : value === 6 ? "Review listing" : ""}
                            </div>
                        </div>
                    </Box>
                </div>
                {/* </SelectSection> */}
            </div>
            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay" >
                <div className="d-flex justify-content-xs-start justify-content-center">
                    <div className="back-heading" style={{fontSize:'20px'}}>Are you sure you want to back?</div>
                </div>
                <div className="d-flex justify-content-xs-start justify-content-center gap-5" >
                    <div style={{ marginTop: "10px",fontSize:'16px' }}>All the changes you will made will be discarded</div>
                </div>
                <div className="d-flex justify-content-center pt-5">
                    <div className="d-flex mt-2" >
                        <div className="stay-heading" onClick={handleCloseBack} style={{fontSize:'16px'}}>Stay on this page</div>
                        <div style={{fontSize:'16px'}}>
                            <ContinueButton text="Go back" color={'#fff'} onClick={() => navigate(-1)} width={"184px"} borderRadius={"4px"} height={"44px"}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default FavoriteSelect
