import React, { useEffect, useState, useRef, useCallback } from 'react'
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { Col, Row, Container } from 'react-bootstrap';
import FavoriteSelect from '../FavoriteSelect/FavoriteSelect';
import "./FavoritePropertyLocate.scss"
import { TextField } from "@mui/material";
import { InputAdornment } from '@mui/material';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import { InputLabel, MenuItem, Select } from '@mui/material'
import FormInput from "../../../../Components/Inputs/FormInput/FormInput"
import ContinueButton from "../../../../Components/Buttons/ContinueButton/ContinueButton"
import { getMethodWithToken, postMethodWithToken } from '../../../../utils/services/apis';
import url from "../../../../utils/services/urls.json"
import { GoogleMap, Marker } from '@react-google-maps/api';
import Modal from "react-modal"
import { toast } from 'react-toastify';
import Geosuggest from "react-geosuggest";
import { useLoadScript } from "@react-google-maps/api";
import { navigateLogin } from '../../../../utils/services/common';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

let latitude = "";
let longitude = "";

const ContainerSection = styled.div`
font-family: Proxima Nova;
`
const containerStyle = {
    width: '100%',
    height: '75vh'
};

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "700px",
        height: "230px",
    },
}
const libraries = ["places"];
const FavoriteProperrtyLocate = () => {
    const navigate = useNavigate()
    const param = useParams()
    const geosuggestEl = useRef("");
    const mapRef = useRef();
    const center = {
        lat: latitude !== "" ? latitude : 40.7128,
        lng: longitude !== "" ? longitude : -74.0060
    };

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyBBNW2PnUIVek7ezyuNFTh1xn6BAj90x7s",  
        libraries,
    });
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);
    const [map, setMap] = React.useState(null)
    const onLoad = React.useCallback(function callback(map) {
        if (center && typeof center.lat === "number" && typeof center.lng === "number") {
            const bounds = new window.google.maps.LatLngBounds(center);
            map.fitBounds(bounds);
            setMap(map)
        }

    }, [center])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const [searchInput, setSearchInput] = useState("")
    const [localityValue, setLocalityValue] = useState("") 
    const [showSearchIcon, setShowSearchIcon] = useState(true)
    const [streetValue, setStreetValue] = useState("")
    const [directionValue, setDirectionValue] = useState("")
    const [islocalityValueEmpty, setislocalityValueEmpty] = useState(false);
    const [stateValue, setStateValue] = useState("")
    const [state, setState] = useState([])
    const [codeValue, setCodeValue] = useState("")
    const [code, setCode] = useState([])
    const [cityValue, setCityValue] = useState("")
    const [unitValue, setUnitValue] = useState("")
    const [city, setCity] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value)
        localStorage.setItem("searchvalue", event.target.value)
    }

    const handleLocalityChange = (event) => {    
        setShowSearchIcon(event === '')
    }

    const handleDirectionChange = (event) => {
        setDirectionValue(event.target.value)
        const newdirectionValue = event.target.value;
    }
    const handleStateValue = (event) => {
        setStateValue(event.target.value)
        // localStorage.setItem("state", event.target.value)
    }

    const handlecodeValue = (event) => {
        setCodeValue(event.target.value)
    }
    const handlecityeValue = (event) => {
        setCityValue(event.target.value)
    }

    const handleUnitNumberChange = (event) => {
        setUnitValue(event.target.value)
    }

    useEffect(() => {
        setState([1, 2, 3])
        setCode([127021, 123031, 123349, 233070])
        setCity(['One', 'Two', 'Three', 'Four', 'Five'])
    }, state, code)

    useEffect(() => {
        getSteps()
    }, [])
    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    const getSteps = async () => {
        await getMethodWithToken(url.selectlist + param.id + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                const allDetailStepThree = response?.data?.detail?.stepThree;
                setLocalityValue(allDetailStepThree?.locality)
                setDirectionValue(allDetailStepThree?.direction)
                setCityValue(allDetailStepThree?.city)
                setStateValue(allDetailStepThree?.state)
                setCodeValue(allDetailStepThree?.zipcode)
                setUnitValue(allDetailStepThree?.unit_number)
                latitude = allDetailStepThree?.latitude
                longitude = allDetailStepThree?.longitude
                setActiveStep(response?.data?.detail?.stepsCompleted)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const onSuggestSelect = (suggest) => {
        console.log(suggest , "171")
       
        if (suggest) {
            const components = suggest.gmaps.address_components;
            let streetNumberValue = '';
            let routeValue = '';
            let cityValue = '';
            let countryValue = '';
            let stateCode = '';
            let countryCode = '';
            let postCodeValue = '';
            let stateValue = '';
            let stateAddressValue = '';
            let addressValue = '';

            for (let key of components) {
                if (key.types[0] === "locality" || key.types[1] === "sublocality") {
                    setCityValue(key.long_name)
                    cityValue = key.long_name;
                }
                if (key.types[0] === "administrative_area_level_1") {
                    stateValue = key.long_name;
                    stateAddressValue = key.short_name;
                    setStateValue(key.long_name)
                }

                if (key.types[0] === "postal_code") {
                    postCodeValue = key.long_name
                    setCodeValue(key.long_name)
                }
                if (key.types[0] === "route") {
                    routeValue = key.short_name;
                }
                if (key.types[0] === "street_number") {
                    streetNumberValue = key.long_name;
                }
                if (key.types[0] === "locality") {
                    countryValue = key.long_name
                }
                if (key.types[0] === "administrative_area_level_1") {
                    countryCode = key.short_name
                }
                if (key.types[0] === "country") {
                    stateCode = key.short_name
                }
                addressValue = streetNumberValue + ", " + routeValue + ", " + countryValue + ", " + countryCode + ", " + stateCode
                setLocalityValue(suggest.gmaps?.formatted_address)
                setShowSearchIcon(false)
                latitude = suggest.location.lat;
                longitude = suggest.location.lng;
            }
        }
    }


    const handleLocatedSubmit = async () => {
        let body = {
            "latitude": latitude,
            "longitude": longitude,
            "locality": localityValue,
            "landmark": streetValue,
            "direction": directionValue,
            // "zipcode": codeValue,
            "city": cityValue,
            "zipcode": +codeValue,
            "state": stateValue,
            "unit_number": +unitValue
        }

        if (localityValue !== "" || localityValue !== "undefined") {
            await postMethodWithToken(url.postLocated + param.id + '/', body).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    navigate(`/Dashboard/FavoritePropertieList/amenities/${response.data.propertyId}`)
                }
                else {
                    toast.error("Please fill all fields")
                    
                }

            })
        } else {
            toast.error("Please fill all fields")
            console.log("Please fill all fields" , "229")
        }

    }
    const handleSaveExit = async () => {
        // let body = {
        //     "latitude": -3.745,
        //     "longitude": -38.523,
        //     "locality": localityValue,
        //     "landmark": streetValue,
        //     "direction": directionValue,
        //     "zip_code": codeValue
        // }
        // // const areFieldsEmpty =
        // //     localityValue.trim() === ''
        // await postMethodWithToken(url.postLocated + param.id + '/', body).then((response) => {
        //     if (response.status === 200 || response.status === 201) {
        //         navigate(`/Dashboard/homePage`)
        //     }
        //     // else if (areFieldsEmpty) {
        //     //     setislocalityValueEmpty(localityValue.trim() === '');
        //     //     toast.error("Please fill all fields")
        //     // }

        // })
        navigate(`/Dashboard/homePage`)
    }
    const handleOpenBack = () => {
        setOpenModal(true)
    }
    const handleCloseBack = () => {
        setOpenModal(false)
    }
    

    return (
        <>
            <ContainerSection>
                {/* <Container> */}
                <Row className='ms-xs-3 ms-sm-3 ms-0 me-xs-3 me-sm-3 me-0'>
                    <Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                        <FavoriteSelect activeStep={activeStep} value={2} />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                        <div className="locate-select" >
                            <div className="locate-heading" >Where is the property located ?</div>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    {isLoaded &&
                                        <div style={{ marginTop: "45px", position: 'relative' }} >
                                            <div className="search-btn-container " style={{ position: 'absolute', top: '42%', transform: 'translateY(-50%)' }}>
                                                <div>
                                                    <SearchIcon style={{ color: "white", width: "25px", height: "25px" }} />
                                                </div>
                                                <div style={{ color: "white", fontWeight: 500, paddingLeft: "7px" }} className='searchName'>Search</div>
                                            </div>
                                            
                                            <div className='searchInput' >
                                            <Geosuggest
                                                ref={geosuggestEl}
                                                onSuggestSelect={onSuggestSelect}
                                                placeholder={"Search for Street address, City, State or Zip code"}
                                                initialValue={localityValue}
                                                onSuggestSelect2
                                                fields={["address_components", "geometry"]}
                                                types={["address"]}
                                                country={["US"]}
                                                style={{ zIndex: openModal ? "auto" : "2" }}
                                                className={!openModal ? 'geo-value' : "geo-value2"}
                                                onChange={handleLocalityChange}
                                            />
                                            {(localityValue === '' || localityValue === undefined && showSearchIcon) &&
                                        
                                            <SearchIcon style={{ width: "20px", height: "20px" ,position: "absolute", left:'75%',color:'#2F86D1',pointerEvents: 'none',top:'30%'}} className='d-block d-md-none'/>
                                            }
                                            
                                            </div>
                                            
                                        </div>
                                    }
                                </Col>
                                <Col lg={12}>
                                    <div className="feild-input">
                                        <FormInput required={true} placeholder={'Street Address'} id={'name'} type={'text'} value={localityValue} disabled={true} />
                                    </div>
                                    {islocalityValueEmpty && (
                                        <p style={{ color: 'red', marginLeft: '15px' }}>This field is required.</p>
                                    )}
                                </Col>
                                <Col lg={12}>
                                    <div className="feild-dropdown">
                                        <FormInput required={true} placeholder={'City'} type={'text'} value={cityValue} disabled={true} />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="feild-dropdown">
                                        <FormInput required={true} placeholder={'State'} type={'text'} value={stateValue} disabled={true} />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="feild-dropdown">
                                        <FormInput required={true} placeholder={'Zip Code'} type={'text'} value={codeValue} disabled={true} />
                                    </div>

                                </Col>
                                <Col lg={12}>
                                    <div className="feild-unit">
                                        <FormInput required={true} name={'Unit Number'} id={'name'} type={'number'} value={unitValue} onChange={handleUnitNumberChange} />
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="feild-input">
                                        <FormInput required={true} name={'Add Direction for Tenants'} id={'name'} type={'text'} value={directionValue} onChange={handleDirectionChange} />
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                    {/* <Col xl={2} xxl={1} /> */}
                    <Col xs={12} sm={12} md={12} lg={12} xl={5} xxl={5}>
                        <div className="map-section">
                            {isLoaded &&
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={center}
                                    zoom={10}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}
                                >
                                    <Marker position={center} />
                                </GoogleMap>
                            }
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={7} xxl={7}>
                        <div className="d-md-flex d-none justify-content-between   buttons " style={{ margin: "20px 0 20px 30px", alignItems: "center" }} >
                            <div className='backButton'>
                                <ContinueButton className="back-list" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={handleOpenBack} fontSize={"20px"} fontWeight={"400"} />
                            </div>
                            <div className="d-flex justify-content-end">
                                {/* <div style={{ fontSize: "20px", cursor: "pointer", paddingRight: "70px", display: "flex", alignItems: "center" }} onClick={() => handleSaveExit()}>Save & Exit</div> */}
                                <div style={{ marginRight: "50px", display: "flex", alignItems: "center" }} >
                                    <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                                <div >
                                    <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => handleLocatedSubmit()} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                            </div>
                        </div>
                        <div className="d-md-none d-flex justify-content-between mt-3 mb-3">
                            {/* <div style={{ fontSize: "20px", cursor: "pointer", paddingRight: "70px", display: "flex", alignItems: "center" }} onClick={() => handleSaveExit()}>Save & Exit</div> */}
                            <div style={{ display: "flex", alignItems: "center" }} className='saveButton'>
                                <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={screenWidth < 768 ? '140px' : '200px'} fontSize={screenWidth < 768 ? '14px' : '20px'} fontWeight={"400"} className="saveExitButton" />
                            </div>
                            <div className='saveButton'>
                                <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => handleLocatedSubmit()} width={screenWidth < 768 ? '140px' : '200px'} fontSize={screenWidth < 768 ? '14px' : '20px'} fontWeight={"400"} className="saveExitButton" />
                            </div>
                        </div>
                    </Col>
                </Row>
                {/* </Container> */}
            </ContainerSection>
            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
                <div className="d-flex justify-content-center">
                    <div className="back-heading">Are you sure you want to back?</div>
                </div>
                <div className="d-flex justify-content-center" >
                    <div style={{ marginTop: "10px" }}>All the changes you will made will be discarded</div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="d-flex" style={{ marginTop: "50px" }}>
                        <div className="stay-heading" onClick={handleCloseBack}>Stay on this page</div>
                        <div>
                            <ContinueButton text="Go back" color={'#fff'} onClick={() => navigate(-1)} width={"210px"} borderRadius={"4px"} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default FavoriteProperrtyLocate
