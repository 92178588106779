import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useParams, useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import FavoriteSelect from '../FavoriteSelect/FavoriteSelect';
import "./FavoriteReview.scss"
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import userImage from "../../../../assets/images/Ellipse 29.png"
import LikeIcon from "../../../../assets/images/listingManager/like-property.svg"
import FlagIcon from "../../../../assets/images/Flag.png"
import Reply from "../../../../assets/images/reply.png"
import Bedroom from "../../../../assets/images/favoriteProperty/bedroom.svg"
import Bathroom from "../../../../assets/images/favoriteProperty/bathroom.svg"
import Parking from "../../../../assets/images/favoriteProperty/parking.svg"
import Clock from "../../../../assets/images/favoriteProperty/clock.svg"
import Home from "../../../../assets/images/favoriteProperty/home.svg"
import Furnishing from "../../../../assets/images/favoriteProperty/furnishing.svg"
import ContinueButton from '../../../../Components/Buttons/ContinueButton/ContinueButton';
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import FormControl from '@mui/material/FormControl';
import { InputLabel, MenuItem, Select } from '@mui/material'
import Modal from "react-modal"
import { getMethodWithToken, postMethodWithToken } from '../../../../utils/services/apis';
import url from "../../../../utils/services/urls.json"
import { navigateLogin } from '../../../../utils/services/common';
import DefaultImage from "../../../../assets/images/default-image.svg"

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "700px",
        height: "230px",
    },
};

const CustomImage = styled.img`
background:url('${props => props.image}');
height: 210px;
width:98%;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;

   @media only screen and (max-width: 768px) {
    height: 182px;
   }
    @media only screen and (max-width: 767px) {
    height: 230px;
    width:100%;
   }
    
`


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 400,
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%', 
        // height:'180px'
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      paddingLeft: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },
    img: {
      height: 210,
      display: 'block',
      maxWidth: 300,
      overflow: 'hidden',
      width: '100%', // Default to full width
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%', // Full width for images on small screens
      },
    },
  }));
  
  

const ContainerSection = styled.div`
font-family: Proxima Nova;
 @media only screen and (max-width: 767px) {
   padding:10px;
   }
`
const FavoriteReview = () => {
    const navigate = useNavigate()
    const moment = require('moment-timezone');
    const params = useParams()
    const classes = useStyles();
    const theme = useTheme();
    const [activeStepValue, setActiveStepValue] = useState(0);
    const [checked, setChecked] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [reviewDetail, setReviewDetail] = useState([])
    const [collectionPropertyId, setCollectionPropertyId] = useState("")
    const [carouselImages, setCarouselImages] = useState([])
    const [featurePropertyArray, setFeaturePropertyArray] = useState([])
    const maxSteps = carouselImages.length;
    const [subscriptionValue, setSubscriptionValue] = useState(localStorage.getItem("subscriptionData") !== "undefined" ? JSON.parse(localStorage.getItem("subscriptionData")) : "")
    const [arrayPropertyId, setArrayPropertyId] = useState(true)
    const [reviewDetailList, setReviewDetailList] = useState([])
    const [activeStep, setActiveStep] = useState(0)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        getSteps()
    }, [])

    useEffect(() => {
        if (checked) {
            getPropertyDetails()
        }

    }, [checked])

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getPropertyDetails = async () => {
        await getMethodWithToken(url.selectlist + params.id + '/').then((response) => {
            if (response.status === 200 || response.status === 201) {
                getReviewDetails(response?.data?.detail?.Property_owner?.[0]?.id)
            }
        })
    }
    const getReviewDetails = async (userId) => {
        let apiUrl;
        if (checked) {
            apiUrl = url.getReviewDetail + params.id + "/?" + "userId=" + userId + "&is_featured=True"
        } else {
            apiUrl = url.getReviewDetail + params.id + "/?" + "userId=" + userId
        }
        await getMethodWithToken(apiUrl).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setReviewDetail(response?.data?.[0])
                if (checked) {
                    if (localStorage.getItem("availabilityValue") !== localStorage.getItem("availabilityValueResponse")) {
                        localStorage.setItem("subscriptionPriceValue", (+(response?.data?.[0]?.subscription_price) + +(response?.data?.[1]?.feature_list_price)))
                    }
                    else if (Object.keys(subscriptionValue).length > 0) {
                        localStorage.setItem("subscriptionPriceValue", (response?.data?.[1]?.feature_list_price))
                    } else {
                        localStorage.setItem("subscriptionPriceValue", (+(response?.data?.[0]?.subscription_price) + +(response?.data?.[1]?.feature_list_price)))
                    }
                } else if (Object.keys(subscriptionValue).length === 0 || localStorage.getItem("availabilityValue") !== localStorage.getItem("availabilityValueResponse")) {
                    localStorage.setItem("subscriptionPriceValue", (+(response?.data?.[0]?.subscription_price)))
                }

            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const getSteps = async () => {
        await getMethodWithToken(url.selectlist + params.id + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                let Detail = response?.data?.detail
                setReviewDetailList(Detail)
                setCarouselImages(Detail?.stepFive)
                setActiveStep(response?.data?.detail?.stepsCompleted)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    const handleNext = (data) => {
        // setActiveStepValue((prevActiveStep) => prevActiveStep + 1);

        setActiveStepValue((prevActiveStep) =>
            prevActiveStep === data?.length - 1 ? 0 : prevActiveStep + 1
        );
    };
    const handleBack = (data) => {
        // setActiveStepValue((prevActiveStep) => prevActiveStep - 1);

        setActiveStepValue((prevActiveStep) =>
            prevActiveStep === 0 ? data?.length - 1 : prevActiveStep - 1
        );
    };

    const handleStepChange = (step) => {
        setActiveStepValue(step);
    };
    const handleCheck = (event) => {
        setChecked(event.target.checked)
        handleFeaturePropertyDropdown()
    }
    const handleFeaturePropertyDropdown = async () => {
        await getMethodWithToken(url.featurePropertyDropdown).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setFeaturePropertyArray(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }

        })
    }
    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }

    const handleOpenBack = () => {
        setOpenModal(true)
    }
    const handleCloseBack = () => {
        setOpenModal(false)
    }
    const handleCollectionChange = (event) => {
        console.log(event)
        setCollectionPropertyId(event.target.value)
        localStorage.setItem("collectionPropertyId", event.target.value)
    }
    const handleReview = async () => {
        if (collectionPropertyId) {
            navigate(`/Dashboard/FavoritePropertieList/PaymentDetail/${params.id}`, {
                state: {
                    featureCheck: checked,
                }
            })

        }
        else {
            navigate(`/Dashboard/FavoritePropertieList/PaymentDetail/${params.id}`)
        }
    }
    const handleAddFavouriteProperty = async () => {
        setArrayPropertyId(!arrayPropertyId)
        let body = {
            "property_id": params.id,
            "favourite": arrayPropertyId
        }
        await postMethodWithToken(url.addFavoriteProperty, body).then((response) => {
            if (response.status === 200 || response.status === 201) {

            }
        })
    }

    const handleEditProperty = () => {
        navigate(`/Dashboard/FavoritePropertieList/SelectBasic/${params.id}/${reviewDetailList?.stepOne?.propertyType?.id}`)
    }
    const handleSaveExit = () => {
        navigate(`/Dashboard/homePage`)
    }
    const handleReviewNavigate = () => {
        navigate(`/Dashboard/MyListings`)
        localStorage.removeItem("PropertyType")
        localStorage.removeItem("name")
        localStorage.removeItem("bedroom");
        localStorage.removeItem("bathroom")
        localStorage.removeItem("balcony")
        localStorage.removeItem("propertysize")
        localStorage.removeItem("furnishingid");
        localStorage.removeItem("furnishing");
        localStorage.removeItem("parkingname")
        localStorage.removeItem("parkingid")
        localStorage.removeItem("floorno")
        localStorage.removeItem("floorid")
        localStorage.removeItem("totalfloor")
        localStorage.removeItem("totalfloorid")
        localStorage.removeItem("storiesName")
        localStorage.removeItem("storiesId")
        localStorage.removeItem("petid")
        localStorage.removeItem("otherpet")
        localStorage.removeItem("locality")
        localStorage.removeItem("city")
        localStorage.removeItem("state")
        localStorage.removeItem("zipcode")
        localStorage.removeItem("latitude")
        localStorage.removeItem("latitude")
        localStorage.removeItem("direction")
        localStorage.removeItem("amenities");
        localStorage.removeItem("imageID")
        localStorage.removeItem("images")
        localStorage.removeItem("subscriptionbutton")
        localStorage.removeItem("masterSubscrption")
        localStorage.removeItem("date");
        localStorage.removeItem("inputvalue")
        localStorage.removeItem("checkpoint");
        localStorage.removeItem("showorganization");
        localStorage.removeItem("subscriptionType");
        localStorage.removeItem("subscriptionId");
        localStorage.removeItem("depositAmount");
        localStorage.removeItem("subscriptionPriceValue");
        localStorage.removeItem("availabilityValue");
        localStorage.removeItem("availabilityValueResponse");
        localStorage.removeItem("addComment")
    }
    return (
        <>
            <ContainerSection>
                <Row  className='m-md-0'>
                    <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                        <FavoriteSelect activeStep={activeStep} value={6} />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} className='mobile-box'>
                        <div className="review-container" >
                            <div className="review-heading" >Review your property listing</div>
                            <div className="review-des" >
                                <div className="review-des-value" >See if you want to change something</div>
                            </div>
                        </div>
                        <div className="review-value-box">
                            <div className="revire-value-item">
                                <Row >
                                    <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                                        <div className={classes.root}>
                                            <SwipeableViews
                                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                index={activeStepValue}
                                                onChangeIndex={handleStepChange}
                                                enableMouseEvents
                                            >
                                                {reviewDetailList?.stepFive?.length > 0 && reviewDetailList?.stepFive.map((step, index) => {
                                                    return (
                                                        <>
                                                            <div key={index}>
                                                                <CustomImage className={classes.img} src={step?.file} alt={step.label} />
                                                            </div>

                                                        </>
                                                    )
                                                })}
                                            </SwipeableViews>
                                            <MobileStepper
                                                position="static"
                                                variant="text"
                                                nextButton={
                                                    <Button size="small" style={{ color: "white" }} onClick={() => handleNext(reviewDetailList?.stepFive)}>
                                                        {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                    </Button>
                                                }
                                                backButton={
                                                    <Button size="small" style={{ color: "white" }} onClick={() => handleBack(reviewDetailList?.stepFive)}>
                                                        {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                    </Button>
                                                }
                                            />
                                        </div>
                                        {/* <div className="rental-heading" >Rental Duration : <span style={{ color: "black", fontWeight: 600, fontSize: "16px" }}>Long Time</span> </div> */}
                                       
                                        <div class="d-none justify-content-center rent-box d-md-flex ">
                                            <div>
                                                <div className="rental-price">${reviewDetailList?.stepSix?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{reviewDetailList?.stepSix?.per_month === true ? "/month" : "/day"}</span></div>
                                                <div className="rent-heading" >Rent</div>
                                            </div>
                                            <div><hr style={{ border: "1px solid black", height: "40px", marginLeft: "10px", marginTop: "20px" }} /></div>
                                            <div>
                                                <div className="deposit-price" style={{ marginLeft: "10px" }}>$<span>{reviewDetailList?.stepSix?.deposit_amount}</span></div>
                                                <div className="rent-heading" style={{ marginLeft: "25px" }}>Deposit</div>
                                            </div>
                                        </div>
                                        <div className="deposit-type pt-3">
                                            <div className="d-flex">
                                                <div className="rent-heading" >Rent : </div>
                                                <div className="rental-price">$ {reviewDetailList?.stepSix?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{reviewDetailList?.stepSix?.per_month === true ? "/month" : "/day"}</span></div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="rent-heading">Deposit : </div>
                                                <div className="deposit-price">$ <span>{reviewDetailList?.stepSix?.deposit_amount}</span></div>

                                            </div>
                                        </div>
                                       
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                        <div className="detail-seaction mt-3 mt-md-1" >
                                            <div className="d-flex justify-content-between align-items-baseline profile">
                                                <div className="d-flex align-items-md-baseline align-items-center" >
                                                    <div>
                                                        <img src={reviewDetailList?.Property_owner?.[0]?.profilePic ? reviewDetailList?.Property_owner?.[0]?.profilePic : DefaultImage} alt="" style={{ borderRadius: "50%" }} className='userImage'/>
                                                    </div>
                                                    <div >
                                                        <div className="d-flex" style={{ paddingLeft: "7px" }}>
                                                            <div className="user-heading">
                                                                {localStorage.getItem("userName")?.length > 0 && getUpparCaseName(localStorage.getItem("userName"))}
                                                                <p className='property-owner  d-block d-md-none'>Property Owner</p>
                                                                </div>
                                                            <div className="user-dot d-none d-md-block">.</div>
                                                            <div className="property-owner  d-none d-md-block">Property Owner</div>
                                                        </div>
                                                        {/* <div style={{ color: "#707070", paddingLeft: "7px", fontSize: "16px" }}>{reviewDetail?.orgnization}</div> */}
                                                    </div>

                                                </div>
                                                <div>
                                                    <div className="d-md-flex d-none">
                                                        {/* <div><img src={arrayPropertyId ? LikeIcon : FilledLikeIcon} alt="" style={{ width: "20px", height: "20px", cursor: "pointer" }} onClick={() => handleAddFavouriteProperty()} /></div> */}
                                                        <div><img src={FlagIcon} alt="" style={{ width: "20px", height: "20px", marginLeft: "10px" }} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pt-3 profile d-md-none d-flex">
                                            <div className="d-flex">
                                                <div className="rent-heading" >Rent : </div>
                                                <div className="rental-price">$ {reviewDetailList?.stepSix?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{reviewDetailList?.stepSix?.per_month === true ? "/month" : "/day"}</span></div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="rent-heading">Deposit : </div>
                                                <div className="deposit-price">$ <span>{reviewDetailList?.stepSix?.deposit_amount}</span></div>

                                            </div>
                                        </div>
                                            <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px" }} className='profile'>{reviewDetailList?.stepThree?.locality}</div>
                                            <Row style={{ borderBottom: "1px solid #B7B7B7", paddingBottom: "5px", paddingTop: "8px" }} className='row-profile' >
                                                <Col md={6} lg={6} xl={6} xxl={6} xs={6} sm={6} className='d-flex'>
                                                    <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                    <div className="categroy-heading-type">Bedroom</div>
                                                    <div className="category-value">{reviewDetailList?.stepTwo?.bedroomCount}</div>
                                                </Col>
                                                <Col md={6} lg={6} xl={6} xxl={6} xs={6} sm={6}  className='d-flex'>
                                                    <div><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                    <div className="categroy-heading-type">Bathroom</div>
                                                    <div className="category-value">{reviewDetailList?.stepOne?.propertyType?.name === "Room" ? reviewDetailList?.stepTwo?.room_sharing?.name : reviewDetailList?.stepTwo?.bathroomCount}</div>
                                                </Col>
                                                {/* <Col md={6} lg={4} xl={4} xxl={4} className='d-flex'>
                                                    <div><img src={Parking} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                    <div className="categroy-heading-type">Parking</div>
                                                    <div className="category-value">{reviewDetailList?.stepTwo?.parkingType?.name?.length > 0 && getUpparCaseName(reviewDetailList?.stepTwo?.parkingType?.name)}</div>
                                                </Col> */}
                                            </Row>
                                            <Row className='profile'>
                                                <Col md={12} lg={12} xl={12} xxl={12} className='d-md-inline  justify-content-between border-mobile'>
                                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                                        <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading">Available from</div>
                                                    </div>
                                                    <div className="select-value-heading">{reviewDetailList?.stepSix?.propertyAvailfrom && moment.tz(reviewDetailList?.stepSix?.propertyAvailfrom, "UTC").tz("Asia/Kolkata").format('YYYY-MM-DD')}</div>
                                                </Col>
                                                {/* <Col md={4} lg={4} xl={4} xxl={4} style={{ borderRight: "1px solid #B7B7B7" }}>
                                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                                        <div><img src={Home} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading">Property Type</div>
                                                    </div>
                                                    <div className="select-value-heading">{reviewDetailList?.stepOne?.propertyType?.name}</div>
                                                </Col>
                                                <Col md={4} lg={4} xl={4} xxl={4}>
                                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                                        <div><img src={Furnishing} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading">Furnishing</div>
                                                    </div>
                                                    <div className="select-value-heading">{reviewDetailList?.stepTwo?.furnishType?.name?.length > 0 && getUpparCaseName(reviewDetailList?.stepTwo?.furnishType?.name)}</div>
                                                </Col> */}
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="edit-property" onClick={() => handleEditProperty()}>Edit Property Details</div>
                        </div>
                        <div className='mobile-line'>
                            <hr />
                        </div>
                        <div className="update-container" >
                            <div className="update-heading-review" >Upgrade your property to featured listing</div>
                            <div className="update-des">
                                <div className="update-des-value" >Allow your property to be featured on the front page,advertisement and stand out on the map</div>
                            </div>
                            <div className="update-check">
                                <FormCheckInput type="checkbox" name="verifyFed" id="verifyFed" onChange={handleCheck} className='update-checkbox' />
                                <label className="ps-2 check-list text-start" htmlFor="verifyFed">I want to add my property to the featured list</label>
                            </div>
                        </div>
                        {checked &&
                            <div className="select-choose">
                                <FormControl variant="standard" sx={{ m: 1, width: "350px" }} >
                                    <InputLabel id="demo-simple-select-label" >Choose Collection for your property</InputLabel>
                                    <Select
                                        required={true}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={collectionPropertyId}
                                        label="Age"
                                        onChange={(e) => handleCollectionChange(e)}
                                    >
                                        {featurePropertyArray.map((data) => {
                                            return (
                                                <MenuItem className='filter_items' value={data?.id}>{data?.name}</MenuItem>
                                            )

                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        }
                    </Col>
                    <Col>
                        <div className="d-md-flex d-none buttons" style={{ margin: "20px 0 20px 30px", alignItems: "center" }} >
                            <div>
                                <ContinueButton className="back-list" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={handleOpenBack} fontSize={"20px"} fontWeight={"400"} />
                            </div>
                            <div className="d-flex justify-content-end">
                                <div style={{ paddingRight: "50px", display: "flex", alignItems: "center" }}>
                                    <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                                <div style={{ marginRight: "50px" }}>
                                    <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => { Object.keys(subscriptionValue)?.length > 0 ? !checked ? localStorage.getItem("availabilityValue") === localStorage.getItem("availabilityValueResponse") ? subscriptionValue?.remaining_list !== 0 && handleReviewNavigate() : handleReview() : handleReview() : handleReview() }} width={"200px"} fontSize={"20px"} fontWeight={"400"} />
                                </div>
                            </div>
                        </div>
                        <div className="d-md-none d-flex justify-content-between mt-3 mb-3">
                                {/* <div style={{ fontSize: "20px", cursor: "pointer", paddingRight: "70px", display: "flex", alignItems: "center" }} onClick={() => handleSaveExit()}>Save & Exit</div> */}
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <ContinueButton text="Save & Exit" color={'#fff'} onClick={() => handleSaveExit()} width={screenWidth < 768 ? '140px' : '200px'}  fontSize={screenWidth < 768 ? '14px' : '20px'} fontWeight={"400"} className="saveExitButton"/>
                                </div>
                                <div >
                                    <ContinueButton text="Save & Continue" color={'#fff'} onClick={() => { Object.keys(subscriptionValue)?.length > 0 ? !checked ? localStorage.getItem("availabilityValue") === localStorage.getItem("availabilityValueResponse") ? subscriptionValue?.remaining_list !== 0 && handleReviewNavigate() : handleReview() : handleReview() : handleReview() }} width={screenWidth < 768 ? '140px' : '200px'}  fontSize={screenWidth < 768 ? '14px' : '20px'} fontWeight={"400"} className="saveExitButton"/>
                                </div>
                            </div>
                    </Col>
                </Row>
            </ContainerSection>
            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
                <div className="d-flex justify-content-center">
                    <div className="back-heading">Are you sure you want to back?</div>
                </div>
                <div className="d-flex justify-content-center" >
                    <div style={{ marginTop: "10px" }}>All the changes you will made will be discarded</div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="d-flex" style={{ marginTop: "50px" }}>
                        <div className="stay-heading" onClick={handleCloseBack}>Stay on this page</div>
                        <div>
                            <ContinueButton text="Go back" color={'#fff'} onClick={() => navigate(-1)} width={"210px"} borderRadius={"4px"} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default FavoriteReview
